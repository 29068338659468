import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { myelinersCollectionRef } from "../../firebase/fire-app";
import ProfileCardSingle from "./ProfileCardSingle";
import SidebarComponent from "../../Components/Shared/SidebarComponent";

const ViewMyelinners = () => {
    const [myelinners, loading, error] = useCollectionData(
        myelinersCollectionRef.orderBy("serial", "asc"),
        { idField: "id" }
    );
    return (
        <>
            <SidebarComponent>
                <section className="sticky-top page-heading ">
                    <div className="container">
                        <h3 className="mt">Myelinners</h3>
                    </div>
                </section>
                <section className="profile-card-container mt-3">
                    <div className="container">
                        <div className="row">
                            {myelinners &&
                                myelinners.length > 0 &&
                                myelinners.map((myeliner) => (
                                    <ProfileCardSingle
                                        data={myeliner}
                                        key={myeliner.id}
                                    />
                                ))}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewMyelinners;
