import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useAuth } from "../../Hooks/useAuth";
import { recruitmentCollectionRef } from "../../firebase/fire-app";
import { fireStorage } from "../../firebase/fire-app";
import { newCandidateAddedNotification } from "../ViewClaimManagement/messageGenerator";
import { webServerLive } from "../../firebase/liveServerStatus";
import emailjs from "@emailjs/browser";

const PopupAddNewCandidate = () => {
    const [show, setShow] = useState(false);
    const [uploading, setUploading] = useState(true);
    const [cvURL, setCVURL] = useState(null);
    const { myelinners, currentUser } = useAuth();

    const { register, handleSubmit, watch, errors } = useForm();
    const onFormSubmit = (data, e) => {
        data.createdAt = new Date();
        data.lastUpdated = new Date();
        data.candidateCV = cvURL;
        data.candidateStatus = "live";
        const mailDataOne = {
            positionTitle: data.positionTitle,
            mailOne: data.assessorOne,
            mailTwo: "zied.tayeb@myelinh.com",
        };
        const mailDataTwo = {
            positionTitle: data.positionTitle,
            mailOne: data.assessorTwo,
            mailTwo: "zied.tayeb@myelinh.com",
        };
        const mailDetailsOne = newCandidateAddedNotification(mailDataOne);
        const mailDetailsTwo = newCandidateAddedNotification(mailDataTwo);
        if (webServerLive) {
            emailjs.send(
                "service_zinxo6v",
                "template_eqvm7mf",
                mailDetailsOne,
                "ygk5Yo8QhiJUWbVZM"
            );
            emailjs.send(
                "service_zinxo6v",
                "template_eqvm7mf",
                mailDetailsTwo,
                "ygk5Yo8QhiJUWbVZM"
            );
        }
        recruitmentCollectionRef.add(data).then(() => {
            e.target.reset();
            setShow(false);
        });
    };

    const onFileUpload = (e) => {
        setUploading(true);
        const file = e.target.files[0]; // Get the first file from the FileList

        if (file) {
            // Get the current time
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, "0"); // Get hours and pad with '0' if less than 10
            const minutes = now.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with '0' if less than 10
            const timeString = `${hours}-${minutes}`; // Format the time as 13-30

            // Extract file extension
            const fileExtension = file.name.split(".").pop(); // Get file extension (pdf, jpg, etc.)
            const fileBaseName = file.name.split(".").slice(0, -1).join("."); // Get the name without extension

            // Combine file name with time and extension
            const updatedFileName = `${fileBaseName}_${timeString}.${fileExtension}`;

            const fileRef = fireStorage
                .ref()
                .child("CVs/")
                .child(updatedFileName);
            fileRef.put(file).then((snapshot) => {
                snapshot.ref.getDownloadURL().then((url) => {
                    console.log(url);
                    setCVURL(url);
                });
            });
        }
    };
    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                Add New Candidate
            </Button>

            <Modal
                centered
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add New Candidate
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form
                            onSubmit={handleSubmit(onFormSubmit)}
                            className="row"
                        >
                            <InputWrapper col="7" label="Candidate Name">
                                <input
                                    type="text"
                                    {...register("name")}
                                    className="form-control"
                                    name="name"
                                />
                            </InputWrapper>
                            <InputWrapper col="5" label="Email">
                                <input
                                    type="text"
                                    {...register("email")}
                                    className="form-control"
                                    name="email"
                                />
                            </InputWrapper>

                            <InputWrapper col="5" label="Position">
                                <select
                                    name="positionTitle"
                                    {...register("positionTitle")}
                                    className="form-select"
                                >
                                    <option value="">
                                        Select position title
                                    </option>
                                    <option value="Cognitive Game Developer">
                                        Cognitive Game Developer
                                    </option>
                                    <option value="BCI Engineer">
                                        BCI Engineer
                                    </option>

                                    <option value="Data Scientist">
                                        Data Scientist
                                    </option>
                                    <option value="Software Engineer">
                                        Software Engineer
                                    </option>
                                </select>
                            </InputWrapper>
                            <div className="col-12"></div>
                            <InputWrapper col="6" label="Assessor One">
                                <select
                                    name="assessorOne"
                                    {...register("assessorOne")}
                                    className="form-select"
                                >
                                    <option value="">Select assessor 1</option>
                                    {myelinners &&
                                        myelinners.length > 0 &&
                                        myelinners.map((item) => (
                                            <option value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </InputWrapper>
                            <InputWrapper col="6" label="Assessor Two">
                                <select
                                    name="assessorTwo"
                                    {...register("assessorTwo")}
                                    className="form-select"
                                >
                                    <option value="">Select assessor 2</option>
                                    {myelinners &&
                                        myelinners.length > 0 &&
                                        myelinners.map((item) => (
                                            <option value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </InputWrapper>
                            <InputWrapper col="12" label="CV">
                                <input
                                    onChange={(e) => onFileUpload(e)}
                                    placeholder="CV"
                                    type="file"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                />
                                {/* <input
                                    type="text"
                                    {...register("candidateCV")}
                                    className="form-control"
                                    name="candidateCV"
                                /> */}
                            </InputWrapper>
                            <InputWrapper col="12" label="GitHub">
                                <input
                                    type="text"
                                    {...register("gitHubProject")}
                                    className="form-control"
                                    name="gitHubProject"
                                />
                            </InputWrapper>
                            <div className="col-12">
                                <p className="mb-2 font-bold">Remarks:</p>
                                <textarea
                                    {...register("adminInitialRemarks")}
                                    rows={4}
                                    name="adminInitialRemarks"
                                    id=""
                                    className="form-control"
                                ></textarea>
                            </div>
                            <div className="col-12 mb-5 mt-4 text-center">
                                {cvURL ? (
                                    <button className="btn btn-sm btn-primary">
                                        Add new candidate
                                    </button>
                                ) : (
                                    <button
                                        disabled
                                        className="btn btn-sm btn-primary"
                                    >
                                        Add new candidate
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAddNewCandidate;
