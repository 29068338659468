import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { timeStampToDate } from "../../utilities";
import { blogsCollectionRef } from "../../firebase/fire-app";
import DeleteConfirmationBasic from "../../Components/DeletePopupConfirmation/DeleteConfirmationBasic";
// import { timeStampToDate } from '../../shared/utilities';

const BlogPostCard = ({ col, blog }) => {

    const onDeleteBlog =()=>{
        blogsCollectionRef.doc(blog.id).delete()
    }
    return (
        <Col md={col ? col : 4} className="mb-3">
        <div className="card text-left">
                    <div>
                        <img
                            src={blog.featuredImage}
                            alt={blog.title}
                            className="img-fluid mb-3"
                        />
                        <div className="post-content px-4 mb-4">
                            {/* <span className="post-category text-primary">
                                <div className="my-2">
                                    <span className="btn btn-sm btn-outline-primary">
                                        CAREER
                                    </span>
                                    <span className="btn btn-sm ml-2 btn-outline-primary">
                                        MYELIN-H
                                    </span>
                                    <span className="btn btn-sm ml-2 btn-outline-primary">
                                        JOIN NOW
                                    </span>
                                </div>
                            </span> */}
                            <p className="font-16 mb-2 text-secondary">
                                {timeStampToDate(blog.createdAt)}
                            </p>
                            <h5 className="font-bold mb-3 text-primary">
                                <>{blog.title} </>
                            </h5>
                            {/* <Link
                                className="btn btn-sm btn-primary me-2"
                                to={"/blog/" + blog.id}
                            >
                                View details
                            </Link> */}
                            <Link className="btn btn-warning btn-sm me-2" to={`/admin/blogs/edit/`+blog.id}>
                                Edit
                            </Link>
                    <DeleteConfirmationBasic onDeleteTriggered={onDeleteBlog} />

                            {/* <p className="text-white">{timeStampToDate(blog.createdAt)} &nbsp;&nbsp; | &nbsp; &nbsp; <FaEye /> &nbsp; {blog.totalViews} </p> */}
                        </div>
                    </div>
            </div>
        </Col>
    );
};

export default BlogPostCard;
