import React from 'react';

const AssGuideCognitive = () => {
    return (
        <>
             <div className="card bg-primary p-3 text-white">
             <h6 className="font-bold">Assessment Guideline</h6>

                       <ul>
                       <li> Results – 3 points Focus on the
                        quality of gameplay and how well the game functions.</li>
                       <li> Methods & Techniques – 2 points Assess the quality of
                        game mechanics and design. Extra Mile (if any) – 1 point</li>
                       <li> Unique features, added value, or doing an additional
                        task </li>
                       <li> Code Readability – 2 points Evaluate the clarity
                        and organisation of the code for maintainability. </li>
                       <li> UI/UX
                        – 2 points Assess the user interface and player
                        experience, focusing on usability and clarity.</li>
                       </ul>
                    </div>
        </>
    );
};

export default AssGuideCognitive;