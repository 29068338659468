import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
    meetingNotesCollectionRef,
    myelinersCollectionRef,
} from "../../firebase/fire-app";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import { set } from "date-fns";
import { meetingNotesAssignmentMessage } from "../ViewClaimManagement/messageGenerator";
import emailjs from "@emailjs/browser";
import { webServerLive } from "../../firebase/liveServerStatus";

const AssignMeetingNotesTaker = () => {
    const [show, setShow] = useState(false);
    const [myeliners, loading, error] = useCollectionData(
        myelinersCollectionRef.orderBy("empID", "desc"),
        { idField: "id" }
    );
    const { register, handleSubmit, watch, errors } = useForm();

    const [selectedEmp, setSelectedEmp] = useState(null);

    const onSubmit = (data, e) => {
        if (selectedEmp) {
            const meetingDate = new Date(data.date);
            const assignedTo = selectedEmp.name;
            const notesDetails = {
                content: `<h2>Meeting Notes: </h2>`,
                meetingDate: meetingDate,
                title: data.title,
                assignedTo: assignedTo,
                assignedToEmail: selectedEmp.email,
                createdAt: new Date(),
                mailOne: selectedEmp.email,
                mailTwo: "zied.tayeb@myelinh.com",
            };
            const mailDetails = meetingNotesAssignmentMessage(
                notesDetails,
                "meetingNotes"
            );
            e.target.reset();

            // alert(JSON.stringify(notesDetails))
            meetingNotesCollectionRef.add(notesDetails).then(() => {
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetails,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                }
                setSelectedEmp(null);
                setShow(false);
            });
        } else {
            alert("Please select an employee!!!");
        }
    };

    const onSelectionChange = (e) => {
        // alert(JSON.stringify(e));
        const selectedEmpOne = myeliners.filter((item) => item.email === e)[0];
        setSelectedEmp(selectedEmpOne);
    };
    return (
        <>
            <Button variant="danger" size="sm" onClick={() => setShow(true)}>
                Assign Meeting Notes Taker
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Assign meeting notes taker
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form onSubmit={handleSubmit(onSubmit)} className="row">
                            <InputWrapper col="6" label="Employee ID">
                                <select
                                    onChange={(e) =>
                                        onSelectionChange(e.target.value)
                                    }
                                    id="disabledSelect"
                                    className="form-select"
                                >
                                    <option>Select Employee</option>
                                    {myeliners &&
                                        myeliners.length > 0 &&
                                        myeliners.map((item) => (
                                            <option value={item.email}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </InputWrapper>
                            <InputWrapper col="6" label="Meeting Date">
                                <input
                                    {...register("date")}
                                    name="date"
                                    type="date"
                                    className="form-control"
                                    id="date"
                                    aria-describedby="emailHelp"
                                />
                            </InputWrapper>
                            <InputWrapper col="12" label="Title">
                                <input
                                    {...register("title")}
                                    type="text"
                                    name="title"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <div className="col-12 text-center">
                                <button className="btn-primary btn btn-sm">
                                    Assign Now!
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AssignMeetingNotesTaker;
