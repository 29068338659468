import React from 'react';
import SidebarComponent from '../../Components/Shared/SidebarComponent';

const ViewTrainingRequirements = () => {
    return (
        <>
               <SidebarComponent>
                <section className="container">
                    <h3 className="mt-3">Training Requirements</h3>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewTrainingRequirements;