import React, { useState } from "react";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import { useAuth } from "../../Hooks/useAuth";
import { recruitmentCollectionRef } from "../../firebase/fire-app";
import { newCandidateAddedNotification, removeAssessorNotification } from "../ViewClaimManagement/messageGenerator";
import { webServerLive } from "../../firebase/liveServerStatus";
import emailjs from "@emailjs/browser";

const SlideChangeAssessor = ({ data }) => {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const { myelinners, currentUser } = useAuth();
    const [previousData, setPreviousData] = useState(data);

    const onFormSubmit = (formData, e) => {
        formData.lastUpdated = new Date();

        if(previousData.assessorOne === formData.assessorOne || previousData.assessorTwo){
            alert("Please select different assessors one");
            const mailData = {
                positionTitle: data.positionTitle,
                mailOne: previousData.assessorOne,
                mailTwo: "zied.tayeb@myelinh.com",
            }
            const mailDetails = removeAssessorNotification(mailData)
            if(webServerLive){
                emailjs.send(
                    "service_zinxo6v",
                    "template_eqvm7mf",
                    mailDetails,
                    "ygk5Yo8QhiJUWbVZM"
                );
            }
        }
        if(previousData.assessorTwo === formData.assessorOne || previousData.assessorTwo){
            alert("Please select different assessors two");
            const mailData = {
                positionTitle: data.positionTitle,
                mailOne: previousData.assessorTwo,
                mailTwo: "zied.tayeb@myelinh.com",
            }
            const mailDetails = removeAssessorNotification(mailData)
            if(webServerLive){
                emailjs.send(
                    "service_zinxo6v",
                    "template_eqvm7mf",
                    mailDetails,
                    "ygk5Yo8QhiJUWbVZM"
                );
            }
        }


        recruitmentCollectionRef
            .doc(data.id)
            .update({
                assessorOne: formData.assessorOne,
                assessorTwo: formData.assessorTwo,
            })
            .then(() => {

                const mailDataOne = {
    
                    positionTitle: data.positionTitle,
                    mailOne: data.assessorOne,
                    mailTwo: "zied.tayeb@myelinh.com",
                };
                const mailDataTwo = {
    
                    positionTitle: data.positionTitle,
                    mailOne: data.assessorTwo,
                    mailTwo: "zied.tayeb@myelinh.com",
                };
                const mailDetailsOne = newCandidateAddedNotification(mailDataOne)
                const mailDetailsTwo = newCandidateAddedNotification(mailDataTwo)
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetailsOne,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                    emailjs.send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetailsTwo,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                }
                e.target.reset();
                setShow(false);
            });
    };
    return (
        <>
            <div className="d-fle">
                <button
                    onClick={() => setShow(true)}
                    className="btn mb-2 btn-primary btn-sm"
                >
                    Change Assessor
                </button>

                <div>
                    <p>Assessor one: {data.assessorOne}</p>
                    <p>Assessor two: {data.assessorTwo}</p>
                </div>
            </div>

            {show && (
                <form onSubmit={handleSubmit(onFormSubmit)} className="row">
                    <InputWrapper col="5" label="Assessor One">
                        <select
                            name="assessorOne"
                            {...register("assessorOne")}
                            className="form-select"
                        >
                            <option value="">Select assessor 1</option>
                            {myelinners &&
                                myelinners.length > 0 &&
                                myelinners.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                ))}
                        </select>
                    </InputWrapper>
                    <InputWrapper col="5" label="Assessor Two">
                        <select
                            name="assessorTwo"
                            {...register("assessorTwo")}
                            className="form-select"
                        >
                            <option value="">Select assessor 2</option>
                            {myelinners &&
                                myelinners.length > 0 &&
                                myelinners.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                ))}
                        </select>
                    </InputWrapper>
                    <div className="col-2">
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                        >
                            Update
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default SlideChangeAssessor;
