import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FileViewer from "react-file-viewer";
import { customDateFormat } from "../../utilities";
import { StatusBadge } from "./FormElements";
import { claimsCollectionRef } from "../../firebase/fire-app";
import emailjs from "@emailjs/browser";
import { claimMessageGenerator } from "./messageGenerator";
import { webServerLive } from "../../firebase/liveServerStatus";

const ClaimViewerPopup = ({ claim, admin }) => {
    const [show, setShow] = useState(false);

    const onApproveClaim = () => {
        const mailData = {
            name: claim.name,
            empID: claim.empID,
            title: claim.title,
            amount: claim.amount,
            currency: claim.currency,
            status: "Approved",
            from_name: "Myelin-H HR",
            mailOne: claim.empEmail,
            mailTwo: "zied.tayeb@myelinh.com",
            mailThree: "samaher.garbaya@myelinh.com",
            mailFour: "info@myelinh.com",
        };
        const mailDetails = claimMessageGenerator(
            mailData,
            "approval",
            claim.name
        );

        if (admin) {
            claimsCollectionRef
                .doc(claim.id)
                .update({ status: "Approved" })
                .then(() => {
                    alert("Claim Approved");
                    if (webServerLive) {
                        emailjs.send(
                            "service_zinxo6v",
                            "template_eqvm7mf",
                            mailDetails,
                            "ygk5Yo8QhiJUWbVZM"
                        );
                    }
                    setShow(false);
                });
        }
    };

    const onRejectClaim = () => {
        const mailData = {
            empID: claim?.empID,
            title: claim.title,
            amount: claim.amount,
            currency: claim.currency,
            from_name: "Myelin-H HR",
            mailOne: claim.empEmail,
            mailTwo: "zied.tayeb@myelinh.com",
            mailThree: "samaher.garbaya@myelinh.com",
            mailFour: "info@myelinh.com",
            status: "Rejected",
        };
        const mailDetails = claimMessageGenerator(
            mailData,
            "approval",
            claim.name
        );

        if (admin) {
            claimsCollectionRef
                .doc(claim.id)
                .update({ status: "Approved" })
                .then(() => {
                    alert("Claim Rejected");
                    if (webServerLive) {
                        emailjs.send(
                            "service_zinxo6v",
                            "template_eqvm7mf",
                            mailDetails,
                            "ygk5Yo8QhiJUWbVZM"
                        );
                    }
                    setShow(false);
                });
        }
    };

    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                View
            </Button>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Claim Details - {claim.id}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                Title:{" "}
                                <span className="font-bold">
                                    {claim.title}{" "}
                                </span>{" "}
                                <StatusBadge status={claim.status} />
                            </p>

                            <div className="d-flex justify-content-between">
                                <p>
                                    Invoice No:{" "}
                                    <span className="font-bold">
                                        {claim.invoiceNo}
                                    </span>
                                </p>
                                <p>
                                    Amount:{" "}
                                    <span className="font-bold">
                                        {claim.currency} {claim.amount}
                                    </span>
                                </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p>
                                    Expense Date:{" "}
                                    <span className="font-bold">
                                        {customDateFormat(claim.expenseDate)}
                                    </span>
                                </p>
                                <p>
                                    Claim Date:{" "}
                                    <span className="font-bold">
                                        {customDateFormat(claim.claimDate)}
                                    </span>
                                </p>
                            </div>
                            <p>
                                <i>{claim.remarks}</i>
                            </p>
                        </div>
                        <div className="col-12">
                            <a
                                href={claim.supportingDoc}
                                className="btn btn-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View supporting document
                            </a>
                            {/* <img
                                src={claim.supportingDoc}
                                alt=""
                                className="img-fluid"
                            /> */}
                        </div>
                        {admin && (
                            <div className="col-12 text-center ">
                                <button
                                    onClick={onApproveClaim}
                                    className="btn btn-primary mx-2"
                                >
                                    APPROVE THIS CLAIM
                                </button>
                                <button
                                    onClick={onRejectClaim}
                                    className="btn btn-danger mx-2"
                                >
                                    REJECT THIS CLAIM
                                </button>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ClaimViewerPopup;
