import React, { useEffect, useState } from "react";
import "./career.scss";
import { jobPostsCollectionRef } from "../../firebase/fire-app";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CareerCard = ({ data, col }) => {
    const onDeletePost = () => {
        jobPostsCollectionRef.doc(data.id).delete();
    };
    const notify = (text) =>
        toast(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const [showToast, setShowToast] = useState(true);
    const onVisibilityChange = () => {
        jobPostsCollectionRef
            .doc(data.id)
            .update({ isVisible: !data.isVisible });
    };
    return (
        <>
            <div className={`col-md-${col ? col : 5} col-xl-4 mb-4`}>
                <div className={`career-card bg-white ${!data.isVisible && "card-outline"}`}>
                    <img src={data.img} alt="" className="img-fluid" />
                    <div className=" py-4 px-4 pb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="font-bold">{data.title}</h5>
                            <button
                            onClick={onVisibilityChange}
                            className={`btn btn-sm btn-${data.isVisible?"danger":"primary"}`}
                        >
                            {data.isVisible ? "Hide This" : "Show This"}
                        </button>
                        </div>
                        <p className="font-16 mb-3">{data.details}</p>
                        {/* <button className="btn btn-outline-primary">VIEW DETAILS</button> */}
                        <a
                            href={data.applyLink}
                            className="btn btn-outline-primary btn-sm me-2"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            VIEW DETAILS
                        </a>
                        <a
                            href={data.applyLink}
                            className="btn me-2 btn-primary ml-3 btn-sm"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            APPLY NOW
                        </a>
                        <button
                            onClick={onDeletePost}
                            className={`btn btn-sm btn-danger`}
                        >
                            Delete
                        </button>
                        {/* <button
                            onClick={() => notify("Item Deleted!")}
                            className="btn btn-secondary btn-sm"
                        >
                            Show toast
                        </button> */}
                         {!data.isVisible && <p className="mb-0 text-danger font-bold mt-3 font-italic font-12 text-right">** this post is hidden on myelinh.com</p>}
                    </div>
                   
                </div>
                {/* <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            /> */}
            
            </div>

            {/* {showToast && <ToastCom showToast={showToast}/>} */}
        </>
    );
};

export default CareerCard;
