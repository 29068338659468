import React, { useEffect, useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { Spinner } from "react-bootstrap";
import "./EmpCard.scss";
import { MdOutlinePersonRemove } from "react-icons/md";

const CompEmpBasic = ({ email, action }) => {
    const { myelinners } = useAuth();
    const [empData, setEmpData] = useState(null);

    useEffect(() => {
        if (myelinners && myelinners.length > 0) {
            const emp = myelinners.filter((item) => item.email === email);
            setEmpData(emp[0]);
        }
    }, [email, myelinners]);

    return (
        <>
            {empData ? (
                <div className="p-1 d-flex align-items-center">
                    <div className="dp mb-0 me-2">
                        <img src={empData.img} alt="" />
                    </div>

                    <div style={{minWidth: 200}} className="d-flex justify-content-between">
                        <h6 className="mb-0 font-14">{empData.name}</h6>
                        <MdOutlinePersonRemove className="text-danger" onClick={() => action(email)} />
                    </div>
                </div>
            ) : (
                <Spinner animation="grow" size="sm" />
            )}
        </>
    );
};

export default CompEmpBasic;
