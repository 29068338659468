import React, { useEffect, useState } from "react";
import PopupAddNewCandidate from "./PopupAddNewCandidate";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import { recruitmentCollectionRef } from "../../firebase/fire-app";
import { tr } from "date-fns/locale";
import PopupAssessorReview from "./PopupAssessorReview";

const AssessorViewRecruitment = ({email}) => {
    const [allCandidates, loading, error] = useCollectionData(
        recruitmentCollectionRef.where("candidateStatus", "==", "live"),
        { idField: "id" }
    );
    return (
        <>
            <div className="table-responsive">
                <table className="table mt-4  pr-5 table-bordered border-primary table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Sl.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Position</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allCandidates &&
                            allCandidates.map((item, index) => (
                                <CandidateRow
                                    data={item}
                                    index={index + 1}
                                    key={item.id}
                                    email={email}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
            {/* <div className="container">
                <div className="row">
                    {allCandidates &&
                        allCandidates.length > 0 &&
                        allCandidates.map((item) => (
                            <CandidateCard data={item} key={item.id} />
                        ))}
                </div>
            </div> */}
        </>
    );
};

export default AssessorViewRecruitment;

const CandidateRow = ({ data, index, email }) => {
    const [assessorStatus, setAssessorStatus] = useState(null)
    
    useEffect(()=>{
        if(data.assessorOne === email){
            setAssessorStatus('One')
        }else if(data.assessorTwo === email){
            setAssessorStatus('Two')
        }
    },[])
    if(assessorStatus){
        return (
            <tr>
                <td>{index}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.positionTitle}</td>
                <td>{"12/05/2024"}</td>
                <td>{"pending"}</td>
                <td><PopupAssessorReview assNo={assessorStatus} data={data}/></td>
            </tr>
        );
    }
};

const CandidateCard = ({ data }) => {
    return (
        <div className="col-md-4">
            <div className="card p-4">
                <div className="d-flex px justify-content-between">
                    <h6 className="font-bold font-weight-bold mb-0">
                        {data.positionTitle}
                    </h6>
                    <h6 className="font-bold font-weight-bold mb-0">
                        Assessing
                    </h6>
                </div>
                <hr className="pt-1" />
                <p className="mb-0 font-14">{data.name}</p>
                <p className="mb-2 font-14">{data.email}</p>
                <p className="mb-0 font-bold font-14">CV: {data.candidateCV}</p>
                <p className="mb-0 font-bold font-14">
                    Github: {data.gitHubProject}
                </p>
            </div>
        </div>
    );
};
