const adminSidebarMenus = [
    {
        id: 1,
        text: `Assign Task`,
        href: `/admin/assign-task`
    },
    {
        id: 2,
        text: `Expense Claims`,
        href: `/admin/expense-claims`
    },
    {
        id: 3,
        text: `Leave Applications`,
        href: `/admin/leave-applications`
    },
    {
        id: 4,
        text: `Remote Work Claim`,
        href: `/admin/remote-works`
    },
    {
        id: 5,
        text: `Blogs`,
        href: `/admin/blogs`
    },
    {
        id: 6,
        text: `Documents`,
        href: `/admin/documents`
    },
    {
        id: 6,
        text: `Gallery`,
        href: `/admin/gallery`
    },
    {
        id: 6,
        text: `Job Posts`,
        href: `/admin/jobs`
    },
    {
        id: 7,
        text: `All Myelinners`,
        href: `/admin/myeliners`
    },
    {
        id: 8,
        text: `Add Employee`,
        href: `/admin/add-employee`
    },
    {
        id: 9,
        text: `Edit Employee Info`,
        href: `/admin/edit-employee-info`
    },
    {
        id: 10,
        text: `Archive`,
        href: `/admin/archive`
    },
    {
        id: 11,
        text: `User Feedbacks`,
        href: `/admin/user-feedbacks`
    },
   
]

export default adminSidebarMenus;