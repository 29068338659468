import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { additionalMyelinnerDetailsCollectionRef } from "../../firebase/fire-app";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";

const PopupUpdateDeclaration = ({ employeeInfo, email, admin }) => {
    const { register, handleSubmit, watch, formState:{errors} } = useForm();

    const [formDisabilityStatus, setFormDisabilityStatus] = useState(null); 
    const [formConvictionsStatus, setFormConvictionsStatus] = useState(null);
    const [formDisabilityText, setFormDisabilityText] = useState(null);
    const [formConvictionsText, setFormConvictionsText] = useState(null);
    const [formHighestQualification, setFormHighestQualification] = useState(null);

    const [show, setShow] = useState(false);

    const [additionalDetails, loading, error] = useDocumentData(
        additionalMyelinnerDetailsCollectionRef.doc(email)
    );

    useEffect(() => {
        if (!loading && !additionalDetails && !admin) {
            setShow(true);
        }
        if(!loading && additionalDetails){
            setFormDisabilityStatus(additionalDetails?.declarationDisabilityStatus);
            setFormConvictionsStatus(additionalDetails?.declarationConvictionStatus);
            setFormDisabilityText(additionalDetails?.declarationDisabilityDetails);
            setFormConvictionsText(additionalDetails?.declarationConvictionDetails);
            setFormHighestQualification(additionalDetails?.declarationHighestQualification);
        }
    }, [loading]);

    const onDeclarationUpdated = () => {
        const data= {
            declarationDisabilityStatus: formDisabilityStatus,
            declarationDisabilityDetails: formDisabilityText?formDisabilityText:"",
            declarationConvictionStatus: formConvictionsStatus,
            declarationConvictionDetails: formConvictionsText?formConvictionsText:"",
            declarationHighestQualification: formHighestQualification?formHighestQualification:"",
        }
        data.lastUpdated = new Date()

        if(!formDisabilityStatus || !formConvictionsStatus || !formHighestQualification){
            alert("Please fill all required fields")
            return;
        }else{
            if(formDisabilityStatus === "YES" && !formDisabilityText){
                alert("Please fill all required fields")
                return;
            }
            else if(formConvictionsStatus === "YES" && !formConvictionsText){
                alert("Please fill all required fields")
                return;
            }
            else{
                additionalMyelinnerDetailsCollectionRef
                .doc(email)
                .set(data)
                .then(() => {
                    setShow(false);
                });
            }
        }
        // alert(JSON.stringify(data))
     
    };
    

    return (
        <>
            {additionalDetails && (
                <div>
                    <p className="font-bold mb-0">
                        # Any disability or long-term health condition status:
                    </p>
                    <p className="mb-3">
                        {formDisabilityStatus === "NO" ? (
                            <span className="btn btn-primary btn-sm">NO</span>
                        ) : (
                            <span className="btn btn-warning py-0 btn-sm">YES</span>
                        )}{" "}
                        -{" "}
                        {formDisabilityText &&
                            formConvictionsText}
                    </p>

                    <p className="font-bold mb-0"># Highest qualification:</p>
                    <p className="mb-3">
                        {formHighestQualification}
                    </p>

                    <p className="font-bold mb-0"># Unspent convictions:</p>
                    <p className="mb-3">
                        {formConvictionsStatus === "NO" ? (
                            <span className="btn btn-primary btn-sm">NO</span>
                        ) : (
                            <span className="btn btn-warning py-0 btn-sm">YES</span>
                        )}{" "}
                        -{" "}
                        {formConvictionsStatus==="YES" &&
                            formConvictionsText}
                    </p>
                </div>
            )}
            <div>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShow(true)}
                >
                    Update
                </Button>
            </div>

            {email && employeeInfo  && (
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header className="py-1 " closeButton>
                        {!loading && additionalDetails ? (
                            <h5
                                className="font-weight-bold mt-1"
                                id="example-custom-modal-styling-title"
                            >
                                Declaration - {employeeInfo.name}
                            </h5>
                        ) : (
                            <h5
                                className="font-weight-bold mt-1"
                                id="example-custom-modal-styling-title"
                            >
                                You are required to declare the following:
                            </h5>
                        )}
                    </Modal.Header>
                    <Modal.Body>
                       { <div
                            className="row"
                        >
                            <div className="col-md-6">
                                <div className="row">
                                    <InputWrapper
                                        col="12"
                                        label="Any disability or long-term health condition status:"
                                    >
                                        <select
                                           onChange={(e)=>setFormDisabilityStatus(e.target.value)}
                                            name="declarationDisability"
                                            className="form-control"
                                        >
                                            <option value={additionalDetails?.declarationDisabilityStatus}>{additionalDetails?.declarationDisabilityStatus}</option>
                                            <option>YES/NO</option>
                                            <option value="NO">NO</option>
                                            <option value="YES">YES</option>
                                        </select>
                                    </InputWrapper>

                                    {formDisabilityStatus === "YES" && <InputWrapper
                                        col="12"
                                        label="Details:"
                                    >
                                        <input
                                            defaultValue={
                                                additionalDetails?.declarationDisabilityDetails
                                            }
                                            onChange={(e)=>setFormDisabilityText(e.target.value)}
                                            name="declarationDisabilityText"
                                            type="text"
                                            className="form-control"
                                        />
                                    </InputWrapper>}
                                    <InputWrapper
                                        col="12"
                                        label="Highest Level of Qualification:"
                                    >
                                        <input
                                            defaultValue={
                                                additionalDetails?.declarationHighestQualification
                                            }
                                            onChange={(e)=>setFormHighestQualification(e.target.value)}
                                            name="declarationHighestQualification"
                                            type="text"
                                            className="form-control"
                                        />
                                    </InputWrapper>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                <InputWrapper
                                        col="12"
                                        label="Any unspent conviction:"
                                    >
                                        <select
                                           onChange={(e)=>setFormConvictionsStatus(e.target.value)}
                                            name="declarationConviction"
                                            className="form-control"
                                        >
                                            <option value={additionalDetails?.declarationConvictionStatus}>{additionalDetails?.declarationDisabilityStatus}</option>
                                            <option>YES/NO</option>
                                            <option value="NO">NO</option>
                                            <option value="YES">YES</option>
                                        </select>
                                    </InputWrapper>

                                    {formConvictionsStatus === "YES" && <InputWrapper
                                        col="12"
                                        label="Details:"
                                    >
                                        <input
                                            defaultValue={
                                                additionalDetails?.declarationDisabilityDetails
                                            }
                                            onChange={(e)=>setFormConvictionsText(e.target.value)}
                                            name="declarationConvictionText"
                                            type="text"
                                            className="form-control"
                                        />
                                    </InputWrapper>}
                                </div>
                            </div>

                            {/* <InputWrapper col="8" label="Image -">
                                        <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </InputWrapper> */}

                            <div className="col-12">
                                <button
                                onClick={onDeclarationUpdated}
                                    className="btn btn-primary"
                                >
                                    Update Declaration
                                </button>
                            </div>
                        </div>}
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default PopupUpdateDeclaration;
