import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";
import { assignedTasksCollectionRef } from "../../firebase/fire-app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import CompTaskCard from "../AdminAssignTask/CompTaskCard";
import CompTaskCardEmployee from "../AdminAssignTask/CompTaskCardEmployee";

const ViewMyTasks = () => {
    const { currentUser } = useAuth();
    const [empEmail, setEmpEmail] = useState("");
    const [liveStatus, setLiveStatus] = useState("active");

    useEffect(() => {
        if (currentUser) {
            setEmpEmail(currentUser.email);
        }
    }, [currentUser]);
    const [tasksList, setTasksList] = useState(null);

    const [assignedTasksList, listLoading, listError] = useCollectionData(
        assignedTasksCollectionRef
            .orderBy("createdAt", "desc")
            .where("liveStatus", "==", liveStatus),
        { idField: "id" }
    );

    useEffect(() => {
        if(assignedTasksList){
            
            setTasksList(assignedTasksList)
        }
    },[assignedTasksList])

    return (
        <>
        <SidebarComponent>
                <section className="sticky-top page-heading">
                    <div className="container">
                        <h3 className="m">My Tasks</h3>
                    </div>
                </section>
                <div className="container mt-3 d-none d-md-block">
                    <div className="row">
                        <div className="col-md-4">
                            <h5 className="mb-0 font-bold py-">New Tasks</h5>
                        </div>
                        <div className="col-md-4">
                            <h5 className="mb-0 font-bold py-">In progress</h5>
                        </div>
                        <div className="col-md-4">
                            <h5 className="mb-0 font-bold py-">Completed</h5>
                        </div>
                    </div>
                </div>
                <section className="task-container pt-4 bg-light mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                {tasksList &&
                                    tasksList
                                        .filter(
                                            (item) =>
                                                item.status ===
                                                "New task"
                                        )
                                        .map((item) => (
                                            <CompTaskCardEmployee
                                                data={item}
                                                key={item.id}
                                                empEmail={empEmail}
                                            />
                                        ))}
                            </div>
                            <div className="col-md-4">
                                {tasksList &&
                                    tasksList
                                        .filter(
                                            (item) =>
                                                item.status ===
                                                "In progress"
                                        )
                                        .map((item) => (
                                            <CompTaskCardEmployee
                                                data={item}
                                                key={item.id}
                                                empEmail={empEmail}

                                            />
                                        ))}
                            </div>
                            <div className="col-md-4">
                                {tasksList &&
                                    tasksList
                                        .filter(
                                            (item) =>
                                                item.status ===
                                                "Completed"
                                        )
                                        .map((item) => (
                                            <CompTaskCardEmployee
                                                data={item}
                                                key={item.id}
                                                empEmail={empEmail}

                                            />
                                        ))}
                            </div>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewMyTasks;
