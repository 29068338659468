import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays, set } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "./leave.scss";
import { useForm } from "react-hook-form";
import { wfhApplicationCollectionRef } from "../../firebase/fire-app";
import { Button } from "react-bootstrap";
import { useAuth } from "../../Hooks/useAuth";
import emailjs from "@emailjs/browser";
import { customDateFormat, screenSizePickerDateRange } from "../../utilities";
import { MandatoryAsterisk } from "../ViewClaimManagement/FormElements";
import { webServerLive } from "../../firebase/liveServerStatus";

const DateSelectionPopup = () => {
    const [show, setShow] = useState(false);
    const { currentUser } = useAuth();

    const { register, handleSubmit, watch, errors } = useForm();
    const [leaveType, setLeaveType] = useState("Vacation");
    const [leaveRemarks, setLeaveRemarks] = useState(null);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: "selection",
        },
    ]);
    const pickerDirection = screenSizePickerDateRange();

    const onLeaveSubmit = (data, e) => {
        if (leaveType && leaveRemarks) {
            let leaveData = {};
            leaveData.leaveType = leaveType;
            leaveData.leaveRemarks = leaveRemarks;
            leaveData.startDate = dateRange[0].startDate;
            leaveData.endDate = dateRange[0].endDate;
            leaveData.leaveDays =
                Math.round(
                    (dateRange[0].endDate - dateRange[0].startDate) /
                        (1000 * 60 * 60 * 24)
                ) + 1;
            leaveData.empID = currentUser?.empID;
            leaveData.status = "Pending";
            leaveData.empEmail = currentUser?.email;
            wfhApplicationCollectionRef.add(leaveData).then(() => {
                alert("Remote Work application submitted successfully");
                const mailData = {
                    mailSubject:
                        "New Remote Work Application from " + currentUser.name,
                    message:
                        "New Application from " +
                        currentUser.name +
                        " to work remotely.",
                    empID: leaveData.empID,
                    name: currentUser.name,
                    title: leaveData.leaveType,
                    duration:
                        leaveData.startDate.toDateString() +
                        " to " +
                        leaveData.endDate.toDateString(),
                    remarks: leaveData.leaveRemarks,
                    status: leaveData.status,
                    mailOne: "zied.tayeb@myelinh.com",
                    mailThree: "samaher.garbaya@myelinh.com",
                    mailFour: "info@myelinh.com",
                    mailTwo: currentUser.email,
                    from_name: "Myelin-H HR",
                };
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_vhzaawx",
                        mailData,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                }
                setDateRange([
                    {
                        startDate: new Date(),
                        endDate: addDays(new Date(), 3),
                        key: "selection",
                    },
                ]);
                setShow(false);
            });
        } else {
            alert("Please fill all the fields");
        }
    };
    return (
        <>
            <Button variant="primary" onClick={() => setShow(true)}>
                Apply to work remotely
            </Button>
            {currentUser && (
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header className="py-1 " closeButton>
                        <h5
                            className="font-weight-bold mt-1"
                            id="example-custom-modal-styling-title"
                        >
                            Apply to work remotely
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="title"
                                                className="form-label font-bold"
                                            >
                                                Title <MandatoryAsterisk />
                                            </label>
                                            <input
                                                onBlur={(e) =>
                                                    setLeaveType(e.target.value)
                                                }
                                                id="title"
                                                name="title"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="leaveRemarks"
                                                className="form-label font-bold"
                                            >
                                                Remarks <MandatoryAsterisk />{" "}
                                            </label>
                                            <textarea
                                                onBlur={(e) =>
                                                    setLeaveRemarks(
                                                        e.target.value
                                                    )
                                                }
                                                name=""
                                                className="form-control"
                                                id="leaveRemarks"
                                                cols="30"
                                                rows="2"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12 d-none d-md-block mt-3 mb-4">
                                        <button
                                            onClick={onLeaveSubmit}
                                            className="btn btn-primary"
                                        >
                                            Apply for Remote Work
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <span className="font-14">
                                    <i>Select Date Range:</i>{" "}
                                    <MandatoryAsterisk />{" "}
                                </span>

                                <DateRangePicker
                                    onChange={(item) =>
                                        setDateRange([item.selection])
                                    }
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={dateRange}
                                    direction={pickerDirection}
                                />
                            </div>
                            <div className="col-12 d-block d-md-none mt-3 mb-4">
                                <button
                                    onClick={onLeaveSubmit}
                                    className="btn btn-primary"
                                >
                                    Apply for Remote Work
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default DateSelectionPopup;
