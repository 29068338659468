import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";
import PopupUploadNewImage from "./PopupUploadNewImage";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
// import { useWebsiteConfig } from "../../Hooks/useWebsiteConfig";
import { Spinner } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { webGalleryCollectionRef } from "../../firebase/fire-app";
import { FaTrashAlt } from "react-icons/fa";

const ViewGallery = () => {
    const { currentUser } = useAuth();
    // const { webGalleryConfig, webConfigLoading } = useWebsiteConfig();
    const [allImages, imgLoading, imgError] = useCollectionData(
        webGalleryCollectionRef.orderBy("createdAt", "desc"),
        { idField: "id" }
    );
    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data, e) => {
        console.log(data);
    };

    const [imageURL, setImageURL] = useState(null);
    return (
        <SidebarComponent>
            <section className="sticky-top page-heading">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="mt">Gallery</h3>
                        <PopupUploadNewImage />
                    </div>
                </div>
            </section>
            <section className="mt-4">
                <div className="container">
                    <div className="row">
                        {allImages &&
                            allImages.length > 0 &&
                            allImages.map((item) => (
                                <ImgCard data={item} key={item.id} />
                            ))}
                    </div>
                </div>
            </section>
        </SidebarComponent>
    );
};

export default ViewGallery;

const ImgCard = ({ data }) => {
    const onImgDelete = () => {
        webGalleryCollectionRef
            .doc(data.id)
            .delete()
            .then(() => {
                "image deleted!";
            });
    };
    return (
        <div className="col-md-3">
            <div className="card mb-1">
                <img src={data.imgURL} alt="" className="img-fluid" />
               
            </div>
            <div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
            <p className="font-14 mb-1">{data.name}</p>
            <button onClick={onImgDelete} className="btn btn-sm p-1 btn-danger"><FaTrashAlt/></button>
                </div>
        </div>
    );
};
