import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DeletePopupConfirmation = ({ onDeleteTriggered, className, data, type, name }) => {
    const [show, setShow] = useState(false);

    const onDelete =async () => {
        onDeleteTriggered();
        setShow(false);
    }

    return (
        <>

            <Button variant="danger" className={className} size='sm' onClick={() => setShow(true)}>
                Delete
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                {/* <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Add Document -
                    </h5>
                </Modal.Header> */}
                <Modal.Body>

                    <div className="text-center card p-4">
                        <h5 className="text-danger font-weight-bold">
                            Are you sure you want to delete this?
                        </h5>
                        <div>
                            <p><u><b>{type} - {name}</b></u></p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button variant="danger" className='mx-2' size='sm' onClick={onDelete}>
                                Yes
                            </Button>
                            <Button variant="primary" className='mx-2' size='sm' onClick={() => setShow(false)}>
                                Cancel
                            </Button>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default DeletePopupConfirmation;
