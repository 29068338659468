import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "./leave.scss";
import { useForm } from "react-hook-form";
import { wfhApplicationCollectionRef } from "../../firebase/fire-app";
import DateSelectionPopup from "./DateSelectionPopup";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { customDateFormat } from "../../utilities";
import { StatusBadge } from "../ViewClaimManagement/FormElements";
import { useAuth } from "../../Hooks/useAuth";
import EditLeaveRemarksPopup from "./EditLeaveRemarksPopup";

const ViewRemoteWork = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const { currentUser } = useAuth();

    const [myLeaves, loading, error] = useCollectionData(
        wfhApplicationCollectionRef.where("empID", "==", currentUser.empID),
        { idField: "id" }
    );
    const [leaveType, setLeaveType] = useState("Vacation");
    const [leaveRemarks, setLeaveRemarks] = useState(null);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: "selection",
        },
    ]);

    const onLeaveSubmit = (data, e) => {
        let leaveData = {};
        leaveData.leaveType = leaveType;
        leaveData.leaveRemarks = leaveRemarks;
        leaveData.startDate = dateRange[0].startDate;
        leaveData.endDate = dateRange[0].endDate;
        leaveData.leaveDays = Math.round(
            (dateRange[0].endDate - dateRange[0].startDate) /
                (1000 * 60 * 60 * 24)
        );
        leaveData.empID = "M0001";
        leaveData.status = "Pending";
        leaveData.leaveID = "L0001";
        leaveData.name = currentUser?.name;

        wfhApplicationCollectionRef.add(leaveData).then(() => {
            alert("Leave submitted successfully");
        });
        alert(JSON.stringify(leaveData));
    };

    return (
        <>
            <SidebarComponent>
                <section className="page-heading sticky-top">
                    <div className="container">
                        <h3 className="">Apply for Remote Work</h3>
                    </div>
                </section>
                <section className="">
                    <div className="container ">
                        <DateSelectionPopup />
                        <div className="table-responsive">
                            {myLeaves && myLeaves.length > 0 && (
                                <table className="table mt-5 pr-5 table-bordered border-primary table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>Type</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Days</th>
                                            <th>Remarks</th>
                                            <th>Status</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myLeaves &&
                                            myLeaves.map((claim, index) => (
                                                <LeaveRow
                                                    claim={claim}
                                                    index={index}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewRemoteWork;

const LeaveRow = ({ claim, index }) => {
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{claim.leaveType}</td>
            <td>{customDateFormat(claim.startDate)}</td>
            <td>{customDateFormat(claim.endDate)}</td>
            <td> {claim.leaveDays}</td>
            <td>{claim.leaveRemarks} </td>
            <td>
                <StatusBadge status={claim.status} />
            </td>
            <td>
                <EditLeaveRemarksPopup claim={claim} />
            </td>
        </tr>
    );
};
