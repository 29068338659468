import React, { useState } from 'react';
import SidebarComponent from '../../Components/Shared/SidebarComponent';
import { claimsCollectionRef, fireStorage } from '../../firebase/fire-app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { customDateFormat } from '../../utilities';
import ClaimViewerPopup from '../ViewClaimManagement/ClaimViewerPopup';
import { StatusBadge } from '../ViewClaimManagement/FormElements';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

const AdminExpenseClaims = () => {
    const [status, setStatus] = useState('Pending');

    const [ myClaims, loading, error ] = useCollectionData(claimsCollectionRef.where("status", "==", status), { idField: 'id' });


    return (
        <>
            <SidebarComponent>
            <section className='page-heading sticky-top' >
                <div className="container d-flex justify-content-between">
<div>

<h3 className='text-dark'>Expense Claims</h3>
</div>
                   <div>
                   <ButtonGroup size='sm' className='' aria-label="Basic example">
                        <Button onClick={()=> setStatus("Pending")} size='sm' variant={`${status === "Pending" ? "warning" : "light"}`}>Pending</Button>
                        <Button onClick={()=> setStatus("Approved")} size='sm' variant={`${status === "Approved" ? "primary" : "light"}`}>Approved</Button>
                        <Button onClick={()=> setStatus("Rejected")} size='sm' variant={`${status === "Rejected" ? "danger" : "light"}`}>Rejected</Button>
                    </ButtonGroup>

                   </div>
                </div>
                </section>
                <hr />
                <section className="container mt-4">
                    <div className="table-responsive">
                    {myClaims && myClaims.length > 0 && <table className="table pr-5 table-bordered border-primary table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>EmpID</th>
                                            <th>Invoice No.</th>
                                            <th>Purpose</th>
                                            <th>Title</th>
                                            <th>Expense Date</th>
                                            <th>Claim Date</th>
                                            <th>Amount</th>
                                            <th>View Invoice</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myClaims && myClaims.map((claim, index) => <ClaimRow claim={claim} index={index} />)}
                                    </tbody>
                                </table>}
                    </div>
                
                </section>
            </SidebarComponent>
        </>
    );
};

export default AdminExpenseClaims;


const ClaimRow = ({ claim, index }) => {
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{claim.empID}</td>
            <td>{claim.invoiceNo}</td>
            <td>{claim.expensePurpose}</td>
            <td>{claim.title}</td>
            <td>{ customDateFormat(claim.expenseDate)}</td>
            <td>{customDateFormat(claim.claimDate)}</td>
            <td>{claim.currency} {claim.amount}</td>
            <td><ClaimViewerPopup claim={claim} admin={true} /> </td>
            <td><StatusBadge status={claim.status} /></td>
        </tr>
    )
}