import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
// import { useWebsiteConfig } from "../../Hooks/useWebsiteConfig";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { fireStorage, webGalleryCollectionRef } from "../../firebase/fire-app";

const PopupUploadNewImage = () => {
    const [show, setShow] = useState(false);
    const [activeTag, setActiveTag] = useState();
    const [fileName, setFileName] = useState("");

    const [imageDetails, setImageDetails] = useState(null);
    const [allTags, setAllTags] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [uploading, setUploading] = useState(false);
    // unique code generation
    const timestamp = Date.now();
    const timestampBase36 = timestamp.toString(36);
    const randomString = Math.random().toString(36).substring(2, 7);
    const uniqueCode = timestampBase36 + randomString;

    const { currentUser } = useAuth();
    // const { webGalleryConfig, webConfigLoading } = useWebsiteConfig();

    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data, e) => {
        console.log(data);
    };

    // useEffect(() => {
    //     if (webGalleryConfig) {
    //         setAllTags(webGalleryConfig.tags);
    //     }
    // }, [webGalleryConfig]);

    const onFileUpload = (e) => {
        setUploading(true);
        let uploadedFile = e.target.files[0];
        let uploadedFileName = uploadedFile.name;
        let fileType = uploadedFile.type;
        // alert(fileType);
        const fileRefName = fileName + uniqueCode + "." + fileType;
        const fileRef = fireStorage
            .ref()
            .child("all-images/")
            .child(fileRefName);

            let imageDetailsDoc = {
                name: fileName,
                createdAt: new Date(),
                fileType: fileType,
                fileRefName: fileRefName,
            }

        fileRef.put(uploadedFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
                imageDetailsDoc.imgURL = url
                setImageDetails(imageDetailsDoc)
                console.log(url);
                setImageURL(url);
                setUploading(false);
            });
        });
    };
    const addImageToDB = ()=>{
        console.log(imageDetails);
        webGalleryCollectionRef.add(imageDetails).then((e)=>{
            setFileName("")
            setImageDetails("")
            setImageURL(false)
            setShow(false)
        })
    }
    return (
        <>
            <button
                className={`btn btn-primary btn-sm`}
                onClick={() => setShow(true)}
            >
                Upload New Image
            </button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Upload New Image
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex align-items-center">
                        <InputWrapper col="8" label="Name">
                            <input
                                onChange={(e) => setFileName(e.target.value)}
                                defaultValue={fileName}
                                type="text"
                                name="name"
                                className="form-control"
                            />
                        </InputWrapper>
                        <div className="col-md-4">
                            {imageURL ? (
                                <button onClick={addImageToDB} className="btn-sm mb-3 btn btn-primary">
                                    Add Image
                                </button>
                            ) : (
                                <button
                                    disabled
                                    className="btn-sm mb-3 btn btn-primary"
                                >
                                    Add Image
                                </button>
                            )}
                        </div>
                        {/* <div className="col-12 mb-3">
                            {allTags ? (
                                allTags.map((item) => (
                                    <TagItem
                                        data={item}
                                        setActiveTag={setActiveTag}
                                        activeTag={activeTag}
                                    />
                                ))
                            ) : (
                                <Spinner size="sm" />
                            )}
                            <button className="btn btn-sm btn-light">
                                Add tag
                            </button>
                        </div> */}
                        <InputWrapper col="12" label="Tag">
                            <input
                                type="file"
                                onChange={(e) => onFileUpload(e)}
                                name="imageFile"
                                className="form-control"
                            />
                        </InputWrapper>
                        {/* <div className="col-12 text-center">
                            {imageURL ? (
                                <button className="btn btn-primary">
                                    Add Image
                                </button>
                            ) : (
                                <button disabled className="btn btn-primary">
                                    Add Image
                                </button>
                            )}
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupUploadNewImage;

const TagItem = ({ data, activeTag, setActiveTag }) => {
    const [tagClass, setTagClass] = useState("secondary");

    useEffect(() => {
        if (data === activeTag) {
            setTagClass("primary");
        } else {
            setTagClass("secondary");
        }
    }, [activeTag]);

    return (
        <button
        type="button"
            onClick={() => setActiveTag(data)}
            className={`btn me-2 btn-sm btn-${tagClass}`}
        >
            #{data}
        </button>
    );
};
