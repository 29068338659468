import React from "react";
import FileViewer from "react-file-viewer";

const ViewDemoDemo = () => {
    const fileInitial = "https://cors-anywhere.herokuapp.com/"
    const file = "https://myelinh.com/static/media/mobile-app.d48bd6cb.png";
    const type = "png";

    return (
        <>
            <section>
                <div className="container">
                    <FileViewer
                        fileType={type}
                        filePath={file}
                    />
                </div>
            </section>
        </>
    );
};

export default ViewDemoDemo;
