import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { bankAccountCollectionRef } from '../../firebase/fire-app';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InputWrapper from '../../Components/FormComponents/InputWrapper';
import { useForm } from 'react-hook-form';
import { webServerLive } from '../../firebase/liveServerStatus';
import { bankAccountUpdatedIndividual } from '../ViewClaimManagement/messageGenerator';
import emailjs from "@emailjs/browser";

const PopupBankAccount = ({ email, empDetails, admin }) => {
    const { register, handleSubmit, watch, errors } = useForm();

    const [show, setShow] = useState(false);
    const [bankDetails, loading, error] = useDocumentData(bankAccountCollectionRef.doc(email));

    const onBankInfoSubmit = (data, e) => {
        data.lastUpdated = new Date();
        const mailData = {
            name: empDetails.name,
            mailOne: "zied.tayeb@myelinh.com",
            mailTwo: "shahjahan.swajan@myelinh.com",
            from_name: "Myelin-H HR",
        }
        const mailDetails = bankAccountUpdatedIndividual(mailData);

        bankAccountCollectionRef.doc(email).set(data, { merge: true })
            .then(() => {
                e.target.reset();
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetails,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                  
                }
                setShow(false)
            })
    }

    useEffect(()=>{
        if(!loading && !bankDetails && !admin){
            setShow(true)
        }
    },[loading])
    return (
        <div className='table-responsive'>
            {bankDetails && <table className="table table-primary font-14 table-bordered border-primary table-hover">
                <tbody>
                <tr>
                    <td>Bank Name</td>
                    <td>{bankDetails.bankName}</td>
                </tr>
                <tr>
                    <td>Account Name</td>
                    <td>{bankDetails.accountName}</td>
                </tr>
                <tr>
                    <td>Account No</td>
                    <td>{bankDetails.accountNumber}
                        <CopyToClipboard text={bankDetails.accountNumber}>
                            <button className='btn ms-3 btn-sm btn-primary'>COPY</button>
                        </CopyToClipboard>
                    </td>
                </tr>
                <tr>
                    <td>Branch</td>
                    <td>{bankDetails.branch}</td>
                </tr>
                <tr>
                    <td>Routing no.</td>
                    <td>{bankDetails.routingNumber}</td>
                </tr>
                <tr>
                    <td>SWIFT/BIC Code</td>
                    <td>{bankDetails.swiftCode}</td>
                </tr>
                </tbody>
            </table>}
            <div>

                <Button variant="primary" size='sm' onClick={() => setShow(true)}>
                    Update Bank Account Details
                </Button>
            </div>

            {email && empDetails && <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className='py-1 ' closeButton>
                   {!loading && bankDetails ? <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Bank Account Info - {empDetails.name}
                    </h5> : <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        You have not updated your bank account details yet. Please complete below.
                    </h5> }
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onBankInfoSubmit)} className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <InputWrapper col="12" label="Account Name">
                                    <input defaultValue={bankDetails?.accountName} {...register("accountName", { required: true })} type="text" name="accountName" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="12" label="Account Number">
                                    <input defaultValue={bankDetails?.accountNumber} {...register("accountNumber", { required: true })} type="text" name="accountNumber" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="12" label="Bank Name">
                                    <input defaultValue={bankDetails?.bankName} {...register("bankName", { required: true })} type="text" name="bankName" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="12" label="Country">
                                    <input defaultValue={bankDetails?.country} {...register("country", { required: true })} type="text" name="country" className="form-control" />
                                </InputWrapper>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <InputWrapper col="12" label="Branch">
                                    <input defaultValue={bankDetails?.branch} {...register("branch")} type="text" name="branch" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="12" label="Branch Routing Code">
                                    <input defaultValue={bankDetails?.routingNumber} {...register("routingNumber")} type="text" name="routingNumber" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="12" label="SWIFT/BIC Code">
                                    <input defaultValue={bankDetails?.swiftCode} {...register("swiftCode", { required: true })} type="text" name="swiftCode" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="12" label="Address">
                                    <input defaultValue={bankDetails?.address} {...register("address")} type="text" name="address" className="form-control" />
                                </InputWrapper>
                            </div>
                        </div>


                        {/* <InputWrapper col="8" label="Image -">
                                        <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </InputWrapper> */}


                        <div className="col-12">
                            <button type='submit' className="btn btn-primary">Update Bank Info</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>}
        </div>
    );
};

export default PopupBankAccount;