import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { payslipsCollectionRef, fireStorage } from "../../firebase/fire-app";
import { SlOptionsVertical } from "react-icons/sl";
import { useForm } from "react-hook-form";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { Spinner } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { documentUploadMessage } from "../ViewClaimManagement/messageGenerator";
import emailjs from "@emailjs/browser";
import "./style.scss";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { convertMillisecondsToDateTime } from "../../utilities";
import { webServerLive } from "../../firebase/liveServerStatus";

const PopupAddPayslip = ({ empID, email, employeeDetails }) => {
    const [show, setShow] = useState(false);
    const [allPayslips, loading, error] = useCollectionData(
        payslipsCollectionRef.where("empID", "==", empID),
        { idField: "id" }
    );
    const { register, handleSubmit, watch, errors } = useForm();

    const allMonths = [
        "Select month",
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUNE",
        "JULY",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
    ];

    // payslip info
    const [payYear, setPayYear] = useState("2024");
    const [payMonth, setPayMonth] = useState(null);
    const [payUrl, setPayUrl] = useState(null);

    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        payslipsCollectionRef.where("empID", "==", empID).get();
        setPayMonth(null);
        setPayUrl(null);
        setPayYear(null);
    }, []);

    const onSubmit = (data, e) => {
        // alert(JSON.stringify(data))
        if (payMonth && payYear && payUrl) {
            data.createdAt = new Date();
            data.payMonth = payYear + "-" + payMonth;
            data.empID = empID;
            data.email = email;
            data.url = payUrl;
            payslipsCollectionRef.add(data).then((docSnapshot) => {
                const mailData = {
                    name: employeeDetails.name,
                    mailOne: employeeDetails.email,
                    mailTwo: "zied.tayeb@myelinh.com",
                    link: payUrl,
                };
                const mailDetails = documentUploadMessage(mailData, "payslip");
                alert(JSON.stringify(mailDetails));
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetails,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                }
                e.target.reset();
                setPayMonth(null);
                setPayUrl(null);
                setPayYear(null);
                setUploading(false);
                setShow(false);
            });
        }
    };
    const onFileUpload = (e) => {
        if (payMonth && payYear) {
            setUploading(true);
            let uploadedFile = e.target.files[0];
            let fileName = uploadedFile.name;
            let fileType = uploadedFile.type;
            // alert(fileType);
            const fileRef = fireStorage
                .ref()
                .child("payslips/" + empID)
                .child(payYear + payMonth + "." + fileType.split("/")[1]);
            fileRef.put(uploadedFile).then((snapshot) => {
                snapshot.ref.getDownloadURL().then((url) => {
                    console.log(url);
                    setPayUrl(url);
                });
            });
        }
    };
    return (
        <>
            <div className="row">
                <div className="col-12">
                    {allPayslips &&
                        allPayslips.length > 0 &&
                        allPayslips.map((data, index) => (
                            <PayslipCard key={index} data={data} />
                        ))}
                </div>
            </div>

            <div>
                <Button
                    variant="danger"
                    size="sm"
                    onClick={() => setShow(true)}
                >
                    Add Payslip
                </Button>
            </div>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add Payslip - {empID} - {payYear}"-"{payMonth}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)} className="row">
                        <div className="col-md-2">
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label font-bold"
                                >
                                    Employee ID{" "}
                                </label>
                                <input
                                    disabled
                                    defaultValue={empID}
                                    type="text"
                                    name="empID"
                                    className="form-control outline-light"
                                    id="exampleInputEmail1"
                                    placeholder="M00XX"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                        </div>
                        <div className="col-md-8"></div>
                        <InputWrapper col="3" label="Year">
                            <select
                                onChange={(e) => setPayYear(e.target.value)}
                                id="disabledSelect"
                                className="form-select"
                            >
                                <option value={2024}>Select year</option>
                                <option value={2024}>2024</option>
                                <option value={2023}>2023</option>
                            </select>
                        </InputWrapper>
                        <InputWrapper col="3" label="Month">
                            <select
                                onChange={(e) => setPayMonth(e.target.value)}
                                id="disabledSelect"
                                className="form-select"
                            >
                                {allMonths.map((month, index) => (
                                    <option key={index} value={month}>
                                        {month}
                                    </option>
                                ))}
                            </select>
                        </InputWrapper>
                        <InputWrapper col="5" label="Payslip">
                            <input
                                onChange={(e) => onFileUpload(e)}
                                placeholder="upload payslip"
                                type="file"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </InputWrapper>
                        <div className="col-12">
                            {payUrl ? (
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-sm"
                                >
                                    Add Payslip
                                </button>
                            ) : (
                                <button
                                    disabled
                                    className="btn btn-sm btn-primary"
                                >
                                    Add Payslip{" "}
                                    {!payUrl && uploading && (
                                        <Spinner size="sm" />
                                    )}{" "}
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAddPayslip;

const PayslipCard = ({ data }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const timeStamp = convertMillisecondsToDateTime(data?.createdAt);
    const deletePayslip = () => {
        const result = window.confirm("Do you want to continue?");
        if (result === true) {
            payslipsCollectionRef.doc(data.id).delete();
            alert("Payslip deleted successfully!");
            setShow(false);
        } else {
            alert("Delete action discarded.");
            setShow(false);
        }
    };
    return (
        <>
            {/* <p className="font-7">{data.url}</p> */}
            {/* <button onClick={()=>alert(JSON.stringify(data))} className="btn btn-danger btn-sm">Delete</button> */}
            {/* <a className='btn btn-sm btn-primary me-2 mb-2' target='_blank' href={data.url}>{data.payMonth}</a> */}
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <Tooltip variant="primary" id="overlay-example" {...props}>
                        <div className=" text-white">
                            Uploaded on: {timeStamp.date} | {timeStamp.time}
                        </div>
                        <button
                            className="btn mt-3 btn-danger btn-sm"
                            onClick={deletePayslip}
                        >
                            delete
                        </button>
                    </Tooltip>
                )}
            </Overlay>

            <span className="payslip-btn btn">
                <a
                    href={data.url}
                    className="link-text"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {data.payMonth}
                </a>
                {/* <SlOptionsVertical onClick={() => setShow(!show)} ref={target} /> */}
                <Button
                    className="btn-white p-0 ms-3"
                    ref={target}
                    onClick={() => setShow(!show)}
                >
                    <SlOptionsVertical />
                </Button>
            </span>
        </>
    );
};
