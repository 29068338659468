function getFileExtension(mimeType) {
    if (typeof mimeType !== "string") {
        return "";
    }

    const parts = mimeType.split("/");
    return parts.length > 1 ? parts[1] : "";
}

export { getFileExtension };
