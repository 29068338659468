import React from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import PopupAddNewBlog from "./PopupAddNewBlog";
import { useSearchParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import fireApp, { blogsCollectionRef } from "../../firebase/fire-app";
import CompBlogCard from "./CompBlogCard";
import "./blog.scss"
import BlogPostCard from "./BlogPostCard";

const ViewBlogs = () => {
    const [blogs, loading, error] = useCollectionData(blogsCollectionRef, {
        idField: "id",
    });
    return (
        <>
            <SidebarComponent>
                <section className="page-heading sticky-top">
                    <div className="d-flex container align-items-center justify-content-between">
                        <h3 className="mb-0">Blogs</h3>
                        <div>
                            <PopupAddNewBlog />
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            {blogs &&
                                blogs.length > 0 &&
                                blogs.map((item) => (
                                    <BlogPostCard col="6" blog={item} key={item.id}  />
                                ))}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};


export default ViewBlogs;
