import React from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import "./personal-profile.scss";
import { Link } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";
import { customDateFormat } from "../../utilities";
import EmployeeIndividualDocuments from "./EmployeeIndividualDocuments";
import EditPersonalInfoPopup from "./EditPersonalInfoPopup";
import CompPersonalPayslips from "./CompPersonalPayslips";
import PopupBankAccount from "../AdminEditEMployee/PopupBankAccount";
import EmployeeProfileTemplate from "./EmployeeProfileTemplate";

const ViewPersonalProfile = ({ email }) => {
    const { currentUser } = useAuth();
    return (
        <>
            {currentUser && (
                <SidebarComponent>
                    <EmployeeProfileTemplate
                        email={currentUser.email}
                        empID={currentUser.empID}
                        currentUser={currentUser}
                    />
                </SidebarComponent>
            )}
        </>
    );
};

export default ViewPersonalProfile;
