import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const DeleteConfirmationBasic = ({
    onDeleteTriggered,
    className,
    btnText,
    btnSize
}) => {
    const [show, setShow] = useState(false);

    const onDelete = async () => {
        onDeleteTriggered();
        setShow(false);
    };

    return (
        <>
            <Button
                variant="danger"
                className={className}
            size={btnSize?btnSize: "sm"}
                onClick={() => setShow(true)}
                
            >
                {btnText? btnText : "Delete"}
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                centered
                className="bg-transparent-green"
                aria-labelledby="example-custom-modal-styling-title"
            >
                {/* <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Add Document -
                    </h5>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="text-center card p-4">
                        <h5 className="text-danger font-weight-bold">
                            Are you sure you want to delete ?
                        </h5>

                        <div className="d-flex justify-content-center">
                            <Button
                                variant="danger"
                                className="mx-2"
                                size="sm"
                                onClick={onDelete}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="primary"
                                className="mx-2"
                                size="sm"
                                onClick={() => setShow(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DeleteConfirmationBasic;
