import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import EditorHRMeetingPage from "./EditorHRMeetingPage";
import { useAuth } from "../../Hooks/useAuth";

const ViewMeetingTools = () => {
    const groupMeeting = `https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MGRlMWExNDktMWQyYS00MWMwLThiNmItY2QxYzAyYjcwNmVk%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522ca893294-87d5-48cc-ae98-3b3a6377a87d%2522%252c%2522Oid%2522%253a%2522d73b7118-3aef-4e0b-8524-1ca1a2cbbc61%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=58a3cc61-4079-48fe-83a8-54b72d05d954&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true`;
    const brainStormingLink = `https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MDkyZmM4ZTUtYTAzZi00MjFlLTgxMmUtZWM1MTU2MTZlOTVh%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522ca893294-87d5-48cc-ae98-3b3a6377a87d%2522%252c%2522Oid%2522%253a%25227929553a-efbd-4e5a-bab5-4404274ccf7f%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=b1602b8e-2ff4-45fd-b1a5-93e614e50da2&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true`;
    const feedbackSessionLink = `https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MzQ5NmUxZjItNTQ0Ni00MDM0LWI5YjgtY2Q3MWFlNTM4ZDhj%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522ca893294-87d5-48cc-ae98-3b3a6377a87d%2522%252c%2522Oid%2522%253a%25227929553a-efbd-4e5a-bab5-4404274ccf7f%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=2eb45d45-4110-45fa-8664-e67eda4f3136&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true`;
    const informalMeetingLink = `https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MzQ5NmUxZjItNTQ0Ni00MDM0LWI5YjgtY2Q3MWFlNTM4ZDhj%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522ca893294-87d5-48cc-ae98-3b3a6377a87d%2522%252c%2522Oid%2522%253a%25227929553a-efbd-4e5a-bab5-4404274ccf7f%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=2eb45d45-4110-45fa-8664-e67eda4f3136&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true`;
    const {  isAdmin } = useAuth()

    const [pageContent, setPageContent] = useState("--");

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top page-heading">
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <h3 className="mb-0">Meetings & Tools</h3>
                            <div>
                               <EditorHRMeetingPage
                                    setPageContent={setPageContent} isAdmin={isAdmin}
                                />
                            </div>
                        </div>

                    </div>
                </section>
                <section className="bg-light">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                        <div dangerouslySetInnerHTML={{__html: pageContent}} className="col-md-10 bg-white policy-details p-5">


                            </div>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewMeetingTools;
