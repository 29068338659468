import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill styles
import { meetingNotesCollectionRef } from '../../firebase/fire-app';
import { useAuth } from '../../Hooks/useAuth';

const NotesEditor = ({ data, allowEdit }) => {
    const {currentUser} = useAuth()

    const [content, setContent] = useState('');

    const handleChange = (value) => {
        setContent(value);
    };

    useEffect(() => {
        if (data) {
            setContent(data.content)
        }
    }, [data])
    const onSaveNote = () => {
        meetingNotesCollectionRef.doc(data.id).update({
            content: content,
            lastUpdated: new Date(),
            lastUpdatedBy: currentUser?.name
        }).then(()=>{
            alert('Notes saved successfully!!!')
        })
    }

    return (
        < >
            <div className="mb-3 d-flex justify-content-between">
                <h6>{data.title}</h6>
                <p>Assigned to: {data.assignedTo}</p>
            </div>
      <div className="bg-white">
      <ReactQuill
                value={content}
                onChange={handleChange}
                modules={NotesEditor.modules}
                formats={NotesEditor.formats}
                placeholder="Write something..."
            />
      </div>
            {allowEdit && <div className="text-center mt-4">
                <button onClick={onSaveNote} className="btn btn-primary btn-sm">Save Notes</button>
            </div>}
        </>
    );
};


NotesEditor.modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
    ],
};

NotesEditor.formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
];

export default NotesEditor;
