import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";
import PopupDocumentUpload from "./PopupDocumentUpload";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
    documentsManagerCollectionRef,
    myelinersCollectionRef,
} from "../../firebase/fire-app";
import { FaArrowAltCircleRight, FaTrashAlt } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { defaultFormatDate } from "../../utilities/convertTime";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Button } from "react-bootstrap";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { CompAdminDocumentCard } from "./CompAdminDocumentCard";

const AdminDocumentManager = () => {
    const [activeCategory, setActiveCategory] = useState("all");
    const [selectedEmpID, setSelectedEmpID] = React.useState("-");
    const [myeliners, loading, error] = useCollectionData(
        myelinersCollectionRef.orderBy("empID", "desc"),
        { idField: "id" }
    );

    const docCategories = [
        `ID & Visa`,
        `Employment Documents`,
        `Miscellaneous`,
    ];
    const [companyDocs, docsLoading, docsError] = useCollectionData(
        documentsManagerCollectionRef
            .orderBy("createdAt", "desc"),
        { idField: "id" }
    );
    // documentsManagerCollectionRef
    //         .orderBy("createdAt", "desc")
    //         .where("category", "==", activeCategory).get().then(docs =>{
    //             docs.forEach(item =>{
    //                 console.log(item.data());
    //             })
    //         })
    return (
        <>
            <SidebarComponent>
                <section className="sticky-top page-heading">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="mt">Document Manager</h3>
                            {myeliners && myeliners.length>0 && <PopupDocumentUpload myeliners={myeliners} />}
                        </div>
                    </div>
                </section>
                <section className="mt-4">
                    <div className="container">
                        <div className="row d-flex justify-content-around">
                            <div className="col-md-7">
                                <ButtonGroup
                                    size="sm"
                                    className=""
                                    aria-label="Basic example"
                                >
                                    {docCategories.map((item) => (
                                        <Button
                                            onClick={() =>
                                                setActiveCategory(item)
                                            }
                                            size=""
                                            variant={`${
                                                activeCategory === item
                                                    ? "primary"
                                                    : "secondary"
                                            }`}
                                        >
                                            {item}
                                        </Button>
                                    ))}
                                    {/* <Button
                                    onClick={() => setActiveCategory("all")}
                                    size=""
                                    variant={`${
                                        activeCategory === "all"
                                            ? "primary"
                                            : "secondary"
                                    }`}
                                >
                                    Reset
                                </Button> */}
                                </ButtonGroup>
                            </div>
                            {/* <div className="col-md-5">
                                <div className="row">
                                    <InputWrapper col="12" label="Employee ID">
                                        <select
                                            onChange={(e) =>
                                                setSelectedEmpID(e.target.value)
                                            }
                                            id="disabledSelect"
                                            className="form-select"
                                        >
                                            <option>Select Employee</option>
                                            {myeliners &&
                                                myeliners.length > 0 &&
                                                myeliners.map((item) => (
                                                    <option value={item.id}>
                                                        {item.id}
                                                    </option>
                                                ))}
                                        </select>
                                    </InputWrapper>
                                </div>
                            </div> */}
                        </div>
                        <div className="row mt-4">
                            {companyDocs &&
                                companyDocs.length > 0 &&
                                companyDocs.map((item) => (
                                    <CompAdminDocumentCard
                                        selectedEmp={selectedEmpID}
                                        admin={true}
                                        data={item}
                                        key={item.id}
                                    />
                                ))}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default AdminDocumentManager;

export const DocumentCard = ({ data, col, admin, selectedEmp }) => {
    console.log(data);
    const onImgDelete = () => {
        documentsManagerCollectionRef
            .doc(data.id)
            .delete()
            .then(() => {
                "image deleted!";
            });
    };
    return (
        <>
            {selectedEmp ? (
                selectedEmp === data.employeeEmail ? (
                    <div className={`col-md-${col ? col : "6"} mb-2`}>
                        <div className="card p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="w-75">
                                    <p className="font-16 font-bold mb-1">
                                        {data.name}
                                    </p>
                                    <p className="font-14 mb-0">
                                        {data.category} |{" "}
                                        {defaultFormatDate(data.createdAt)}
                                    </p>
                                    {admin && (
                                        <p className="mb-0 font-14">
                                            {data.employeeEmail}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <a
                                        className="btn btn-sm p-1 btn-primary me-2"
                                        href={data.fileURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaLink />
                                    </a>
                                    <button
                                        onClick={onImgDelete}
                                        className="btn btn-sm p-1 btn-danger"
                                    >
                                        <FaTrashAlt />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )
            ) : (
                <div className={`col-md-${col ? col : "6"} mb-2`}>
                    <div className="card p-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="w-75">
                                <p className="font-16 font-bold mb-1">
                                    {data.name}
                                </p>
                                <p className="font-14 mb-0">
                                    {data.category} |{" "}
                                    {defaultFormatDate(data.createdAt)}
                                </p>
                                {admin && (
                                    <p className="mb-0 font-14">
                                        {data.employeeEmail}
                                    </p>
                                )}
                            </div>
                            <div>
                                <a
                                    className="btn btn-sm p-1 btn-primary me-2"
                                    href={data.fileURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLink />
                                </a>
                                <button
                                    onClick={onImgDelete}
                                    className="btn btn-sm p-1 btn-danger"
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
