import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { myelinersCollectionRef } from "../../firebase/fire-app";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PopupMyelinEditOption from "./PopupMyelinEditOption";

const AdminMyeliners = () => {
    const [allMyeliners, loading, error] = useCollectionData(
        myelinersCollectionRef.orderBy("createdAt", "asc"),
        { idField: "id" }
    );
    return (
        <>
            <SidebarComponent>
                <section className="container">
                    <h3 className="mt-3">Myeliners</h3>
                </section>
                <hr />

                <section>
                    <div className="container table-responsive px-5">
                        {allMyeliners && allMyeliners.length > 0 && (
                            <table className="table pr-5 table-bordered border-primary table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Sl.</th>
                                        <th>EmpID</th>
                                        <th>Name</th>
                                        <th>Designation</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allMyeliners &&
                                        allMyeliners.map((data, index) => (
                                            <TableRow
                                                data={data}
                                                index={index}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default AdminMyeliners;

const TableRow = ({ data, index }) => {
    const onDelete = () => {
        if (data && data.empID) {
            myelinersCollectionRef
                .doc(data.empID)
                .delete()
                .then(() => {
                    alert("Employee Deleted");
                })
                .catch((err) => {
                    alert("Error Deleting Employee");
                });
        }
    };
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{data.empID}</td>
            <td>{data.name}</td>
            <td>{data.designation}</td>
            <td>
                <CopyToClipboard text={data.email}>
                    <button className="btn btn-sm btn-dark">
                        {data.email}
                    </button>
                </CopyToClipboard>
            </td>

            <td>{data.phone}</td>

            <td>
                <Link
                    to={"/admin/myeliners/" + data.email}
                    className="btn btn-sm btn-primary"
                >
                    Details
                </Link>
                <PopupMyelinEditOption data={data} />
            </td>
        </tr>
    );
};
