import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import CompEmpOnComments from "../../Components/EmployeeCard/CompEmpOnComments";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { assignedTasksCollectionRef } from "../../firebase/fire-app";

const PopupAssignRoleToTask = ({
    setRoleWise,
    assignedTo,
    roleWise,
    taskID,
    taskDetails,
    myelinners,
}) => {
    const [show, setShow] = useState(false);
    const [roleBasedAssignees, setRoleBasedAssignees] = useState([]);
    const { register, handleSubmit, watch, errors } = useForm();

    const onFormOneSubmit = (data, e) => {};

    const onNewRoleAssignment = (emp, role) => {
        const existingIndex = roleBasedAssignees.findIndex(
            (assignee) => assignee.email === emp
        );

        // If entry exists, update the role, else add new entry
        if (existingIndex !== -1) {
            const updatedRoleBasedAssignees = [...roleBasedAssignees];
            updatedRoleBasedAssignees[existingIndex].role = role;

            setRoleBasedAssignees(updatedRoleBasedAssignees);
        } else {
            // Create a new role assignment object
            const newRoleAssignment = {
                email: emp,
                role: role,
            };
            // Update the state with the new role assignment
            const updatedRoles = [...roleBasedAssignees, newRoleAssignment];
            setRoleBasedAssignees([...updatedRoles]);
        }
    };
    useEffect(() => {
        if (taskDetails && taskDetails.roleBasedAssignees) {
            setRoleBasedAssignees(taskDetails.roleBasedAssignees);
        }
    }, [taskDetails]);

    const updateNewTaskRoles = () => {
        if (roleBasedAssignees.length > 0) {
            assignedTasksCollectionRef
                .doc(taskID)
                .update({
                    roleBasedAssignment: true,
                    roleBasedAssignees: roleBasedAssignees,
                })
                .then(() => {
                    setShow(false);
                });
        } else {
            alert("Please assign roles first. Thank you!");
        }
    };

    const [newAssignee, setNewAssignee] = useState("");

    return (
        <>
            <Button variant="danger" size="sm" onClick={() => setShow(true)}>
                {roleWise ? "Update roles" : "Assign roles"}
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                centered
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        {roleWise ? "Update roles" : "Assign roles"}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    {roleWise ? (
                        <div>
                            {/* role already assigned */}

                            <div>
                                {roleBasedAssignees &&
                                    roleBasedAssignees.length > 0 &&
                                    roleBasedAssignees.map((item) => (
                                        <div className="row">
                                            <div className="col-md-4">
                                                <CompEmpOnComments
                                                    email={item.email}
                                                />
                                            </div>
                                            <InputWrapper col="4" label="Role">
                                                <select
                                                    onChange={(e) =>
                                                        onNewRoleAssignment(
                                                            item.email,
                                                            e.target.value
                                                        )
                                                    }
                                                    name={item.email}
                                                    id={item.email}
                                                    className="form-select"
                                                >
                                                    <option
                                                        value={item.role}
                                                        selected
                                                    >
                                                        {item.role}
                                                    </option>
                                                    <option value="Assistant">
                                                        Assistant
                                                    </option>
                                                    <option value="Task Coordinator">
                                                        Task Coordinator
                                                    </option>
                                                    <option value="Main Contributor">
                                                        Main Contributor
                                                    </option>
                                                    <option value="Supervisor">
                                                        Supervisor
                                                    </option>
                                                </select>
                                            </InputWrapper>
                                        </div>
                                    ))}
                                {/* adding new ones */}

                                <div className="row">
                                    <InputWrapper col="4" label="New assignee">
                                        <select
                                            onChange={(e) =>
                                                setNewAssignee(e.target.value)
                                            }
                                            name="new"
                                            id="new"
                                            className="form-select"
                                        >
                                            <option value="">
                                                Select employees
                                            </option>
                                            {myelinners &&
                                                myelinners.length > 0 &&
                                                myelinners.map((item) => (
                                                    <option value={item.id}>
                                                        {item.id}
                                                    </option>
                                                ))}
                                        </select>
                                    </InputWrapper>
                                    {newAssignee && (
                                        <InputWrapper col="4" label="Role">
                                            <select
                                                onChange={(e) =>
                                                    onNewRoleAssignment(
                                                        newAssignee,
                                                        e.target.value
                                                    )
                                                }
                                                name="newRole"
                                                id="newRole"
                                                className="form-select"
                                            >
                                                <option value="Assistant">
                                                    Assistant
                                                </option>
                                                <option value="Task Coordinator">
                                                    Task Coordinator
                                                </option>
                                                <option value="Main Contributor">
                                                    Main Contributor
                                                </option>
                                                <option value="Supervisor">
                                                    Supervisor
                                                </option>
                                            </select>
                                        </InputWrapper>
                                    )}
                                </div>

                                <div className=" text-center">
                                    <button
                                        onClick={updateNewTaskRoles}
                                        className="btn btn-sm btn-primary"
                                    >
                                        Update roles
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {/* need to assign roles here */}
                            <div>
                                {assignedTo &&
                                    assignedTo.length > 0 &&
                                    assignedTo.map((item) => (
                                        <div className="row">
                                            <div className="col-md-4">
                                                <CompEmpOnComments
                                                    email={item}
                                                />
                                            </div>
                                            <InputWrapper col="4" label="Role">
                                                <select
                                                    onChange={(e) =>
                                                        onNewRoleAssignment(
                                                            item,
                                                            e.target.value
                                                        )
                                                    }
                                                    name={item}
                                                    id={item}
                                                    className="form-select"
                                                >
                                                    <option value="Assistant">
                                                        Assistant
                                                    </option>
                                                    <option value="Task Coordinator">
                                                        Task Coordinator
                                                    </option>
                                                    <option value="Main Contributor">
                                                        Main Contributor
                                                    </option>
                                                    <option value="Supervisor">
                                                        Supervisor
                                                    </option>
                                                </select>
                                            </InputWrapper>
                                        </div>
                                    ))}
                                <div className=" text-center">
                                    <button
                                        onClick={updateNewTaskRoles}
                                        className="btn btn-sm btn-primary"
                                    >
                                        Update roles
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAssignRoleToTask;
