import React, { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { taskCommentsCollectionRef } from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import CompEmpBasic from "../../Components/EmployeeCard/CompEmpBasic";
import CompEmpOnComments from "../../Components/EmployeeCard/CompEmpOnComments";
import { timeStampToDate } from "../../utilities";
import { webServerLive } from "../../firebase/liveServerStatus";
import emailjs from "@emailjs/browser";
import { newCommentOnTheTask } from "../ViewClaimManagement/messageGenerator";
import { Spinner } from "react-bootstrap";

const CompTaskComments = ({ data }) => {
    const { currentUser } = useAuth();
    const [systemLoading, setSystemLoading] = useState(null);
    const [newComment, setNewComment] = useState("");
    const [taskComments, loading, error] = useCollectionData(
        taskCommentsCollectionRef
            .where("taskID", "==", data.id)
            .orderBy("createdAt", "desc"),
        { idField: "id" }
    );

    taskCommentsCollectionRef
        .where("taskID", "==", data.id)
        .orderBy("createdAt", "desc")
        .get();

    const onCommentSend = () => {
        if (newComment) {
            const commentDetails = {
                comment: newComment,
                createdAt: new Date(),
                taskID: data.id,
                commentedBy: currentUser.email,
            };
            // alert(JSON.stringify(commentDetails))
            const mailData = {
                commenter: currentUser.name,
                taskTitle: data.title,
                commentText: newComment,
                mailOne: data.assignees,
                mailTwo: "shahjahan.swajan@myelinh.com"
            };
            const mailDetails = newCommentOnTheTask(mailData);
            setSystemLoading(true);
            taskCommentsCollectionRef.add(commentDetails).then(() => {
                setSystemLoading(false);

                if (webServerLive) {
                    emailjs
                        .send(
                            "service_zinxo6v",
                            "template_eqvm7mf",
                            mailDetails,
                            "ygk5Yo8QhiJUWbVZM"
                        )
                        .then(() => {
                            //
                        });
                } else {
                    setSystemLoading(false);
                }

                setNewComment("");
            });
        }
    };
    return (
        <>
            <p className="font-14 font-bold mb-1">Comments:</p>
            <textarea
                onBlur={(e) => setNewComment(e.target.value)}
                name=""
                id=""
                rows="2"
                defaultValue={newComment}
                className="form-control low-radius"
            ></textarea>
            <div className="text-right mt-2">
               {systemLoading? 
                <button disabled
                className="btn-light btn btn-sm"
            >
                Send <Spinner animation="grow" size="sm"/>
            </button>
               : <button
                    onClick={onCommentSend}
                    className="btn-light btn btn-sm"
                >
                    Send
                </button>}
            </div>
            {taskComments &&
                taskComments.length > 0 &&
                taskComments.map((item) => (
                    <CommentComponent data={item} key={item.id} />
                ))}
        </>
    );
};

export default CompTaskComments;

const CommentComponent = ({ data }) => {
    return (
        <>
            {data && (
                <div className=" mx-0 mx-md-5 px-3 px-md-4 comment-box py-2 py-md-3 mb-3 bg-light">
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-between">
                            <CompEmpOnComments email={data.commentedBy} />
                            <span className="font-12 text-primary">
                                {timeStampToDate(data.createdAt)}
                            </span>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <p className="font-14 mb-0  word-break">{data.comment}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
