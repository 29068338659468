import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import ReactQuill from "react-quill";
import {
    timeStampToDefaultInputValue,
    timeStampToJSDate,
} from "../../utilities/firebasetimestamp";
import { myelinersCollectionRef } from "../../firebase/fire-app";

const PopupMyelinEditOption = ({ data }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [shortDescriptionContent, setShortDescriptionContent] = useState("");
    const [publicDescription, setPublicDescription] = useState("");

const [newSerial, setNewSerial] = useState(data.serial)
const [myelinLive, setMyelinLive] = useState(data.myelinLive?data.myelinLive:false)

    const [systemLoading, setSystemLoading] = useState(false);
    const [employeeUpdates, setEmployeeUpdates] = useState({});
    useEffect(() => {
        if (data) {
            if (data.shortDescription) {
                setShortDescriptionContent(data.shortDescription);
            }
            if (data.publicDescription) {
                setPublicDescription(data.publicDescription);
            }
        }
    }, [data]);

    const onFieldValueChange = (type, value) => {
        const newData = { ...employeeUpdates, [type]: value };
        setEmployeeUpdates({ ...newData });
    };

    const onSaveChanges = () => {
        setSystemLoading(true);
        let empDetails = {
            ...data,
            ...employeeUpdates,
            serial: newSerial,
            myelinLive: myelinLive,
            shortDescription: shortDescriptionContent,
            publicDescription: publicDescription,
        };
        empDetails.lastUpdated = new Date();
        console.log(empDetails);
        myelinersCollectionRef
            .doc(data.email)
            .update({
                ...empDetails,
            })
            .then(() => {
                setSystemLoading(false);
            });
    };
    return (
        <>
            <button
                onClick={() => setShowPopup(true)}
                className="btn btn-sm btn-secondary"
            >
                Edit
            </button>
            <Modal
                show={showPopup}
                onHide={() => setShowPopup(false)}
                dialogClassName="modal-90w"
                aria-labelledby="myelin-popup"
            >
                <Modal.Header className="py-1" closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Edit Basic Info - {data.name}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <InputWrapper label="#" col="2">
                            <input
                                className="form-control"
                                type="text"
                                defaultValue={data.serial}
                                onChange={(e)=> setNewSerial(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper label="Name" col="4">
                            <input
                                className="form-control"
                                type="text"
                                value={data.name}
                                readOnly
                            />
                        </InputWrapper>
                        <InputWrapper label="Designation" col="5">
                            <input
                                className="form-control"
                                type="text"
                                defaultValue={data.designation}
                                onChange={(e)=>onFieldValueChange("designation", e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper label="ID" col="2">
                            <input
                                className="form-control"
                                type="text"
                                value={data.empID}
                                readOnly
                            />
                        </InputWrapper>
                        <InputWrapper label="DoJ" col="3">
                            <input
                                className="form-control"
                                type="date"
                                value={timeStampToDefaultInputValue(data.doj)}
                                // onChange={}
                                readOnly
                            />
                        </InputWrapper>
                        <InputWrapper label="DoJ" col="3">
                            <input
                                className="form-control"
                                type="date"
                                onChange={(e) =>
                                    onFieldValueChange("doj", e.target.value)
                                }
                            />
                        </InputWrapper>
                        <div className="col-md-3">
                            {
                                myelinLive?<button onClick={()=> setMyelinLive(false)} className="btn btn-danger">Hide on myelinh.com</button>:
                                <button onClick={()=> setMyelinLive(true)} className="btn btn-primary">Show on myelinh.com</button>
                            }
                            
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3 mb-0 font-bold">
                                Short Description:
                            </p>
                            <ReactQuill
                                value={shortDescriptionContent}
                                onChange={(value) =>
                                    setShortDescriptionContent(value)
                                }
                                modules={PopupMyelinEditOption.modules}
                                formats={PopupMyelinEditOption.formats}
                                placeholder="Write something..."
                            />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3 mb-0 font-bold">
                                Public Description:
                            </p>
                            <ReactQuill
                                value={publicDescription}
                                onChange={(value) =>
                                    setPublicDescription(value)
                                }
                                modules={PopupMyelinEditOption.modules}
                                formats={PopupMyelinEditOption.formats}
                                placeholder="Write something..."
                            />
                        </div>
                        <div className="col-12 text-center">
                            {systemLoading ? (
                                <button
                                    disabled
                                    className="btn btn-sm btn-primary mt-3"
                                >
                                    Save changes <Spinner animation="grow" size="sm"/>
                                </button>
                            ) : (
                                <button
                                    onClick={onSaveChanges}
                                    className="btn btn-sm btn-primary mt-3"
                                >
                                    Save changes
                                </button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
PopupMyelinEditOption.modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
    ],
};

PopupMyelinEditOption.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
];

export default PopupMyelinEditOption;
