import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import InputWrapper from '../../Components/FormComponents/InputWrapper';
import { myelinersCollectionRef } from '../../firebase/fire-app';



const EditPersonalInfoPopup = ({ empID, currentUser }) => {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data, e) => {
        // alert(JSON.stringify(data))
        let dob;
        if (data.dob) {
            dob = new Date(data.dob);
            data.dob = dob;
        }
        data.lastUpdated = new Date();
        myelinersCollectionRef.doc(currentUser.email).update(data)
            .then(() => {
                alert('Info updated')
                e.target.reset()
                setShow(false)
            })
    }
    return (
        <>
            <Button variant="primary" size='sm' onClick={() => setShow(true)}>
                Edit Personal Info
            </Button>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Edit Personal Info - {empID}
                    </h5>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleSubmit(onSubmit)} className="row">
                        {/* <div className="col-md-3">
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label font-bold">Employee ID</label>
                                <input disabled defaultValue={empID} type="text" name="empID" className="form-control outline-light" id="exampleInputEmail1" placeholder='M00XX' aria-describedby="emailHelp" />
                            </div>
                        </div> */}
                        <InputWrapper col="4" label="Name">
                            <input defaultValue={currentUser.name} {...register("name")} type="text" name="name"  className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="5" label="Designation">
                            <input defaultValue={currentUser.designation} {...register("designation")} type="text" name="designation" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="3" label="DOB">
                            <input  {...register("dob")} name='dob' type="date" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </InputWrapper>
                        <InputWrapper col="4" label="Email">
                            <input defaultValue={currentUser.email} {...register("email")} type="email" placeholder='xxxxx@myelinh.com' name="email" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="4" label="Personal Email">
                            <input defaultValue={currentUser.personalEmail} {...register("personalEmail")} type="email" name="personalEmail" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="4" label="Phone">
                            <input defaultValue={currentUser.phone} {...register("phone")} type="text" name="phone" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="4" label="WhatsApp">
                            <input defaultValue={currentUser.whatsApp}{...register("whatsApp")} type="text" name="whatsApp" className="form-control" />
                        </InputWrapper>



                        <InputWrapper col="4" label="Address">
                            <input defaultValue={currentUser.address}{...register("address")} type="text" name="address" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="4" label="Home Country">
                            <input defaultValue={currentUser.country}{...register("country")} type="text" name="country" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="4" label="Current Location">
                            <input defaultValue={currentUser.location}{...register("location")} type="text" name="location" className="form-control" />
                        </InputWrapper>

                        <div className="col-12 py-3"></div>
                        <InputWrapper col="4" label="Marital Status">
                            <input defaultValue={currentUser.maritalStatus}{...register("maritalStatus")} type="text" name="whatsApp" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="4" label="Preferred Language">
                            <input defaultValue={currentUser.preferredLang}{...register("preferredLang")} type="text" name="preferredLang" className="form-control" />
                        </InputWrapper>

                        <InputWrapper col="4" label="Other Languages">
                            <input defaultValue={currentUser.allLang}{...register("allLang")} type="text" name="allLang" className="form-control" />
                        </InputWrapper>


                        <InputWrapper col="4" label="Emergency Contact Person">
                            <input defaultValue={currentUser.emergencyContact}{...register("emergencyContact")} type="text" name="emergencyContact" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="4" label="Relation">
                            <input defaultValue={currentUser.emergencyRelation}{...register("emergencyRelation")} type="text" name="emergencyRelation" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col="4" label="Contact Number">
                            <input defaultValue={currentUser.emergencyNumber}{...register("emergencyNumber")} type="text" name="emergencyNumber" className="form-control" />
                        </InputWrapper>
                        {/* <InputWrapper col="8" label="Image -">
                                        <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </InputWrapper> */}


                        <div className="col-12 text-center">
                            <button type='submit' className="btn btn-primary">Update Info</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditPersonalInfoPopup;