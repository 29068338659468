import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PDFViewer from 'pdf-viewer-reactjs'


const EmployeeIndividualDocuments = ({empID} ) => {
    // const empID = "PG1122ML"

    const [show, setShow] = useState(false);
    const [showDoc, setShowDoc] = useState(false);

    const onDocClick = (doc) => {
        setShowDoc(doc);
        setShow(true);
    }

    return (
        <>
            <div className="d-block">
                <button onClick={() => onDocClick(`/docs/${empID}/contract.pdf`)} className="btn btn-block d-block mb-3 btn-primary">Employment Contract</button>
                <button onClick={() => onDocClick(`/docs/${empID}/jobperks.pdf`)} className="btn btn-block d-block mb-3 btn-primary">Job Perks</button>
                <button onClick={() => onDocClick(`/docs/${empID}/passport.pdf`)} className="btn btn-block btn-primary d-block mb-3">ID/Passport</button>

            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Document
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    {showDoc && <PDFViewer
                        document={{
                            url: showDoc,
                        }}
                    />}

                </Modal.Body>
            </Modal>
        </>
    );
};

export default EmployeeIndividualDocuments;