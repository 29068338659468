import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import { useAuth } from "../../Hooks/useAuth";
import { advisorsCollectionRef, myelinersCollectionRef } from "../../firebase/fire-app";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles

const EditAdvisorProfileInfo = ({ data }) => {
    const { currentUser } = useAuth();
    const [show, setShow] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const [shortDescriptionContent, setShortDescriptionContent] = useState("");

    useEffect(() => {
        if (data) {
            setShortDescriptionContent(data.shortDescription);
        }
    }, [data]);

    const handleChange = (value) => {
        setShortDescriptionContent(value);
    };

    const onFormSubmit = (submission, e) => {
        if (currentUser && currentUser.email) {
            submission.shortDescription = shortDescriptionContent;
            advisorsCollectionRef
                .doc(data.email)
                .update(submission)
                .then((res) => {
                    setShow(false);
                });
        }
    };



    return (
        <>
            <Button variant="danger" size="sm" onClick={() => setShow(true)}>
                Edit Info
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Edit Info
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="row">
                            <InputWrapper col="12" label="LinkedIn">
                                <input
                                    {...register("socialLinkedIn")}
                                    type="text"
                                    name="socialLinkedIn"
                                    className="form-control"
                                    defaultValue={data.socialLinkedIn}
                                />
                            </InputWrapper>
                            <InputWrapper col="12" label="Instagram">
                                <input
                                    {...register("socialInstagram")}
                                    type="text"
                                    name="socialInstagram"
                                    className="form-control"
                                    defaultValue={data.socialInstagram}
                                />
                            </InputWrapper>
                            <InputWrapper col="12" label="Twitter">
                                <input
                                    {...register("socialTwitter")}
                                    type="text"
                                    name="socialTwitter"
                                    className="form-control"
                                    defaultValue={data.socialTwitter}
                                />
                            </InputWrapper>
                            <InputWrapper col="12" label="Web">
                                <input
                                    {...register("socialWeb")}
                                    type="text"
                                    name="socialWeb"
                                    className="form-control"
                                    defaultValue={data.socialWeb}
                                />
                            </InputWrapper>
                            <div className="col-12">
                                <p className="mb-0 mt-3 font-bold">
                                    Profile Brief:
                                </p>
                                <textarea
                                    {...register("shortProfileBrief")}
                                    name="shortProfileBrief"
                                    id=""
                                    cols="30"
                                    rows="3"
                                    className="form-control"
                                    defaultValue={data?.shortProfileBrief}
                                ></textarea>
                            </div>
                            <div className="col-12">
                                <p className="mt-3 mb-0 font-bold">
                                    Short Description:
                                </p>
                                <ReactQuill
                                    value={shortDescriptionContent}
                                    onChange={handleChange}
                                    modules={EditAdvisorProfileInfo.modules}
                                    formats={EditAdvisorProfileInfo.formats}
                                    placeholder="Write something..."
                                />
                            </div>
                            <div className="col-12 mt-3">
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-primary"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

EditAdvisorProfileInfo.modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
    ],
};

EditAdvisorProfileInfo.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
];

export default EditAdvisorProfileInfo;
