import React, { useEffect, useState } from 'react';
import SidebarComponent from '../../Components/Shared/SidebarComponent';
import NotesEditor from './NotesEditor';
import AssignMeetingNotesTaker from '../ViewLeaveManagement/AssignMeetingNotesTaker';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { meetingNotesCollectionRef } from '../../firebase/fire-app';
import "./styles.scss"
import { customDateFormat } from '../../utilities';
import NotesViewerPopup from './NotesViewerPopup';
import DeletePopupConfirmation from '../../Components/DeletePopupConfirmation/DeletePopupConfirmation';
import { useAuth } from '../../Hooks/useAuth';
import { set } from 'date-fns';


const ViewMeetingMinutes = () => {
    const [allNotes, loading, error] = useCollectionData(meetingNotesCollectionRef.orderBy('meetingDate', "desc"), { idField: "id" });
    const [selectedNote, setSelectedNote] = React.useState(null);
    const [allowEdit, setAllowEdit] = useState(false);
    const {isAdmin, currentUser} = useAuth();

  

    useEffect(()=>{
        if(isAdmin || (currentUser && currentUser.email === selectedNote?.assignedToEmail)){
            setAllowEdit(true)
        }else{
            setAllowEdit(false)
        }
    },[currentUser, selectedNote])

    useEffect(() => {
        if (allNotes && allNotes.length > 0) {
            setSelectedNote(allNotes[0])
        }
    }, [allNotes])
    return (
        <>
            <SidebarComponent>
                <section className="sticky-top page-heading ">
                    <div className="container ">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className=''>
                                Meeting Minutes
                            </h3>
                            <div>
                                <AssignMeetingNotesTaker allowEdit={allowEdit} />

                            </div>
                        </div>


                    </div>
                </section>
                <section className="mt-4">
                    <div className="container">

                        <div className="row mt-4">
                            <div className="col-md-8 mb-4 mb-md-0">
                                <div className="light-container">
                                    {selectedNote && <NotesEditor allowEdit={allowEdit} data={selectedNote} />}
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="light-container">
                                    <div className="row">
                                        {allNotes && allNotes.length > 0 && allNotes.map((item, index) => <MeetingCard data={item} setSelectedNote={setSelectedNote} />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewMeetingMinutes;

const MeetingCard = ({ data, setSelectedNote }) => {
    const [allowEdit, setAllowEdit] = useState(false);
    const {isAdmin, currentUser} = useAuth();

    const onDelete = () => {
        meetingNotesCollectionRef.doc(data.id).delete()
            .then(() => {
                return true
            })
    }

    useEffect(()=>{
        if(isAdmin || (currentUser && currentUser.empID === data?.assignedToEmail)){
            setAllowEdit(true)
        }else{
            setAllowEdit(false)
        }
    },[currentUser])

    return (
        <div className="col-md-12 mb-3">
            <div className="card p-3">
                <h5>{data.title}</h5>
                <div className="font-14 mb-2">
                    <p className='mb-0'>Assigned to: {data.assignedTo}</p>
                    <p className='mb-0'>Meeting Date: {customDateFormat(data.meetingDate)}</p>
                    <p className='mb-0'>Last updated: {customDateFormat(data.lastUpdated)} {data.lastUpdatedBy && <span>(by: {data.lastUpdatedBy})</span>}</p>
                </div>
                <div className='d-flex'>
                    {allowEdit &&<button onClick={() => setSelectedNote(data)} className="btn-primary btn btn-sm me-3">Edit</button>}
                    <NotesViewerPopup data={data} className="me-3" />
                    {isAdmin && <DeletePopupConfirmation onDeleteTriggered={onDelete} name={data.title + " " + customDateFormat(data.meetingDate)} type="Note" data={data} />}
                </div>
            </div>
        </div>
    )
}