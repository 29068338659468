import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useAuth } from "../../Hooks/useAuth";
import { recruitmentCollectionRef } from "../../firebase/fire-app";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import AssGuideCognitive from "./AssGuideCognitive";
import AssGuideBCI from "./AssGuideBCI";
import { candidateReviewSubmitted } from "../ViewClaimManagement/messageGenerator";
import { webServerLive } from "../../firebase/liveServerStatus";
import emailjs from "@emailjs/browser";

const PopupAssessorReview = ({ data, assNo }) => {
    const [show, setShow] = useState(false);
    const { myelinners, currentUser, isAdmin } = useAuth();
    const [scoreValue, setScoreValue] = useState(0);

    const scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [candidateSelectionStatus, setCandidateSelectionStatus] =
        useState(null);
const [prjStrenghths, setPrjStrengths] = useState(null)
const [prjWeakness, setPrjWeakness] = useState(null)
    const { register, handleSubmit, watch, errors } = useForm();

    useEffect(()=>{
        if(data && assNo==="One"){
            setCandidateSelectionStatus(data.assOneReview)
            setPrjStrengths(data.oneSubmissionStrengths)
            setPrjWeakness(data.oneSubmissionWeaknesses)
            setScoreValue(data.oneScore)

        }else if (data && assNo==="Two"){
            setCandidateSelectionStatus(data.assTwoReview)
            setPrjStrengths(data.twoSubmissionStrengths)
            setPrjWeakness(data.twoSubmissionWeaknesses)
            setScoreValue(data.twoScore)

        }
    },[])
    const onFormSubmit = (formData, e) => {
        formData.lastUpdated = new Date();
        if (assNo === "One") {
            const mailData = {
    
                mailOne: data.assessorOne,
                mailTwo: "zied.tayeb@myelinh.com",
                positionTitle: data.positionTitle,
                score: scoreValue,
                strengths: formData.submissionStrengths,
                weakness: formData.submissionWeaknesses,
                review: candidateSelectionStatus

            };
            const mailDetails = candidateReviewSubmitted(mailData);
            if (webServerLive) {
                emailjs.send(
                    "service_zinxo6v",
                    "template_eqvm7mf",
                    mailDetails,
                    "ygk5Yo8QhiJUWbVZM"
                );
     
            }
            recruitmentCollectionRef
                .doc(data.id)
                .update({
                    assOneReview: candidateSelectionStatus,
                    oneSubmissionWeaknesses: formData.submissionWeaknesses,
                    oneSubmissionStrengths: formData.submissionStrengths,
                    oneScore: scoreValue,
                    lastUpdated: new Date(),
                })
                .then(() => {
                    e.target.reset();
                    setCandidateSelectionStatus("");
                    setShow(false);
                });
        } else if (assNo === "Two") {
            const mailData = {
    
                mailOne: data.assessorTwo,
                mailTwo: "zied.tayeb@myelinh.com",
                positionTitle: data.positionTitle,
                score: scoreValue,
                strengths: formData.submissionStrengths,
                weakness: formData.submissionWeaknesses,
                review: candidateSelectionStatus

            };
            const mailDetails = candidateReviewSubmitted(mailData);
            if (webServerLive) {
                emailjs.send(
                    "service_zinxo6v",
                    "template_eqvm7mf",
                    mailDetails,
                    "ygk5Yo8QhiJUWbVZM"
                );
     
            }
            recruitmentCollectionRef
                .doc(data.id)
                .update({
                    assTwoReview: candidateSelectionStatus,
                    twoSubmissionWeaknesses: formData.submissionWeaknesses,
                    twoSubmissionStrengths: formData.submissionStrengths,
                    twoScore: scoreValue,
                    lastUpdated: new Date(),
                })
                .then(() => {
                    e.target.reset();
                    setCandidateSelectionStatus("");
                    setShow(false);
                });
        } else {
            // alert(JSON.stringify(data));
        }
    };
    return (
        <>
            <Button variant="danger" size="sm" onClick={() => setShow(true)}>
                Review
            </Button>

            <Modal
                centered
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Review - {data.name}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="table-responsive">
                            <table className="table table-bordered border-primary table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Position</th>
                                        <th>CV</th>
                                        <th>GitHub</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="font-bolder">
                                        <td>{data.name}</td>
                                        <td>{data.positionTitle}</td>
                                        <td>
                                            <a
                                                href={data.candidateCV}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-primary btn-sm"
                                            >
                                                view
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                href={data.gitHubProject}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-primary btn-sm"
                                            >
                                                view
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="mb-3">
                           <b>Admin initial remarks: </b> {data.adminInitialRemarks}
                        </p>
                        <div className="py-3 text-center d-flex justify-content-center align-items-center">
                            <h5 className="font-bolder mb-0">Score: </h5>
                            <ButtonGroup
                                size="sm"
                                className=""
                                aria-label="Basic example"
                            >
                                {scores.map((item) => (
                                    <Button
                                        onClick={() => setScoreValue(item)}
                                        className="px-4 btn-lg"
                                        variant={`${
                                            scoreValue === item
                                                ? "primary"
                                                : "white"
                                        }`}
                                        size="lg"
                                    >
                                        {item}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </div>
                        <div className="py-3 text-center">
                            <ButtonGroup
                                size="sm"
                                className=""
                                aria-label="Basic example"
                            >
                                <Button
                                    onClick={() =>
                                        setCandidateSelectionStatus("Pass")
                                    }
                                    className="px-4 btn-lg"
                                    variant={`${
                                        candidateSelectionStatus === "Pass"
                                            ? "primary"
                                            : "light"
                                    }`}
                                    size="lg"
                                >
                                    Pass
                                </Button>
                                <Button
                                    className="px-4 btn-lg"
                                    onClick={() =>
                                        setCandidateSelectionStatus("Fail")
                                    }
                                    variant={`${
                                        candidateSelectionStatus === "Fail"
                                            ? "danger"
                                            : "light"
                                    }`}
                                    size="lg"
                                >
                                    Fail
                                </Button>
                                <Button
                                    className="px-4 btn-lg"
                                    onClick={() =>
                                        setCandidateSelectionStatus("Undecided")
                                    }
                                    variant={`${
                                        candidateSelectionStatus === "Undecided"
                                            ? "warning"
                                            : "light"
                                    }`}
                                    size="lg"
                                >
                                    Undecided
                                </Button>
                            </ButtonGroup>
                        </div>
                        <form
                            onSubmit={handleSubmit(onFormSubmit)}
                            className="row"
                        >
                            <div className="col-md-6">
                                <p className="font-16 mb-1 font-bolder">
                                    Submission strengths:
                                </p>
                                <textarea
                                    name="submissionStrengths"
                                    {...register("submissionStrengths")}
                                    defaultValue={prjStrenghths && prjStrenghths}
                                    className="form-control"
                                    rows="3"
                                ></textarea>
                            </div>
                            <div className="col-md-6">
                                <p className="font-16 mb-1 font-bolder">
                                    Submission weaknesses:
                                </p>
                                <textarea
                                    name="submissionWeaknesses"
                                    {...register("submissionWeaknesses")}
                                    className="form-control"
                                    defaultValue={prjWeakness}
                                    rows="3"
                                ></textarea>
                            </div>

                            <div className="col-12 mb-5 mt-4 text-center">
                                {!data.adminChecked && candidateSelectionStatus ? (
                                    <button className="btn btn-primary">
                                        Submit Review
                                    </button>
                                ) : (
                                    <button
                                        disabled
                                        className="btn btn-primary"
                                    >
                                        Submit Review
                                    </button>
                                )}
                                {
                                    data.adminChecked && <p className="font-14 mb-0"><i>* Admin has finalized review. You cannot edit and submit now.</i></p>
                                }
                            </div>
                        </form>
                    </div>
                    {data.positionTitle === "Cognitive Game Developer" ? (
                        <AssGuideCognitive />
                    ) : (
                        <AssGuideBCI />
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAssessorReview;
