import React from "react";

const AssGuideBCI = () => {
    return (
        <>
            <div className="card bg-primary p-3 text-white">
                <h6 className="font-bold">Assessment Guideline</h6>
                <ul>
                    <li>
                        {" "}
                        Final Results (Accuracy, deliverables, etc.) 30% (3
                        points){" "}
                    </li>

                    <li>
                        Methods & Techniques (signal processing, approach, etc.)
                        30% (3 points){" "}
                    </li>

                    <li>
                        Extra Mile (if any: innovation, extra tasks, etc.) 10%
                        (1 point){" "}
                    </li>

                    <li>
                        Code Readability (easy to debug, clear, clean, modular,
                        efficient data handling, documentation) 30% (3 points){" "}
                    </li>
                </ul>
            </div>
        </>
    );
};

export default AssGuideBCI;
