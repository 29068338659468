import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import {
    compensationCollectionRef,
    employmentInfoCollectionRef,
    myelinersCollectionRef,
} from "../../firebase/fire-app";
import { Link } from "react-router-dom";
import PopupUploadDocuments from "./PopupUploadDocuments";
import { set } from "date-fns";
import PopupBankAccount from "./PopupBankAccount";
import PopupAddPayslip from "../AdminViews/PopupAddPayslip";
import EmployeeDeletePopup from "./EmployeeDeletePopup";
import { Spinner } from "react-bootstrap";

const AdminEditEmployee = () => {
    const [newDocument, setNewDocument] = useState(null);
    const [empLM, setEmpLM] = useState(null);
    const { register, handleSubmit, watch, errors } = useForm();
    const [empID, setEmpID] = useState(null);
    const [myeliners, loading, error] = useCollectionData(
        myelinersCollectionRef.orderBy("empID", "desc"),
        { idField: "id" }
    );
    const [reload, setReload] = useState(null);
    const [compensationCollection, compensationLoading, compensationError] =
        useCollectionData(compensationCollectionRef, { idField: "id" });
    const [employmentCollection, employmentLoading, employmentError] =
        useCollectionData(employmentInfoCollectionRef, { idField: "id" });

    const [selectedEmp, setSelectedEmp] = React.useState(null);
    const [selectedEmpID, setSelectedEmpID] = React.useState("-");
    const [selectedEmpEmail, setSelectedEmpEmail] = React.useState(null);

    const [empCompensationInfo, setEmpCompensationInfo] = React.useState(null);
    const [empEmploymentInfo, setEmpEmploymentInfo] = React.useState(null);

    const [employeeDetails, empLoading, empError] = useDocumentData(
        myelinersCollectionRef.doc(selectedEmpID)
    );
    useEffect(() => {
        if (employeeDetails) {
            setEmpID(employeeDetails.empID);
            setEmpLM(employeeDetails.lm);
            
        }
    }, [employeeDetails]);
    const onSubmit = (data, e) => {
        const dob = new Date(data.dob);
        data.dob = dob;
        myelinersCollectionRef
            .doc(data.empID)
            .get()
            .then((docSnapshot) => {
                if (docSnapshot.exists) {
                    alert("Employee ID already exists");
                } else {
                    myelinersCollectionRef
                        .doc(data.empID)
                        .set(data)
                        .then(() => {
                            alert("Employee Added");
                            e.target.reset();
                        });
                }
            });
    };

    const onLMUpdate =()=>{
        if(empLM){
            myelinersCollectionRef.doc(employeeDetails.email).update({
                lm: empLM
            }).then(()=>{
                alert('Line Manager Updated')
            })
        }
    }

    return (
        <>
            <SidebarComponent>
                <section className="container">
                    <h3 className="mt-3">Edit Employee - {selectedEmpID}</h3>
                </section>
                <hr />
                <section className="">
                    <div className="container ps-5">
                        <div className="row ">
                            <div className="col-md-7">
                                {/* <h4 className='mt-4 mb-4'>Claim Something</h4> */}
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="row"
                                >
                                    <InputWrapper col="12" label="Employee ID">
                                        <select
                                            onChange={(e) =>
                                                setSelectedEmpID(e.target.value)
                                            }
                                            id="disabledSelect"
                                            className="form-select"
                                        >
                                            <option>Select Employee</option>
                                            {myeliners &&
                                                myeliners.length > 0 &&
                                                myeliners.map((item) => (
                                                    <option value={item.id}>
                                                        {item.id}
                                                    </option>
                                                ))}
                                        </select>
                                    </InputWrapper>
                                </form>
                            </div>
                            <div className="col-md-5 text-right mt-1">
                                {employeeDetails && (
                                    <EmployeeDeletePopup
                                        employeeDetails={employeeDetails}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-6 mb-4">
                                <div className="card p-4">
                                    <div className="mb-3 d-flex justify-content-between">
                                        <h5>Employee Documents</h5>
                                    </div>
                                    <div>
                                        {employeeDetails?.empContract && (
                                            <a
                                                href={
                                                    employeeDetails.empContract
                                                }
                                                className="btn btn-primary btn-sm me-2 mb-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Employment Contract
                                            </a>
                                        )}
                                        {employeeDetails?.empNationalID && (
                                            <a
                                                href={
                                                    employeeDetails.empNationalID
                                                }
                                                className="btn btn-primary btn-sm me-2 mb-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                National ID
                                            </a>
                                        )}
                                        {employeeDetails?.empPassport && (
                                            <a
                                                href={
                                                    employeeDetails.empPassport
                                                }
                                                className="btn btn-primary btn-sm me-2 mb-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Passport
                                            </a>
                                        )}
                                        {employeeDetails?.empJobPerks && (
                                            <a
                                                href={
                                                    employeeDetails.empJobPerks
                                                }
                                                className="btn btn-primary btn-sm me-2 mb-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Job Perks
                                            </a>
                                        )}
                                        {employeeDetails?.empCV && (
                                            <a
                                                href={employeeDetails.empCV}
                                                className="btn btn-primary btn-sm me-2 mb-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                CV
                                            </a>
                                        )}
                                        {employeeDetails?.msclOne && (
                                            <a
                                                href={employeeDetails.msclOne}
                                                className="btn btn-primary btn-sm me-2 mb-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Miscellaneous Doc One
                                            </a>
                                        )}
                                        {employeeDetails?.msclTwo && (
                                            <a
                                                href={employeeDetails.msclTwo}
                                                className="btn btn-primary btn-sm me-2 mb-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Miscellaneous Doc Two
                                            </a>
                                        )}
                                        {employeeDetails?.msclThree && (
                                            <a
                                                href={employeeDetails.msclThree}
                                                className="btn btn-primary btn-sm me-2 mb-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Miscellaneous Doc Three
                                            </a>
                                        )}
                                        {/* {
                                            employeeDetails && <a href={employeeDetails.url} className='btn btn-primary btn-sm me-2 mb-2' target="_blank" rel="noopener noreferrer">{newDocument.type}</a>
                                        } */}
                                    </div>
                                    <div>
                                        {employeeDetails && empID && (
                                            <PopupUploadDocuments
                                                empID={empID}
                                                email={selectedEmpID}
                                                data={employeeDetails}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-4">
                                    <div className="mb-3 d-flex justify-content-between">
                                        <h5>Pay Slips</h5>
                                    </div>
                                    {employeeDetails && (
                                        <PopupAddPayslip
                                            empID={employeeDetails.empID}
                                            employeeDetails={employeeDetails}
                                            email={employeeDetails.email}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-4">
                                    <div className="mb-3 d-flex justify-content-between">
                                        <h5>Bank Account Info</h5>
                                    </div>

                                    {employeeDetails && (
                                        <PopupBankAccount
                                            empDetails={employeeDetails}
                                            admin={true}
                                            email={selectedEmpID}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-4">
                                    <div className="mb-3 d-flex justify-content-between">
                                        <h5>Edit Line manager</h5>
                                    </div>
                                  {employeeDetails?  <div className="row">
                                        <InputWrapper
                                            col="12"
                                            label="Line Manager"
                                           
                                        >
                                            <input  defaultValue={empLM} name="lmField"
                                            className="form-control"
                                            onChange={(e)=> setEmpLM(e.target.value)} type="text" />
                                        </InputWrapper>
                                        <div className="col-12">
                                            <button onClick={onLMUpdate} className="btn btn-sm btn-primary">Update Line Manager</button>
                                        </div>
                                    </div> : <Spinner animation="border" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default AdminEditEmployee;

const EmpCard = ({ data }) => {
    return (
        <div className="card mb-3 p-4 d-inline-block">
            <h5 className="card-title">{data.id}</h5>
            <p className="text-secondary mb-0">{data.name}</p>
            <p className="text-secondary mb-1">{data.email}</p>
            <button className="btn btn-sm btn-primary inline-btn me-3">
                View Profile
            </button>
            <button className="btn btn-sm btn-danger inline-btn">
                Edit Details
            </button>
        </div>
    );
};
