import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useForm } from "react-hook-form";
import { claimsCollectionRef, fireStorage } from "../../firebase/fire-app";
import { set } from "date-fns";
import { MandatoryAsterisk, StatusBadge } from "./FormElements";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { customDateFormat } from "../../utilities";
import ClaimViewerPopup from "./ClaimViewerPopup";
import { useAuth } from "../../Hooks/useAuth";
import emailjs from "@emailjs/browser";
import { claimMessageGenerator } from "./messageGenerator";
import { webServerLive } from "../../firebase/liveServerStatus";

const ViewClaimManagement = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const { currentUser } = useAuth();
    const [myClaims, loading, error] = useCollectionData(
        claimsCollectionRef.where("empID", "==", currentUser.empID),
        { idField: "id" }
    );
    const [newClaimID, setNewClaimID] = React.useState(
        claimsCollectionRef.doc().id
    );
    const [invoiceLink, setInvoiceLink] = useState(null);
    const onClaimSubmit = (data, e) => {
        if (data.amount && data.expensePurpose && data.remarks) {
            data.expenseDate = new Date(data.expenseDate);
            data.claimDate = new Date();
            data.empID = currentUser?.empID;
            data.status = "Pending";
            data.email = currentUser?.email;
            data.supportingDoc = invoiceLink && invoiceLink;
            data.amount = Number(data.amount);
            data.name = currentUser?.name;

            const mailData = {
                name: currentUser?.name,
                empID: currentUser?.empID,
                title: data.title,
                amount: data.amount,
                currency: data.currency,
                purpose: data.expensePurpose,
                status: "Pending",
                from_name: "Myelin-H HR",
                // mailOne: "shahjahan.swajan@myelinh.com",
                mailOne: "zied.tayeb@myelinh.com",
                mailThree: "samaher.garbaya@myelinh.com",
                mailFour: "info@myelinh.com",
                mailTwo: currentUser?.email,
            };
            const mailDetails = claimMessageGenerator(
                mailData,
                "submit",
                "Zied"
            );
            claimsCollectionRef
                .doc(newClaimID)
                .set(data)
                .then(() => {
                    e.target.reset();
                    alert("Claim submitted successfully");
                    if (webServerLive) {
                        emailjs.send(
                            "service_zinxo6v",
                            "template_eqvm7mf",
                            mailDetails,
                            "ygk5Yo8QhiJUWbVZM"
                        );
                    }

                    setNewClaimID(claimsCollectionRef.doc().id);
                });
        } else {
            alert("Please fill all the mandatory fields");
        }
    };

    const alertClaim = () => {
        alert(JSON.stringify(myClaims));
    };
    const onFileUpload = (e) => {
        let uploadedFile = e.target.files[0];
        let fileName = uploadedFile.name;
        let fileType = uploadedFile.type;
        // alert(fileType);
        const fileRef = fireStorage
            .ref()
            .child("claims")
            .child(newClaimID + "." + fileType.split("/")[1]);

        fileRef.put(uploadedFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
                console.log(url);
                setInvoiceLink(url);
            });
        });
    };
    return (
        <>
            {currentUser && (
                <SidebarComponent>
                    <section className="page-heading sticky-top">
                        <div className="container">
                            <h3 className="">Submit New Claim </h3>
                        </div>
                    </section>
                    <section className="">
                        <div className="container ps-3 ps-md-5">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <h4 className='mt-4 mb-4'>Submit New Claim</h4> */}

                                    <form
                                        onSubmit={handleSubmit(onClaimSubmit)}
                                        className="row"
                                    >
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="exampleInputEmail1"
                                                    className="form-label font-bold"
                                                >
                                                    Invoice No.{" "}
                                                    <MandatoryAsterisk />
                                                </label>
                                                <input
                                                    {...register("invoiceNo")}
                                                    type="text"
                                                    name="invoiceNo"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="expenseDate"
                                                    className="form-label font-bold"
                                                >
                                                    Expense Date{" "}
                                                    <MandatoryAsterisk />
                                                </label>
                                                <input
                                                    {...register("expenseDate")}
                                                    type="datetime-local"
                                                    className="form-control"
                                                    id="expenseDate"
                                                    name="expenseDate"
                                                    aria-describedby="emailHelp"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="title"
                                                    className="form-label font-bold"
                                                >
                                                    Claim Title{" "}
                                                    <MandatoryAsterisk />
                                                </label>
                                                <input
                                                    {...register("title")}
                                                    name="title"
                                                    type="text"
                                                    className="form-control"
                                                    id="title"
                                                    aria-describedby="emailHelp"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="expensePurpose"
                                                    className="form-label font-bold"
                                                >
                                                    Expense Purpose{" "}
                                                    <MandatoryAsterisk />
                                                </label>
                                                <select
                                                    {...register(
                                                        "expensePurpose"
                                                    )}
                                                    name="expensePurpose"
                                                    id="expensePurpose"
                                                    className="form-select"
                                                >
                                                    <option>
                                                        Select Expense Purpose
                                                    </option>
                                                    <option value="Office Expense">
                                                        Office Expense
                                                    </option>
                                                    <option value="Business Trip">
                                                        Business Trip
                                                    </option>
                                                    <option value="Personal">
                                                        Personal Expense
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="currency"
                                                    className="form-label font-bold"
                                                >
                                                    Currency{" "}
                                                    <MandatoryAsterisk />
                                                </label>
                                                <select
                                                    {...register("currency")}
                                                    name="currency"
                                                    id="currency"
                                                    className="form-select"
                                                >
                                                    <option value="€">
                                                        € (Euro)
                                                    </option>
                                                    <option value="$">
                                                        $ (USD)
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="amount"
                                                    className="form-label font-bold"
                                                >
                                                    Amount -
                                                    <MandatoryAsterisk />
                                                </label>
                                                <input
                                                    {...register("amount")}
                                                    type="number"
                                                    className="form-control"
                                                    id="amount"
                                                    step=".01"
                                                    name="amount"
                                                    aria-describedby="emailHelp"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="supportingDoc"
                                                    className="form-label font-bold"
                                                >
                                                    Supporting Doc{" "}
                                                    <MandatoryAsterisk />
                                                </label>
                                                <input
                                                    onChange={(e) =>
                                                        onFileUpload(e)
                                                    }
                                                    placeholder="upload invoice"
                                                    type="file"
                                                    className="form-control"
                                                    id="supportingDoc"
                                                    aria-describedby="emailHelp"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="remarks"
                                                    className="form-label font-bold"
                                                >
                                                    Remarks{" "}
                                                    <MandatoryAsterisk />
                                                </label>
                                                <textarea
                                                    name="remarks"
                                                    {...register("remarks")}
                                                    className="form-control"
                                                    id="remarks"
                                                    cols="30"
                                                    rows="2"
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            {invoiceLink ? (
                                                <button className="btn btn-primary">
                                                    SUBMIT NEW CLAIM
                                                </button>
                                            ) : (
                                                <button
                                                    disabled
                                                    className="btn btn-primary"
                                                >
                                                    SUBMIT NEW CLAIM
                                                </button>
                                            )}{" "}
                                        </div>
                                    </form>
                                </div>

                                <div className="col-md-12 ">
                                    <h4 className="mt-4 mb-4">My Claims</h4>
                                    <div className="table-responsive">
                                        {myClaims && myClaims.length > 0 && (
                                            <table className="table pr-5 table-bordered border-primary table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Sl.</th>
                                                        <th>Invoice No.</th>
                                                        <th>Title</th>
                                                        <th>Expense Date</th>
                                                        <th>Claim Date</th>
                                                        <th>Amount</th>
                                                        <th>View Invoice</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {myClaims &&
                                                        myClaims.map(
                                                            (claim, index) => (
                                                                <ClaimRow
                                                                    claim={
                                                                        claim
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </SidebarComponent>
            )}
        </>
    );
};

export default ViewClaimManagement;

const ClaimRow = ({ claim, index }) => {
    // const onDeleteClaim = ()=>{
    //     claimsCollectionRef.doc(claim.id).delete();
    // }
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{claim.invoiceNo}</td>
            <td>{claim.title}</td>
            <td>{customDateFormat(claim.expenseDate)}</td>
            <td>{customDateFormat(claim.claimDate)}</td>
            <td>
                {claim.currency} {claim.amount}
            </td>
            <td>
                <ClaimViewerPopup claim={claim} />{" "}
            </td>
            <td>
                <StatusBadge status={claim.status} />
                {/* <button onClick={onDeleteClaim} className="btn btn-primary">Delete</button> */}
            </td>
        </tr>
    );
};
