import React, { useEffect, useState } from "react";
import { timeStampToDateViaSecond } from "../../utilities/convertTime";
import { useAuth } from "../../Hooks/useAuth";
import PopupTaskUpdate from "../AdminAssignTask/PopupTaskUpdate";
import PopupAdminEditTask from "../AdminAssignTask/PopupAdminEditTask";
import { portalFeedbacksCollectionRef } from "../../firebase/fire-app";

const CompPortalFeedback = ({ data }) => {
    const { currentUser, isAdmin } = useAuth();
    const [sjnView, setSjnView] = useState(null)
    useEffect(()=>{
        if(currentUser){
            if(currentUser.email === "shahjahan.swajan@myelinh.com"){
                setSjnView(true)
            }
        }
    },[currentUser])
    const [cardMode, setCardMode] = useState("light");
    const [detailsChange, setDetailsChange] = useState(data?.details);
    const [sjnNote, setSjnNote] = useState(data?.sjnNote);

    useEffect(() => {
        if (data) {
            if (data.status === "New task") {
                setCardMode("danger");
            } else if (data.status === "In progress") {
                setCardMode("warning");
            } else if (data.status === "Completed") {
                setCardMode("primary");
            }
        }
    }, [data]);

    const onChangesSave = ()=>{
        data.sjnNote = sjnNote
        data.details = detailsChange
        // alert(JSON.stringify(data))
        portalFeedbacksCollectionRef.doc(data.id).update({
            details: detailsChange,
            sjnNote: sjnNote
        })
    }
    return (
        <>
            <div className="col-md-4 p-3">
                <div
                    className={`card bg-white card-${cardMode} task-card  px-3 py-3 pb-1`}
                >
                    <h5 className="font-bold mb-1">{data.title}</h5>
                    <p className="mb-0">
                        <span className="badge badge-light bg-primary">
                        Priority: {data.priority}
                        </span>
                    </p>
                    <div className="mt-2">
                        <p className="mb-0">
                            <u>Details:</u> <br />

                            {!sjnView ? (
                                <textarea
                                    onBlur={(e) => setDetailsChange(e.target.value)}
                                    name="details"
                                    defaultValue={data.details}
                                    rows="4"
                                    className="form-control"
                                ></textarea>
                            ) : (
                                <i>{data.details}</i>
                            )}                        </p>
                        <hr className="my-2" />
                        <p className="">
                            <u>Notes from Swajan:</u> <br />
                            {sjnView ? (
                                <textarea
                                    onBlur={(e) => setSjnNote(e.target.value)}
                                    name="sjnNote"
                                    defaultValue={data.sjnNote}
                                    rows="4"
                                    className="form-control"
                                ></textarea>
                            ) : (
                                data.sjnNote
                            )}
                        </p>
<div className="text-right">
<button onClick={onChangesSave} className="btn btn-sm btn-outline-primary">SAVE</button>

</div>
                    </div>

                    {/* <div className="text-right">
                        {isAdmin ? (
                            <PopupAdminEditTask
                                data={data}
                                cardMode={cardMode}
                            />
                        ) : (
                            <PopupTaskUpdate data={data} cardMode={cardMode} />
                        )}
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default CompPortalFeedback;
