import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { archivedUsersCollectionRef, authUsersCollection, myelinersCollectionRef } from '../../firebase/fire-app';
import { useAuth } from '../../Hooks/useAuth';
import { removeFromArray } from '../../utilities';

const EmployeeDeletePopup = ({ employeeDetails }) => {
    const [show, setShow] = useState(false);
    const { allAuthUsers } = useAuth()

    const onDeleteProfile = () => {
        const userRemovedList = removeFromArray(allAuthUsers, employeeDetails.email)
        alert(JSON.stringify(userRemovedList))

        archivedUsersCollectionRef.doc(employeeDetails.email).set({ ...employeeDetails, type: "delete", archived: new Date() }).then(() => {
            const userRemovedList = removeFromArray(allAuthUsers, employeeDetails.email)
            authUsersCollection.doc('authUsers').update({ users: userRemovedList }).then(() => {
                alert("User's access has been removed successfully!")
            }
            )
            myelinersCollectionRef.doc(employeeDetails.email).delete().then(() => {
                alert("User deleted successfully!")

            })
        })


    }
    const onArchiveProfile = () => {
        archivedUsersCollectionRef.doc(employeeDetails.email).set({ ...employeeDetails, type: "archive", archived: new Date() }).then(() => {
            const userRemovedList = removeFromArray(allAuthUsers, employeeDetails.email)
            authUsersCollection.doc('authUsers').update({ users: userRemovedList }).then(() => {
                alert("User's access has been removed successfully!")
            }
            )
            myelinersCollectionRef.doc(employeeDetails.email).delete().then(() => {
                alert("User Info has been archived.")

            })
        })
    }

    return (
        <>
            <button onClick={() => setShow(!show)} className="btn-sm btn btn-outline-danger">Delete</button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName=""

                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className='py-1 ' closeButton>
                    <p className='font-bold mb-0 py-3'>
                        Delete Employee Profile - {employeeDetails.name}({employeeDetails.empID})
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <p className="font-bold text-center text-danger">
                        <i>
                            Are you sure you want to delete the profile of {employeeDetails.name}? <br />
                            This action cannot be undone.
                        </i>
                    </p>
                    <div className="text-center">
                        <button onClick={onDeleteProfile} className="btn btn-danger btn-sm">Delete - {employeeDetails.name}</button>
                    </div>
                    <p className="font-14 text-center mt-5">
                        <i>
                            You can also archive the profile of {employeeDetails.name}. <br />
                            If you archive the profile, the employee will not be able to login to the system. But, you can access the information. <br />
                        </i>
                    </p>
                    <div className="text-center">
                        <button onClick={onArchiveProfile} className="btn btn-outline-primary btn-sm">Archive - {employeeDetails.name}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EmployeeDeletePopup;