import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MandatoryAsterisk } from '../ViewClaimManagement/FormElements';
import { leavesCollectionRef } from '../../firebase/fire-app';


const EditLeaveRemarksPopup = ({ claim }) => {
    const [show, setShow] = useState(false);
    const [remarks, setRemarks] = useState(claim?.leaveRemarks);
    const onRemarksUpdate = () => {
        if (remarks) {
            leavesCollectionRef.doc(claim.id).update({ leaveRemarks: remarks }).then(() => {
                setShow(false);
                alert('Remarks Updated');
            })
        }
    }
    return (
        <>
            <Button variant="danger" size='sm' onClick={() => setShow(true)}>
                Edit Remarks
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Edit Remarks - {claim.title}
                    </h5>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">
                        <div className="col-md-7">
                            <div className="mb-3">
                                <label htmlFor="feedback" className="form-label font-bold">Remarks <MandatoryAsterisk /></label>
                                <textarea cols={30} rows={4} onBlur={(e) => setRemarks(e.target.value)} defaultValue={remarks} type="text" name='feedback' className="form-control" id="feedback" aria-describedby="emailHelp" >
                                </textarea>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <button onClick={onRemarksUpdate} className="btn btn-primary btn-sm">Update</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditLeaveRemarksPopup;