import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays, differenceInCalendarDays, set } from "date-fns";
import { DateRangePicker } from "react-date-range";
import Form from "react-bootstrap/Form";

import "./leave.scss";
import { useForm } from "react-hook-form";
import { leavesCollectionRef } from "../../firebase/fire-app";
import { Button } from "react-bootstrap";
import { useAuth } from "../../Hooks/useAuth";
import emailjs from "@emailjs/browser";
import { customDateFormat, screenSizePickerDateRange } from "../../utilities";
import { MandatoryAsterisk } from "../ViewClaimManagement/FormElements";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { webServerLive } from "../../firebase/liveServerStatus";

const DateSelectionPopup = () => {
    const [show, setShow] = useState(false);

    const { currentUser } = useAuth();

    const [halfDayLeave, setHalfDayLeave] = useState(false);

    const { register, handleSubmit, watch, errors } = useForm();
    const [leaveType, setLeaveType] = useState("Vacation");
    const [leaveRemarks, setLeaveRemarks] = useState(null);
    const [vacationCount, setVacationCount] = useState(null);

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: "selection",
        },
    ]);
    const pickerDirection = screenSizePickerDateRange();

    useEffect(() => {
        if(halfDayLeave){
            setVacationCount(0.5)
        }else{
            setVacationCount(
                getVacationDays(dateRange[0].startDate, dateRange[0].endDate)
            )
        }
    }, [dateRange]);

    function getVacationDays(startDate, endDate) {
        const officialWeekendDays = [0 /* Sunday */, 6 /* Saturday */];
        let currentDay = startDate;
        let vacationDays = 0;

        while (currentDay <= endDate) {
            // Check if the day is not an official weekend day
            if (!officialWeekendDays.includes(currentDay.getDay())) {
                vacationDays++;
            }
            currentDay = addDays(currentDay, 1);
        }

        return vacationDays;
    }

    const onLeaveSubmit = (data, e) => {
        let leaveData = {};
        leaveData.leaveType = leaveType;
        leaveData.leaveRemarks = leaveRemarks;
        leaveData.startDate = dateRange[0].startDate;
        leaveData.endDate = dateRange[0].endDate;

        leaveData.leaveDays = vacationCount;
        leaveData.empID = currentUser?.empID;
        leaveData.status = "Pending";
        leaveData.empEmail = currentUser?.email;
        leaveData.preLeaveBalance = currentUser?.leaveBalance;
        leaveData.preLeaveTaken = currentUser?.leaveTaken;


        leavesCollectionRef.add(leaveData).then(() => {
            alert("Leave submitted successfully");
            const mailData = {
                mailSubject:
                    "New Leave Application from " + currentUser.name + ".",
                message:
                    currentUser.name +
                    " has applied for a leave. Here goes the details:",
                empID: leaveData.empID,
                name: currentUser.name,
                type: leaveData.leaveType,
                duration:
                    leaveData.startDate.toDateString() +
                    " to " +
                    leaveData.endDate.toDateString(),

                remarks: leaveData.leaveRemarks,
                status: leaveData.status,
                mailOne: "zied.tayeb@myelinh.com",
                mailTwo: currentUser.email,
                mailThree: "samaher.garbaya@myelinh.com",
                mailFour: "info@myelinh.com",
                from_name: "Myelin-H HR",
            };
            if (webServerLive) {
                emailjs.send(
                    "service_zinxo6v",
                    "template_vhzaawx",
                    mailData,
                    "ygk5Yo8QhiJUWbVZM"
                );
            }
            setDateRange([
                {
                    startDate: new Date(),
                    endDate: addDays(new Date(), 3),
                    key: "selection",
                },
            ]);
            setShow(false);
        });
    };

    const onHalfDayDateChange = (e) => {
        const newHalfDayDate = new Date(e.target.value);
        setDateRange([
            {
                startDate: newHalfDayDate,
                endDate: newHalfDayDate,
                key: "selection",
            },
        ]);
    };
    return (
        <>
            <Button variant="primary" onClick={() => setShow(true)}>
                New Leave Application
            </Button>
            {currentUser && (
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header className="py-1 " closeButton>
                        <h5
                            className="font-weight-bold mt-1"
                            id="example-custom-modal-styling-title"
                        >
                            New Leave Application {vacationCount} days
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        {currentUser && (
                                            <p className="font-bold text-danger">
                                                Your annual leave balance:{" "}
                                                {currentUser.leaveBalance} days{" "}
                                            </p>
                                        )}

                                        <div className="mb-3">
                                            <label
                                                htmlFor="leaveType"
                                                className="form-label font-bold"
                                            >
                                                Leave Type <MandatoryAsterisk />
                                            </label>
                                            <select
                                                onChange={(e) =>
                                                    setLeaveType(e.target.value)
                                                }
                                                id="leaveType"
                                                name="leaveType"
                                                className="form-select"
                                            >
                                                <option value={"Vacation"}>
                                                    Vacation
                                                </option>
                                                <option value={"Sick"}>
                                                    Sick Leave
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Check
                                            type="radio"
                                            label={`half day leave (0.5 day)`}
                                            onChange={(e) =>
                                                setHalfDayLeave(!halfDayLeave)
                                            }
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="remarks"
                                                className="form-label font-bold"
                                            >
                                                Remarks <MandatoryAsterisk />{" "}
                                            </label>
                                            <textarea
                                                onBlur={(e) =>
                                                    setLeaveRemarks(
                                                        e.target.value
                                                    )
                                                }
                                                name="remarks"
                                                className="form-control"
                                                id="remarks"
                                                cols="30"
                                                rows="2"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12 d-none d-md-block mt-3 mb-4">
                                        <button
                                            onClick={onLeaveSubmit}
                                            className="btn btn-primary"
                                        >
                                            Apply for Leave
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {!halfDayLeave ? (
                                <div className="col-md-8">
                                    <span className="font-14">
                                        <i>Select Date Range:</i>{" "}
                                        <MandatoryAsterisk />{" "}
                                    </span>
                                    <DateRangePicker
                                        onChange={(item) =>
                                            setDateRange([item.selection])
                                        }
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={dateRange}
                                        direction={pickerDirection}
                                    />
                                </div>
                            ) : (
                                <div className="col-md-4">
                                    <span className="font-14">
                                        <i>Select Date:</i>{" "}
                                        <MandatoryAsterisk />{" "}
                                    </span>
                                    <input
                                        type="date"
                                        onChange={(e) => onHalfDayDateChange(e)}
                                        name="halfDayDate"
                                        className="form-control"
                                        id=""
                                    />
                                </div>
                            )}
                            <div className="col-12 d-block d-md-none mt-3 mb-4">
                                <button
                                    onClick={onLeaveSubmit}
                                    className="btn btn-primary"
                                >
                                    Apply for Leave
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default DateSelectionPopup;
