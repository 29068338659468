import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import { authUsersCollection } from "../../firebase/fire-app";

const EditorCultureLeadershipPrinciplePopup = ({setPageContent, isAdmin}) => {
    const [show, setShow] = useState(false);
    const [pageContent, loading, error] = useDocumentData(
        authUsersCollection.doc("webPages")
    );
    const [content, setContent] = useState("---");
    const handleChange = (value) => {
        setContent(value);
    };

    useEffect(() => {
        if (pageContent) {
            setContent(pageContent.cultureLeadershipPrinciple);
            setPageContent(pageContent.cultureLeadershipPrinciple)
        }
    }, [pageContent]);

    const onSavePage = () => {
        authUsersCollection
            .doc("webPages")
            .update({
                cultureLeadershipPrinciple: content,
            })
            .then(() => {
                setShow(false);
            });
    };
    return (
        <>
            {isAdmin && <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                Edit Page
            </Button>}

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <div className="container">
                        <div className="d-flex w-100 align-items-center justify-content-around">
                            <h5
                                className="font-weight-bold my-0"
                                id="example-custom-modal-styling-title"
                            >
                                Edit Culture & Leadership Principle Page
                            </h5>
                            <div>
                                <button
                                    onClick={onSavePage}
                                    className="btn-primary btn"
                                >
                                    SAVE PAGE
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr className="my-0" />

                  <div className="policy-details">
                  <ReactQuill
                        value={content}
                        onChange={handleChange}
                        modules={EditorCultureLeadershipPrinciplePopup.modules}
                        formats={EditorCultureLeadershipPrinciplePopup.formats}
                        placeholder="Write something..."
                    />
                  </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

EditorCultureLeadershipPrinciplePopup.modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
    ],
};

EditorCultureLeadershipPrinciplePopup.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
];

export default EditorCultureLeadershipPrinciplePopup;
