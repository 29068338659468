import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import {
    assignedTasksCollectionRef,
    leavesCollectionRef,
    myelinersCollectionRef,
} from "../../firebase/fire-app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import PopupAssignNewTask from "./PopupAssignNewTask";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import CompTaskCard from "./CompTaskCard";
import OverlayAssignTask from "./OverlayAssignTask";

const AdminAssignTask = () => {
    const [selectedEmpID, setSelectedEmpID] = React.useState("");
    const [liveStatus, setLiveStatus] = useState("active");
    const [selectedEmpDetails, setSelectedEmpDetails] = useState(null);
    const [taskStatus, setTaskStatus] = useState("New task");
    const [tasksList, setTasksList] = useState(null);
    const [myeliners, loadingmyeliner, errorMy] = useCollectionData(
        myelinersCollectionRef.orderBy("empID", "desc"),
        { idField: "id" }
    );

    const [assignedTasksList, listLoading, listError] = useCollectionData(
        assignedTasksCollectionRef
            .where("liveStatus", "==", liveStatus)
            .orderBy("createdAt", "desc"),
        { idField: "id" }
    );

    // assignedTasksCollectionRef
    //     .orderBy("createdAt", "desc")
    //     .where("liveStatus", "==", liveStatus)
    //     .get();

    // assignedTasksCollectionRef
    //         .where("assignedTo", "==", selectedEmpID)
    //         .orderBy("createdAt", "desc").get()
    useEffect(() => {
        if (selectedEmpID) {
            const emmployeeDetails = myeliners.filter(
                (item) => item.id === selectedEmpID
            )[0];
            setSelectedEmpDetails(emmployeeDetails);
        }
    }, [selectedEmpID]);
    useEffect(()=>{
if(assignedTasksList){
    console.log(assignedTasksList);

}
    },[assignedTasksList])

    useEffect(() => {
        if (assignedTasksList && assignedTasksList.length > 0) {
            const filteredList = assignedTasksList.filter(
                (item) => item.status === taskStatus
            );
            setTasksList([...assignedTasksList]);
            // alert(taskStatus)
        }
    }, [assignedTasksList, taskStatus]);
    const changeTaskStatus = (statusUpdate) => {
        setTaskStatus(null);
        // alert(taskStatus)
        setTaskStatus(statusUpdate);
    };
    useEffect(() => {
        if(assignedTasksList && assignedTasksList.length >0){
            if(selectedEmpID === "all"){
                setTasksList([...assignedTasksList])
            }else{
                const filteredList = assignedTasksList.filter(
                    (item) => item.assignees?.includes(selectedEmpID) 
                );
                setTasksList([...filteredList]);
            }
        }
    }, [selectedEmpID]);
    return (
        <>
            <SidebarComponent>
                <section className="page-heading sticky-top">
                    <div className="container d-md-flex d-block justify-content-between">
                    <div>
                        <h3 className="text-center text-md-left">Assign a task - {taskStatus} </h3>
                    </div>
                    <form className="row mt-md-0">
                        <InputWrapper col="12" label="Employee ID">
                            <select
                                onChange={(e) =>
                                    setSelectedEmpID(e.target.value)
                                }
                                id="disabledSelect"
                                className="form-select"
                            >
                                <option>Select Employee</option>
                                <option value="all">All</option>
                                {myeliners &&
                                    myeliners.length > 0 &&
                                    myeliners.map((item) => (
                                        <option value={item.id}>
                                            {item.id}
                                        </option>
                                    ))}
                            </select>
                        </InputWrapper>
                    </form>
                    </div>
                </section>
                <section className="container mt-3">
                    <div className="d-flex justify-content-between">
                        {/* <PopupAssignNewTask
                            selectedEmpID={selectedEmpID}
                            selectedEmpDetails={selectedEmpDetails}
                        /> */}
                        <OverlayAssignTask />
                        <ButtonGroup
                            size="sm"
                            className=""
                            aria-label="Basic example"
                        >
                            <Button
                                onClick={() => setLiveStatus("active")}
                                size="sm"
                                variant={`${
                                    liveStatus === "active"
                                        ? "primary"
                                        : "light"
                                }`}
                            >
                                Active
                            </Button>
                            <Button
                                onClick={() => setLiveStatus("archived")}
                                size="sm"
                                variant={`${
                                    liveStatus === "archived"
                                        ? "danger"
                                        : "light"
                                }`}
                            >
                                Archived
                            </Button>
                        </ButtonGroup>
                    </div>
                </section>
                <div className="container d-none d-md-block">
                    <div className="row">
                        <div className="col-md-4">
                            <h5 className="mb-0 font-bold py-">New Tasks</h5>
                        </div>
                        <div className="col-md-4">
                            <h5 className="mb-0 font-bold py-">In progress</h5>
                        </div>
                        <div className="col-md-4">
                            <h5 className="mb-0 font-bold py-">Completed</h5>
                        </div>
                    </div>
                </div>
                {tasksList && <section className="task-container pt-4 bg-light mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                {tasksList &&
                                    tasksList
                                        .filter(
                                            (item) =>
                                                item.status === "New task" && item.liveStatus == liveStatus
                                        )
                                        .map((item) => (
                                            <CompTaskCard
                                                data={item}
                                                key={item.id}
                                            />
                                        ))}
                            </div>
                            <div className="col-md-4">
                                {tasksList &&
                                    tasksList
                                        .filter(
                                            (item) =>
                                                item.status === "In progress" && item.liveStatus == liveStatus
                                        )
                                        .map((item) => (
                                            <CompTaskCard
                                                data={item}
                                                key={item.id}
                                                liveStatus
                                            />
                                        ))}
                            </div>
                            <div className="col-md-4">
                                {tasksList &&
                                    tasksList
                                        .filter(
                                            (item) =>
                                                item.status === "Completed" && item.liveStatus == liveStatus
                                        )
                                        .map((item) => (
                                            <CompTaskCard
                                                data={item}
                                                key={item.id}
                                                liveStatus
                                            />
                                        ))}
                            </div>
                        </div>
                    </div>
                </section>}
            </SidebarComponent>
        </>
    );
};

export default AdminAssignTask;

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div className={"col-md-" + col + " " + addClass}>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">{label} </span>
                </div>
                {children}
            </div>
        </div>
    );
};
