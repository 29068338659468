import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useAuth } from "../../Hooks/useAuth";
import { recruitmentCollectionRef } from "../../firebase/fire-app";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import SlideChangeAssessor from "./SlideChangeAssessor";
import { adminReviewConfirmationNotification } from "../ViewClaimManagement/messageGenerator";
import { webServerLive } from "../../firebase/liveServerStatus";
import emailjs from "@emailjs/browser";

const PopupAdminReview = ({ data }) => {
    const [show, setShow] = useState(false);
    const { myelinners, currentUser } = useAuth();
    const [closeReview, setCloseReview] =useState(false)
    const [candidateSelectionStatus, setCandidateSelectionStatus] =
        useState(null);

    const { register, handleSubmit, watch, errors } = useForm();
    const onFormSubmit = (formData, e) => {
        formData.lastUpdated = new Date();
        const mailData = {
            name: data.name,
            positionTitle: data.positionTitle,
            finalReview: candidateSelectionStatus,
            mailOne: `${data.assessorOne},${data.assessorTwo}`,
            mailTwo: "zied.tayeb@myelinh.com",
        }
        const mailDetails = adminReviewConfirmationNotification(mailData)
        if(webServerLive){
            emailjs.send(
                "service_zinxo6v",
                "template_eqvm7mf",
                mailDetails,
                "ygk5Yo8QhiJUWbVZM"
            );
        }
        recruitmentCollectionRef
            .doc(data.id)
            .update({
                adminChecked: closeReview,
                finalReview: candidateSelectionStatus,
                finalRemarks: formData.remarks,
                lastUpdated: new Date(),
            })
            .then(() => {
                e.target.reset();
                setCandidateSelectionStatus("");
                setShow(false);
            });
    };
    return (
        <>
            <Button variant="danger" size="sm" onClick={() => setShow(true)}>
                Review
            </Button>

            <Modal
                centered
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Review - {data.name} - {currentUser.email}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="table-responsive">
                            <table className="table table-bordered border-primary table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Position</th>
                                        <th>CV</th>
                                        <th>GitHub</th>
                                        <th>Score 1</th>

                                        <th>Rev. 1</th>
                                        <th>Score 2</th>

                                        <th>Rev. 2</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="font-bolder">
                                        <td>{data.name}</td>
                                        <td>{data.positionTitle}</td>
                                        <td>
                                            <a
                                                href={data.candidateCV}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-primary btn-sm"
                                            >
                                                view
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                href={data.gitHubProject}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-primary btn-sm"
                                            >
                                                view
                                            </a>
                                        </td>
                                        <td>{data.oneScore}</td>
                                        <td>{data.assOneReview}</td>
                                        <td>{data.twoScore}</td>
                                        <td>{data.assTwoReview}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="mb-3">
                           <b>Admin initial remarks: </b> {data.adminInitialRemarks}
                        </p>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <p className="font-bold">
                                        Submission strengths (assessor 1):{" "}
                                    </p>
                                    <p>{data.oneSubmissionStrengths}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <p className="font-bold">
                                        Submission weaknesses (assessor 1):
                                    </p>
                                    <p>{data.oneSubmissionWeaknesses}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <p className="font-bold">
                                        Submission strengths (assessor 2):{" "}
                                    </p>
                                    <p>{data.twoSubmissionStrengths}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <p className="font-bold">
                                        Submission weaknesses (assessor 2):
                                    </p>
                                    <p>{data.twoSubmissionWeaknesses}</p>
                                </div>
                            </div>
                        </div>
                        <SlideChangeAssessor data={data} />
                        <div className="py-3 text-center">
                            <ButtonGroup
                                size="sm"
                                className=""
                                aria-label="Basic example"
                            >
                                <Button
                                    onClick={() =>
                                        setCandidateSelectionStatus("Pass")
                                    }
                                    className="px-4 btn-lg"
                                    variant={`${
                                        candidateSelectionStatus === "Pass"
                                            ? "primary"
                                            : "light"
                                    }`}
                                    size="lg"
                                >
                                    Pass
                                </Button>
                                <Button
                                    className="px-4 btn-lg"
                                    onClick={() =>
                                        setCandidateSelectionStatus("Fail")
                                    }
                                    variant={`${
                                        candidateSelectionStatus === "Fail"
                                            ? "danger"
                                            : "light"
                                    }`}
                                    size="lg"
                                >
                                    Fail
                                </Button>
                                <Button
                                    className="px-4 btn-lg"
                                    onClick={() =>
                                        setCandidateSelectionStatus("Undecided")
                                    }
                                    variant={`${
                                        candidateSelectionStatus === "Undecided"
                                            ? "warning"
                                            : "light"
                                    }`}
                                    size="lg"
                                >
                                    Undecided
                                </Button>
                            </ButtonGroup>
                        </div>
                        <form
                            onSubmit={handleSubmit(onFormSubmit)}
                            className="row"
                        >
                            <div className="col-md-8">
                                <p className="font-16 mb-1 font-bolder">
                                    Remarks
                                </p>
                                <textarea
                                    name="remarks"
                                    defaultValue={data.finalRemarks}
                                    {...register("remarks")}
                                    className="form-control"
                                    rows="4"
                                ></textarea>
                            </div>
                            

                            <div className="col-12 mb-5 mt-4 text-center">
                                {candidateSelectionStatus ? (
                                    <button className="btn btn-primary">
                                        Submit Review
                                    </button>
                                ) : (
                                    <button
                                        disabled
                                        className="btn btn-primary"
                                    >
                                        Submit Review
                                    </button>
                                )}
                            </div>
                        </form>
                        <div className="text-center">
                        <ButtonGroup
                            size="sm"
                            className=""
                            aria-label="Basic example"
                        >
                            <Button
                                onClick={() => setCloseReview(true)}
                                size="sm"
                                variant={`${
                                    closeReview === true
                                        ? "primary"
                                        : "light"
                                }`}
                            >
                                Open Review for Assessor
                            </Button>
                            <Button
                                onClick={() => setCloseReview(false)}
                                size="sm"
                                variant={`${
                                    closeReview === false
                                        ? "danger"
                                        : "light"
                                }`}
                            >
                                Close Review for Assessor
                            </Button>
                        </ButtonGroup>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAdminReview;
