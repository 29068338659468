import React from 'react';
import { useAuth } from '../../Hooks/useAuth';
import SidebarComponent from '../../Components/Shared/SidebarComponent';
import { useForm } from "react-hook-form";
import { MandatoryAsterisk } from '../ViewClaimManagement/FormElements';
import { feedbackCollectionRef } from '../../firebase/fire-app';


const ViewFeedback = () => {
    const { currentUser } = useAuth();
    const { register, handleSubmit, watch, errors } = useForm();

    const onFeedbackSubmit = (data, e) => {
        if (currentUser) {
            data.createdAt = new Date()
            data.empID = currentUser.empID
            feedbackCollectionRef.add(data).then(() => {
                alert('Feedback submitted successfully!')
                e.target.reset();
            })
        }

    }
    return (
        <>
            {
                currentUser && <SidebarComponent>
                    <section className="container">
                        <h3 className="mt-3">Submit your valuable feedback</h3>
                    </section>
                    <hr />
                    <section className="container ps-5">
                        <form onSubmit={handleSubmit(onFeedbackSubmit)} className="row">
                            <div className="col-md-5">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label font-bold">Title <MandatoryAsterisk /></label>
                                    <input {...register("title")} type="text" name='title' className="form-control" id="title" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="col-md-7"></div>
                            <div className="col-md-7">
                                <div className="mb-3">
                                    <label htmlFor="feedback" className="form-label font-bold">Feedback on the portal <MandatoryAsterisk /></label>
                                    <textarea cols={30} rows={4} {...register("feedback")} type="text" name='feedback' className="form-control" id="feedback" aria-describedby="emailHelp" >
                                    </textarea>
                                </div>
                            </div>
                            <div className="col-12 text-left">
                                <button className="btn btn-sm btn-primary" type='submit' >Submit</button>
                            </div>
                        </form>
                    </section>
                </SidebarComponent>
            }
        </>
    );
};

export default ViewFeedback;