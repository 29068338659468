import React, { useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import { jobPostsCollectionRef } from "../../firebase/fire-app";

const PopupAddNewJob = () => {
    const [show, setShow] = useState(false);
    const { register, handleSubmit } = useForm();
    const [coverImage, setCoverImage] = useState();

    const onUploadFileChange =(e)=>{
        setCoverImage(URL.createObjectURL(e.target.files[0]));

    }

    const onJobDetailsSubmitted = (data, e) => {
        data.img = coverImage;
        data.isVisible = true;
        data.createdAt = new Date();
        alert(JSON.stringify(data));
        jobPostsCollectionRef.add(data).then(()=>{
            alert("Added new vacancy.")
            e.target.reset();
            setShow(false);
        })
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            console.log(reader.result);
            setCoverImage(reader.result);
        };

        reader.readAsDataURL(file);
    }, []);
    return (
        <>
            <button
                className={`btn btn-primary btn-sm`}
                onClick={() => setShow(true)}
            >
                Add new vacancy post
            </button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add new vacancy post
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(onJobDetailsSubmitted)}
                        className="row"
                    >
                        <InputWrapper col="7" label="Title">
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                {...register("title")}
                            />
                        </InputWrapper>
                        <InputWrapper col="5" label="Cover">
                            <input
                                className="form-control"
                                type="file"
                                name="img"
                                accept="image/*"
                                onChange={(e) =>
                                    onDrop(e.target.files)
                                }
                            />
                        </InputWrapper>
                        <div className="col-12 mb-3">
                            <p className="mb-2 font-14 font-bold">Details</p>
                            <textarea
                                name="details"
                                {...register("details")}
                                id=""
                                cols="30"
                                rows="3"
                                className="form-control"
                            ></textarea>
                        </div>
                        <InputWrapper col="8" label="LinkedIn URL">
                            <input
                                type="text"
                                {...register("applyLink")}
                                className="form-control"
                            />
                        </InputWrapper>
                        <div className="col-12 text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-primary"
                            >
                                Add this vacancy
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAddNewJob;
