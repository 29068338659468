import React, { useEffect, useState } from "react";
import "./admin-assign.scss"
import { timeStampToDateViaSecond } from "../../utilities/convertTime";
import { useAuth } from "../../Hooks/useAuth";
import PopupTaskUpdate from "./PopupTaskUpdate";
import PopupAdminEditTask from "./PopupAdminEditTask";
import { assignedTasksCollectionRef } from "../../firebase/fire-app";
import { FcCalendar } from "react-icons/fc";
import PopupAssignNewTask from "./PopupAssignNewTask";

const CompTaskCard = ({ data, liveStatus }) => {
    const { currentUser, isAdmin } = useAuth();
    const [cardMode, setCardMode] = useState("light");
    const [empComment, setEmpComment] = useState(data.empComment);
    useEffect(() => {
        if (data) {
            if (data.status === "New task") {
                setCardMode("danger");
            } else if (data.status === "In progress") {
                setCardMode("warning");
            } else if (data.status === "Completed") {
                setCardMode("primary");
            }
        }
    }, [data]);

    const saveComment = () => {
        assignedTasksCollectionRef.doc(data.id).update({
            empComment: empComment,
        }).then(()=>{
            console.log("done");
        })
    };

    const liveStatusActive =()=>{
        if(data.status === "New task"){
            assignedTasksCollectionRef.doc(data.id).update({
                status: "New task",
            }) 
        }else{
            assignedTasksCollectionRef.doc(data.id).update({
                status: data.status,
            })
        }
     
    }
    console.log(liveStatus);
    // console.log(data.liveStatus);
    return (
        <>
            {true && <div className="p-1">
                <div
                    className={`card bg-white card-${cardMode} task-card  px-3 py-3 pb-1`}
                >
                    <div className="d-flex justify-content-between">
                        <h5 className="font-bold mb-1">{data.title}</h5>
                        <div>
                            <span
                                className={`badge bg-${cardMode} badge-light`}
                            >
                                {data.status}
                            </span>
                        </div>
                    </div>
                    {/* temp */}
                    {/* <button onClick={liveStatusActive} className="btn btn-sm btn-warning">Live status - {data.progressStatus}</button> */}
                    <div className="mt-1 font-14 ps-3">
                        <i
                        dangerouslySetInnerHTML={{__html: data.details}}
                        >
                        </i>
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex justify-content-between">
                        <div>
                        <FcCalendar/> <span className="font-12 text-primary">{timeStampToDateViaSecond(data.endDate)}</span>

                        </div>
                        <div>
                            <PopupAssignNewTask data={data}/>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default CompTaskCard;
