import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "./leave.scss";
import { useForm } from "react-hook-form";
import { leavesCollectionRef } from "../../firebase/fire-app";
import DateSelectionPopup from "./DateSelectionPopup";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import { customDateFormat } from "../../utilities";
import { StatusBadge } from "../ViewClaimManagement/FormElements";
import { useAuth } from "../../Hooks/useAuth";
import EditLeaveRemarksPopup from "./EditLeaveRemarksPopup";

const ViewLeaveManagement = () => {
    const { currentUser } = useAuth();
    // const [leaveCountData, leaveLoading, leaveError] = useDocumentData(dataControlCollectionRef.doc("leaveCount"));

    const [myLeaves, loading, error] = useCollectionData(
        leavesCollectionRef
            .where("empID", "==", currentUser.empID)
            .orderBy("startDate", "desc"),
        { idField: "id" }
    );

    leavesCollectionRef
        .where("empID", "==", currentUser.empID)
        .orderBy("startDate", "desc")
        .get(() => {
            console.log("leave data changed");
        });

    return (
        <>
            <SidebarComponent>
                <section className="page-heading sticky-top">
                    <div className="container">
                        <h3 className="">Leave Management</h3>
                    </div>
                </section>
                <section className="container">
                    {currentUser && (
                        <h4 className="mb-4">
                            Your annual leave balance:{" "}
                            {currentUser.leaveBalance} days{" "}
                        </h4>
                    )}
                    {/* <button onClick={decrementLeave} className="btn btn-primary">Change</button> */}
                </section>
                <section className="">
                    <div className="container ">
                        <DateSelectionPopup />
                        <div className="table-responsive">
                            {myLeaves && myLeaves.length > 0 && (
                                <table className="table mt-4 pr-5 table-bordered border-primary table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>Leave Type</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Days</th>
                                            <th>Remarks</th>
                                            <th>Status</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myLeaves &&
                                            myLeaves.map((claim, index) => (
                                                <LeaveRow
                                                    claim={claim}
                                                    index={index}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewLeaveManagement;

const LeaveRow = ({ claim, index }) => {
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{claim.leaveType}</td>
            <td>{customDateFormat(claim.startDate)}</td>
            <td>{customDateFormat(claim.endDate)}</td>
            <td> {claim.leaveDays}</td>
            <td>{claim.leaveRemarks} </td>
            <td>
                <StatusBadge status={claim.status} />
            </td>
            <td>
                <EditLeaveRemarksPopup claim={claim} />
            </td>
        </tr>
    );
};
