import React, { useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { fireStorage, myelinersCollectionRef } from "../../firebase/fire-app";
import { Spinner } from "react-bootstrap";

const DPUploadPopup = ({ data }) => {
    const [show, setShow] = useState();
    const [dpLoading, setDpLoading] = useState(false)

    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ aspect: 1, x: 0, y: 0 });
    const [croppedImage, setCroppedImage] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            console.log(reader.result);
            setImage(reader.result);
        };

        reader.readAsDataURL(file);
    }, []);

    const onCropChange = (newCrop) => {
        // Ensure that width and height are the same
        if (newCrop.width !== newCrop.height) {
            const size = Math.min(newCrop.width, newCrop.height);
            newCrop.width = size;
            newCrop.height = size;
            setCrop(newCrop);
        } else {
            setCrop(newCrop);
        }
    };

    const onImageLoaded = (img) => {
        if (img.naturalWidth < 200 || img.naturalHeight < 200) {
            // You can add validation here to make sure the image is big enough.
            alert("Image is too small.");
            return;
        }
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        // Use the croppedAreaPixels to crop the image when saving.
        console.log(croppedArea, croppedAreaPixels);
    };

    const uploadCroppedImage = () => {
        if (image) {
            setDpLoading(true)
            myelinersCollectionRef
                .doc(data.email)
                .update({
                    img: image,
                })
                .then(() => {
                    setDpLoading(false)
                    alert("Profile picture uploaded successfully!!!")
                    setShow(false);
                });
            // const fileRef = fireStorage.ref().child('DP/'+data.empID).child(data.empID);
            // fileRef.put(image).then((snapshot)=>{
            //     snapshot.ref.getDownloadURL().then((url)=>{
            //         myelinersCollectionRef.doc(data.email).update({
            //             img: url
            //         }).then(()=>{
            //             setShow(false)
            //         })
            //     })
            // })
        } else {
            alert("please select an image.");
        }
    };
    return (
        <>
            <button
                className="btn edit-btn btn-danger btn-smaller"
                onClick={() => setShow(true)}
            >
                Edit
            </button>

            <Modal
                backdrop="static"
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Edit Profile Picture
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <InputWrapper col="12" label="Image">
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => onDrop(e.target.files)}
                                        className="form-control"
                                    />
                                </InputWrapper>
                            </div>
                            {image && (
                                <div className="px-5">
                                    <ReactCrop
                                        crop={crop}
                                        onChange={onCropChange}
                                        onComplete={onCropComplete}
                                        onImageLoaded={onImageLoaded}
                                    >
                                        <img src={image} />
                                    </ReactCrop>
                                    {/* <ReactCrop
                                        src={image}
                                        crop={crop}
                                        onChange={onCropChange}
                                        onComplete={onCropComplete}
                                        onImageLoaded={onImageLoaded}
                                    /> */}
                                </div>
                            )}
                            {croppedImage && (
                                <div>
                                    <h3>Cropped Image Preview</h3>
                                    <img src={croppedImage} alt="Cropped" />
                                </div>
                            )}
                            <div className="text-center">
                                {dpLoading ? <button
                                    disabled
                                    className="btn btn-outline-primary"
                                >
                                    Upload <Spinner size="sm"/>
                                </button>:<button
                                    onClick={uploadCroppedImage}
                                    className="btn btn-outline-primary"
                                >
                                    Upload
                                </button>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-5">
                                {data?.img && (
                                    <img
                                        src={data.img}
                                        alt=""
                                        className="img-fluid"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DPUploadPopup;
