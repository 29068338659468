import React, { useEffect } from 'react';
import SidebarComponent from '../../Components/Shared/SidebarComponent';
import { Link, useParams } from 'react-router-dom';
import { myelinersCollectionRef } from '../../firebase/fire-app';
import EmployeeDocuments from './EmployeeDocuments';
import PopupAddPayslip from './PopupAddPayslip';
import EmployeeIndividualDocuments from '../ViewPersonalProfile/EmployeeIndividualDocuments';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import EmployeeProfileTemplate from '../ViewPersonalProfile/EmployeeProfileTemplate';
import { useAuth } from '../../Hooks/useAuth';

const AdminEmployeeDetails = () => {
    const params = useParams();
    const email = params.email;
    const [employeeInfo, setEmployeeInfo] = React.useState(null);
    const [employeeDetails, loading, error] = useDocumentData(myelinersCollectionRef.doc(email));
    const {currentUser} = useAuth()

    // useEffect(() => {
    //     myelinersCollectionRef.doc(empID).get()
    //         .then((doc) => {
    //             if (doc.exists) {
    //                 setEmployeeInfo(doc.data())
    //             } else {
    //                 alert('No Employee Found')
    //             }
    //         })
    // }, [])
    return (
        <>
            {employeeDetails && <SidebarComponent>
                <EmployeeProfileTemplate currentUser={currentUser} email={email} empID={employeeDetails.empID} admin={true} />

            </SidebarComponent>}
        </>
    );
};

export default AdminEmployeeDetails;