import { AuthContextProvider } from "./Hooks/useAuth";
// import SidebarComponent from "./Components/Shared/SidebarComponent"
import "./styles/bootstrap.scss";
import Main from "./Views/Main";

import "./reset.scss";
import "./styles/index.scss";

function App() {
    return (
        <>
            <AuthContextProvider>
                {/* <WebConfigContextProvider> */}
                    <Main />
                {/* </WebConfigContextProvider> */}
            </AuthContextProvider>
        </>
    );
}

export default App;
