import React from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import PopupAddNewJob from "./PopupAddNewJob";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { jobPostsCollectionRef } from "../../firebase/fire-app";
import CareerCard from "./CareerCard";
import careerOpenings from "./careerdata";

const AdminJobPortal = () => {
    const [allJobPosts, loading, error] = useCollectionData(jobPostsCollectionRef, {idField: "id"})
    return (
        <>
            <SidebarComponent>
                <section className="page-heading sticky-top">
                    <div className="d-flex container align-items-center justify-content-between">
                        <h3 className="mb-0">Job Posts</h3>
                        <div>
                            <PopupAddNewJob />
                        </div>
                    </div>
                </section>
                <section className="bg-light py-4">
                    <div className="container-fluid">
                        <div className="row">
                            {
                                allJobPosts && allJobPosts.length >0 && allJobPosts.map(item => <CareerCard col="4" data={item} key={item.id}/> )
                            }
                        </div>
                        
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default AdminJobPortal;


