import React, { useEffect, useState } from "react";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import {
    documentsManagerCollectionRef,
    fireStorage,
} from "../../firebase/fire-app";
import { getFileExtension } from "../../utilities/text-operations";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../../Hooks/useAuth";

const PopupUserDocumentUploader = () => {
    const [show, setShow] = useState(false);
    const { currentUser } = useAuth();
    const [activeCategory, setActiveCategory] = useState(false);
    const [fileName, setFileName] = useState("");
    const [selectedEmpID, setSelectedEmpID] = React.useState("-");

    useEffect(() => {
        if (currentUser) {
            setSelectedEmpID(currentUser.email);
        }
    }, [currentUser]);

    const docCategories = [
        `ID & Visa`,
        `Employment Documents`,
        `Miscellaneous`,
    ];
    const [fileDetails, setFileDetails] = useState({});
    const [fileInfo, setFileInfo] = useState({
        name: "",
        category: "all",
    });
    const [allTags, setAllTags] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [uploading, setUploading] = useState(false);
    // unique code generation
    const timestamp = Date.now();
    const timestampBase36 = timestamp.toString(36);
    const uniqueCode = timestampBase36;

    useEffect(() => {
        console.log(fileInfo);
    }, [fileInfo]);

    const onFileUpload = (e) => {
        setUploading(true);
        let uploadedFile = e.target.files[0];
        let uploadedFileName = uploadedFile.name;
        let fileType = getFileExtension(uploadedFile.type);
        const fileRefName = uniqueCode + " - " + uploadedFileName;
        const fileRef = fireStorage.ref().child("all-docs/").child(fileRefName);

        let fileDetailsDoc = {
            ...fileDetails,
            fileType: fileType,
            fileRefName: fileRefName,
        };

        fileRef.put(uploadedFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
                fileDetailsDoc.fileURL = url;
                setFileDetails({ ...fileDetailsDoc });
                console.log(url);
                setFileURL(url);
                setUploading(false);
            });
        });
    };
    const addImageToDB = () => {
        if (selectedEmpID && fileInfo) {
            setUploading(true);
            console.log({ ...fileInfo, ...fileDetails });
            documentsManagerCollectionRef
                .add({
                    ...fileInfo,
                    ...fileDetails,
                    employeeEmail: currentUser?.email,
                    createdAt: new Date(),
                })
                .then((e) => {
                    setFileName("");
                    setFileDetails({});
                    setFileURL(false);
                    setShow(false);
                    setFileInfo({});
                    setUploading(false);
                });
        }else{
            alert("Please fill all the fields.")
        }
    };
    return (
        <>
            <button
                className={`btn btn-primary btn-sm`}
                onClick={() => setShow(true)}
            >
                Upload New Document
            </button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Upload New Document
                    </h5>
                </Modal.Header>
                {currentUser ? (
                    <Modal.Body>
                        <div className="row d-flex align-items-center">
                            <InputWrapper col="8" label="Tag">
                                <input
                                    type="file"
                                    onChange={(e) => onFileUpload(e)}
                                    name="imageFile"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <div className="col-md-4">
                                {fileURL && currentUser ? (
                                    <button
                                        onClick={addImageToDB}
                                        className="btn-sm mb-3 btn btn-primary"
                                    >
                                        Upload document{" "}
                                        {uploading && (
                                            <Spinner
                                                animation="grow"
                                                size="sm"
                                            />
                                        )}
                                    </button>
                                ) : (
                                    <button
                                        disabled
                                        className="btn-sm mb-3 btn btn-primary"
                                    >
                                        Upload document{" "}
                                        {uploading && (
                                            <Spinner
                                                animation="grow"
                                                size="sm"
                                            />
                                        )}
                                    </button>
                                )}
                            </div>
                            <InputWrapper col="6" label="Name">
                                <input
                                    onChange={(e) =>
                                        setFileInfo({
                                            ...fileInfo,
                                            name: e.target.value,
                                        })
                                    }
                                    defaultValue={fileName}
                                    type="text"
                                    name="name"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper col="6" label="Category">
                                <select
                                    onChange={(e) =>
                                        setFileInfo({
                                            ...fileInfo,
                                            category: e.target.value,
                                        })
                                    }
                                    name="category"
                                    id="category"
                                    className="form-select"
                                >
                                    <option>Select category</option>

                                    {docCategories.map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                            </InputWrapper>

                            {/* <div className="col-12 mb-3">
                            {allTags ? (
                                allTags.map((item) => (
                                    <TagItem
                                        data={item}
                                        setActiveTag={setActiveTag}
                                        activeTag={activeTag}
                                    />
                                ))
                            ) : (
                                <Spinner size="sm" />
                            )}
                            <button className="btn btn-sm btn-light">
                                Add tag
                            </button>
                        </div> */}

                            {/* <div className="col-12 text-center">
                            {imageURL ? (
                                <button className="btn btn-primary">
                                    Add Image
                                </button>
                            ) : (
                                <button disabled className="btn btn-primary">
                                    Add Image
                                </button>
                            )}
                        </div> */}
                        </div>
                    </Modal.Body>
                ) : (
                    <div className="w-100 py-5 text-center">
                        {" "}
                        <Spinner animation="grow" />
                    </div>
                )}
            </Modal>
        </>
    );
};

export default PopupUserDocumentUploader;

const TagItem = ({ data, activeTag, setActiveTag }) => {
    const [tagClass, setTagClass] = useState("secondary");

    useEffect(() => {
        if (data === activeTag) {
            setTagClass("primary");
        } else {
            setTagClass("secondary");
        }
    }, [activeTag]);

    return (
        <button
            type="button"
            onClick={() => setActiveTag(data)}
            className={`btn me-2 btn-sm btn-${tagClass}`}
        >
            #{data}
        </button>
    );
};
