import React, { useState } from "react";
import { webServerLive } from "../../firebase/liveServerStatus";
import {
    assignedNewTaskMessage,
    sendTaskReminderMessage,
} from "../ViewClaimManagement/messageGenerator";
import emailjs from "@emailjs/browser";
import { Spinner } from "react-bootstrap";

const TaskEmailTrigger = ({ data }) => {
    const [systemLoading, setSystemLoading] = useState(null);

    const mailData = {
        title: data.title,
        details: data.details,
        name: "team",

        status: "Approved",
        mailOne: data.assignees,
        mailTwo: "shahjahan.swajan@myelinh.com",
        from_name: "Myelin-H HR",
    };
    const onTriggerEmail = () => {
        if (data.roleBasedAssignment) {
            const newAssigneeList = data.roleBasedAssignees.map(
                (assignee) => assignee.email
            );
            mailData.mailOne = newAssigneeList;
        } else {
            mailData.mailOne = data.assignees;
        }
        setSystemLoading(true);
        const mailDetails = assignedNewTaskMessage(mailData);

        if (webServerLive) {
            emailjs
                .send(
                    "service_zinxo6v",
                    "template_eqvm7mf",
                    mailDetails,
                    "ygk5Yo8QhiJUWbVZM"
                )
                .then(() => {
                    setSystemLoading(false);
                });
        }
    };
    const onSendTaskReminder = () => {
        if (data.roleBasedAssignment) {
            const newAssigneeList = data.roleBasedAssignees.map(
                (assignee) => assignee.email
            );
            mailData.mailOne = newAssigneeList;
        } else {
            mailData.mailOne = data.assignees;
        }
        setSystemLoading(true);
        const mailDetails = sendTaskReminderMessage(mailData);

        if (true) {
            emailjs
                .send(
                    "service_zinxo6v",
                    "template_eqvm7mf",
                    mailDetails,
                    "ygk5Yo8QhiJUWbVZM"
                )
                .then(() => {
                    setSystemLoading(false);
                });
        }
    };
    return (
        <>
        <div className="d-flex">
            {systemLoading ? (
                <button
                    onClick={onTriggerEmail}
                    disabled
                    className="btn btn-sm btn-primary text-no-wrap me-3"
                >
                    Send notification <Spinner animation="grow" size="sm" />
                </button>
            ) : (
                <button
                    onClick={onTriggerEmail}
                    className="btn btn-sm btn-primary text-no-wrap me-3"
                >
                    Send notification
                </button>
            )}
            {systemLoading ? (
                <button
                    onClick={onSendTaskReminder}
                    disabled
                    className="btn btn-sm btn-warning text-no-wrap"
                >
                    Send reminder <Spinner animation="grow" size="sm" />
                </button>
            ) : (
                <button
                    onClick={onSendTaskReminder}
                    className="btn btn-sm btn-warning text-no-wrap"
                >
                    Send reminder
                </button>
            )}
            </div>
        </>
    );
};

export default TaskEmailTrigger;
