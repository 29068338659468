import { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import { AiFillHdd } from "react-icons/ai";
import { FcCalendar } from "react-icons/fc";
import { MdGroupAdd } from "react-icons/md";
import { useAuth } from "../../Hooks/useAuth";
import CompEmpBasic from "../../Components/EmployeeCard/CompEmpBasic";
import { assignedTasksCollectionRef } from "../../firebase/fire-app";

const OverlayAssignTask = () => {
    const [show, setShow] = useState(false);
    const { myelinners, currentUser } = useAuth();
    const target = useRef(null);
    const [assignedEmployees, setAssignedEmployees] = useState([]);
    const [taskTitle, setTaskTitle] = useState("");
    const [dueDate, setDueDate] = useState(new Date());

    const onAssigneeChange = (newEmp, action) => {
        if (action === "add") {
            const newCollection = [...new Set([...assignedEmployees, newEmp])];
            setAssignedEmployees([...newCollection]);
        } else {
            const newCollection = assignedEmployees.filter(
                (item) => item !== newEmp
            );
            setAssignedEmployees([...newCollection]);
        }
    };
    const [taskPriority, setTaskPriority] = useState(null);

    const onAssignTaskClicked = () => {
        const taskDetails = {
            title: taskTitle,
            endDate: new Date(dueDate),
            assignees: assignedEmployees,
            createdAt: new Date(),
            lastUpdated: new Date(),
            lastUpdatedBy: currentUser.email,
            lastUpdatedIncident: "task created",
            liveStatus: "active",
            priority: taskPriority ? taskPriority : "Medium",
            status: "New task",
        };
        assignedTasksCollectionRef.add(taskDetails).then(() => {
            setTaskTitle("");
            setDueDate(new Date());
            setAssignedEmployees([]);
            setShow(false);
        });
    };
    console.log(dueDate);
    const onDueDateChanged = (e) => {
        setDueDate(new Date(e.target.value));
    };
    return (
        <>
            <Button
                variant="primary"
                ref={target}
                size="sm"
                onClick={() => setShow(!show)}
            >
                Create new task
            </Button>
            <Overlay target={target.current} show={show} placement="bottom">
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                }) => (
                    <div
                        className="overlay-primary"
                        {...props}
                        style={{
                            position: "absolute",
                            ...props.style,
                        }}
                    >
                        <div className="p-3">
                            <div className="d-flex align-items-center">
                                <div className="icon-container text-primary">
                                    <AiFillHdd />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Enter a task name * (required)"
                                    className="form-control"
                                    onBlur={(e) => setTaskTitle(e.target.value)}
                                />
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <div className="icon-container text-primary">
                                    <FcCalendar />
                                </div>
                                <input
                                    type="date"
                                    placeholder="Enter a task name * (required)"
                                    className="form-control"
                                    onChange={(e) => onDueDateChanged(e)}
                                />
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <div className="icon-container text-primary">
                                    <AiFillHdd />
                                </div>
                                <select
                                    onChange={(e) =>
                                        setTaskPriority(e.target.value)
                                    }
                                    name="priority"
                                    id="priority"
                                    className="form-select"
                                >
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                </select>
                            </div>
                            {/* <div>{dueDate.toDateString()}</div> */}
                            <div className="d-flex  mt-3">
                                <div className="icon-container  text-primary">
                                    <MdGroupAdd />
                                </div>
                                <div>
                                    <select
                                        onChange={(e) =>
                                            onAssigneeChange(
                                                e.target.value,
                                                "add"
                                            )
                                        }
                                        id="disabledSelect"
                                        className="mb-2 form-select"
                                    >
                                        <option>Select Employee</option>
                                        {myelinners &&
                                            myelinners.length > 0 &&
                                            myelinners.map((item) => (
                                                <option value={item.id}>
                                                    {item.id}
                                                </option>
                                            ))}
                                    </select>
                                    {assignedEmployees &&
                                        assignedEmployees.map((item) => (
                                            <CompEmpBasic
                                                action={onAssigneeChange}
                                                email={item}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={onAssignTaskClicked}
                            className="btn w-100 assign-btn d-block btn-primary"
                        >
                            Add task
                        </button>
                    </div>
                )}
            </Overlay>
        </>
    );
};

export default OverlayAssignTask;
