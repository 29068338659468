import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
    payslipsCollectionRef,
    fireStorage,
    documentsCollectionRef,
    myelinersCollectionRef,
} from "../../firebase/fire-app";
import { useForm } from "react-hook-form";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { Spinner } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import emailjs from "@emailjs/browser";
import { documentUploadMessage } from "../ViewClaimManagement/messageGenerator";
import { webServerLive } from "../../firebase/liveServerStatus";

const PopupUploadDocuments = ({ email, data, empID }) => {
    const [currentEmp, setCurrentEmp] = useState(empID);
    const [show, setShow] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const [documentType, setDocumentType] = useState(null);
    const [docUrl, setDocUrl] = useState(null);
    const [uploading, setUploading] = useState(false);

    const onFileUpload = (e) => {
        setUploading(true);
        let uploadedFile = e.target.files[0];
        let fileName = uploadedFile.name;
        let fileType = uploadedFile.type;
        // alert(fileType);
        const fileRef = fireStorage
            .ref()
            .child("documents/" + empID)
            .child(fileName);

        fileRef.put(uploadedFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
                console.log(url);
                setDocUrl(url);
                const mailData = {
                    name: data.name,
                    mailOne: data.email,
                    mailTwo: "zied.tayeb@myelinh.com",
                    link: url,
                };

                const mailDetails = documentUploadMessage(
                    mailData,
                    "Personal Document"
                );
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetails,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                }
                // setNewDocument({
                //     url: docUrl,
                //     type: documentType
                // })
                setUploading(false);
            });
        });
    };

    const onDocumentAdd = () => {
        myelinersCollectionRef.doc(email).update({ [documentType]: docUrl });
        setShow(false);
    };
    return (
        <>
            <Button variant="danger" size="sm" onClick={() => setShow(true)}>
                Add Document
            </Button>

            {data && (
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header className="py-1 " closeButton>
                        <h5
                            className="font-weight-bold mt-1"
                            id="example-custom-modal-styling-title"
                        >
                            Add Document - {data.name}
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label font-bold"
                                    >
                                        Employee ID{" "}
                                    </label>
                                    <input
                                        disabled
                                        defaultValue={empID}
                                        type="text"
                                        name="empID"
                                        className="form-control outline-light"
                                        id="exampleInputEmail1"
                                        placeholder="M00XX"
                                        aria-describedby="emailHelp"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8"></div>
                            <InputWrapper col="4" label="Type">
                                <select
                                    onChange={(e) =>
                                        setDocumentType(e.target.value)
                                    }
                                    id="disabledSelect"
                                    className="form-select"
                                >
                                    <option>Select document type</option>
                                    <option value="empContract">
                                        Employment Contract
                                    </option>
                                    <option value="empNationalID">
                                        National ID
                                    </option>
                                    <option value="empPassport">
                                        Passport
                                    </option>
                                    <option value="empJobPerks">
                                        Job Perks
                                    </option>
                                    <option value="empCV">CV</option>
                                    <option value="msclOne">
                                        Miscellaneous Doc One
                                    </option>
                                    <option value="msclTwo">
                                        Miscellaneous Doc Two
                                    </option>
                                    <option value="msclThree">
                                        Miscellaneous Doc Three
                                    </option>
                                </select>
                            </InputWrapper>
                            <InputWrapper col="6" label="Document">
                                <input
                                    onChange={(e) => onFileUpload(e)}
                                    placeholder="upload document"
                                    type="file"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                />
                            </InputWrapper>

                            <div className="col-12">
                                {docUrl ? (
                                    <button
                                        onClick={onDocumentAdd}
                                        className="btn btn-primary btn-sm"
                                    >
                                        Add Document
                                    </button>
                                ) : (
                                    <button
                                        disabled
                                        className="btn btn-sm btn-primary"
                                    >
                                        Add Document{" "}
                                        {uploading && <Spinner size="sm" />}{" "}
                                    </button>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default PopupUploadDocuments;

const PayslipCard = ({ data }) => {
    return (
        <>
            <a
                className="btn btn-sm btn-primary me-2 mb-2"
                target="_blank"
                href={data.url}
            >
                {data.payMonth}
            </a>
        </>
    );
};
