import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { customDateFormat } from '../../utilities';

const NotesViewerPopup = ({ data, className }) => {
    const [show, setShow] = useState(false);

    return (
        <>

            <Button variant="primary" className={className} size='sm' onClick={() => setShow(true)}>
                View
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Meeting Note - {customDateFormat(data.meetingDate)}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="font-14 d-flex justify-content-around mb-2">
                            <p className='mb-0'><b>Assigned to:</b> {data.assignedTo}; </p>
                            <p className='mb-0'><b>Meeting Date:</b> {customDateFormat(data.meetingDate)}; </p>
                            <p className='mb-0'><b>Last updated:</b> {customDateFormat(data.lastUpdated)} {data.lastUpdatedBy && <span>(by: {data.lastUpdatedBy})</span>}; </p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div dangerouslySetInnerHTML={{ __html: data.content }}>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default NotesViewerPopup;