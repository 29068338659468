import React, { useEffect, useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { Spinner } from "react-bootstrap";
import "./EmpCard.scss";
import { MdOutlinePersonRemove } from "react-icons/md";

const CompEmpCardtype = ({ email, action }) => {
    const { myelinners } = useAuth();
    const [empData, setEmpData] = useState(null);

    useEffect(() => {
        if (myelinners && myelinners.length > 0) {
            const emp = myelinners.filter((item) => item.email === email);
            setEmpData(emp[0]);
        }
    }, [email, myelinners]);

    return (
        <>
            {empData ? (
                <div className="basic-emp-card mb-2 d-flex align-items-center col-md-3 p-2">
                    <div className="dp mb-0 me-2">
                        <img src={empData.img} alt="" />
                    </div>

                    <div className="d-flex w-100 justify-content-around align-items-center">
                        <h6 className="mb-0 d-inline-block font-14">
                            {empData.name}
                        </h6>
                        <div className="ms-auto">
                            <MdOutlinePersonRemove
                                className="text-danger"
                                onClick={() => action(email)}
                            />
                        </div>{" "}
                    </div>
                </div>
            ) : (
                <Spinner animation="grow" size="sm" />
            )}
        </>
    );
};

export default CompEmpCardtype;
