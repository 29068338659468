import React from "react";
import "./profilecard.scss";
import { FaLinkedinIn, FaInstagram, FaTwitter, FaLink } from "react-icons/fa";
import EditProfileCardInfo from "../ViewPersonalProfile/EditProfileCardInfo";
import { useAuth } from "../../Hooks/useAuth";
import ProfileCardDetails from "./ProfileCardDetails";
import { myelinersCollectionRef } from "../../firebase/fire-app";
const ProfileCardSingle = ({ data }) => {
    const { currentUser } = useAuth();
    const updatePublicDescription =()=>{
        myelinersCollectionRef.doc(data.id).update({
            publicDescription: data.shortDescription
        }).then(()=>{
            alert("updated")
        })
    }
    return (
        <>
            <div className="col-md-6 mb-3   f">
                <div className="profile-card-single">
                    {/* <button onClick={updatePublicDescription} className="btn btn-sm btn-danger">update short</button> */}
                    <div className="edit-btn-container">
                        {/* <EditProfileCardInfo data={data} /> */}

                        {/* {(currentUser?.email === data.email || currentUser?.email === "zied.tayeb@myelinh.com" || currentUser?.email === "shahjahan.swajan@bat.com")? (
                            <EditProfileCardInfo data={data} />
                        ):""} */}
                        {(currentUser?.email === data.email || currentUser?.email === "zied.tayeb@myelinh.com" || currentUser?.email === "shahjahan.swajan@myelinh.com")? (
                            <EditProfileCardInfo data={data} />
                        ):""}
                      {/* <p className="font-bold">  {data.serial}</p> */}
                    </div>
                    <div className="row d-flex align-items-center">
                        <div className="col-5">
                            <div className="dp-img">
                                <img
                                    src={data.img}
                                    alt=""
                                    className="img-luid"
                                />
                            </div>
                            <div className="text">
                                <p className="name mb-0">{data.name}</p>
                                <p className="job_title">{data.designation}</p>
                            </div>
                            <div className="icons">
                                {data.socialLinkedIn && (
                                    <a
                                        href={data.socialLinkedIn}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaLinkedinIn />
                                    </a>
                                )}
                                {data.socialInstagram && (
                                    <a
                                        href={data.socialInstagram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaInstagram />
                                    </a>
                                )}
                                {data.socialTwitter && (
                                    <a
                                        href={data.socialTwitter}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaTwitter />
                                    </a>
                                )}
                                {data.socialWeb && (
                                    <a
                                        href={data.socialWeb}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaLink />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="right-description ps-3">
                                <div className="text-align-left font-14 mb-0 pb-0">
                                    <p className="mb-0">{data?.shortProfileBrief}</p>
                                </div>

                                {data && <ProfileCardDetails data={data} />}
                                <div className="d-flex mt-4 justify-content-center">
                                    <div>
                                        <a
                                            className="btn mx-1 btn-sm btn-primary cta"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`mailto:${data.email}`}
                                        >
                                            Message
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={data.socialLinkedIn}
                                            className="btn btn-sm btn-outline-primary"
                                        >
                                            Connect
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileCardSingle;

const ReadMore = () => {
    return <button className="btn btn-sm btn-primary">READ MORE</button>;
};
