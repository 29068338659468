import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Button } from "react-bootstrap";
import AssessorViewRecruitment from "./AssessorViewRecruitment";
import AdminViewRecruitment from "./AdminViewRecruitment";
import { useAuth } from "../../Hooks/useAuth";

const ViewRecruitment = () => {
    const [recruitmentMode, setRecruitmentMode] = useState("assessor");
    const {isAdmin, currentUser} = useAuth();

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top page-heading ">
                    <div className="container ">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="">Recruitment - {recruitmentMode}</h3>
                            <div>
                                <ButtonGroup
                                    size="sm"
                                    aria-label="basic example"
                                >
                                    {isAdmin && <Button
                                        onClick={() =>
                                            setRecruitmentMode("admin")
                                        }
                                        variant={`${
                                            recruitmentMode === "admin"
                                                ? "primary"
                                                : "light"
                                        }`}
                                    >
                                        Admin
                                    </Button>}
                                    <Button
                                        onClick={() =>
                                            setRecruitmentMode("assessor")
                                        }
                                        variant={`${
                                            recruitmentMode === "assessor"
                                                ? "primary"
                                                : "light"
                                        }`}
                                    >
                                        Assessor
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-3">
                    <div className="container">
                        {recruitmentMode === "assessor" && currentUser ? (
                            <AssessorViewRecruitment email={currentUser.email} />
                        ) : (
                            <AdminViewRecruitment />
                        )}
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewRecruitment;
