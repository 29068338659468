import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import "./personal-profile.scss";
import { customDateFormat } from "../../utilities";
import EditPersonalInfoPopup from "./EditPersonalInfoPopup";
import CompPersonalPayslips from "./CompPersonalPayslips";
import PopupBankAccount from "../AdminEditEMployee/PopupBankAccount";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { documentsManagerCollectionRef, myelinersCollectionRef } from "../../firebase/fire-app";
import SectionLoading from "../../Components/SectionLoading";
import { webServerLive } from "../../firebase/liveServerStatus";
import PopupUpdateDeclaration from "./PopupUpdateDeclaration";
import DPUploadPopup from "./DPUploadPopup";
import { Button } from "react-bootstrap";
import PopupUserDocumentUploader from "../AdminDocumentManager/PopupUserDocumentUploader";
import { DocumentCard } from "../AdminDocumentManager/AdminDocumentManager";

const EmployeeProfileTemplate = ({ currentUser, empID, admin, email }) => {
    const [employeeInfo, loading, error] = useDocumentData(
        myelinersCollectionRef.doc(email)
    );

    const [empDocs, docLoading, docError] = useCollectionData(documentsManagerCollectionRef.orderBy("createdAt", "desc").where("employeeEmail", "==", email), {idField: "id"})
    const [activeSection, setActiveSection] = useState("bank-details");
    // documentsManagerCollectionRef.orderBy("createdAt", "desc").where("employeeEmail", "==", email).get();
    return (
        <>
            {loading && <SectionLoading />}
            {employeeInfo && (
                <div className="personal-profile-page">
                    <div className="sticky-top">
                        <section className="personal-profile bg-light py-3">
                            <div className="container">
                                <div className="row d-flex align-items-center">
                                    <div className="col-md-3">
                                        <div className="dp-container">
                                            <img
                                                className="profile-image"
                                                src={employeeInfo.img}
                                                alt=""
                                            />
                                            <DPUploadPopup
                                                data={employeeInfo}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-left">
                                        <h3 className="">
                                            {employeeInfo.name} (
                                            {employeeInfo.empID})
                                        </h3>
                                        <p className="text-secondary mb-">
                                            {employeeInfo.designation}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className={`py-2 ${
                                webServerLive ? "bg-primary" : "bg-info"
                            }`}
                        >
                            <div className="container  justify-content-">
                                <a
                                    onClick={() =>
                                        setActiveSection("bank-details")
                                    }
                                    href="#bank-details"
                                    className="btn me-2 my-1 btn-white"
                                >
                                    Bank Account Info
                                </a>
                                <a
                                    onClick={() =>
                                        setActiveSection("personal-docs")
                                    }
                                    href="#personal-docs"
                                    className="btn me-2 my-1 btn-white"
                                >
                                    Personal Docs
                                </a>
                                <a
                                    onClick={() => setActiveSection("payslips")}
                                    href="#payslips"
                                    className="btn mx-1 me-2 btn-white"
                                >
                                    Payslips
                                </a>
                                <a
                                    onClick={() =>
                                        setActiveSection("leave-balance")
                                    }
                                    href="#leave-balance"
                                    className="btn mx-1 me-2 btn-white"
                                >
                                    Leave Balance
                                </a>
                            </div>
                        </section>
                    </div>

                    <section id="docs" className="mt-4">
                        <div className="container">
                            <div className="row">
                                <div
                                    className="col-md-6 mb-4"
                                    id="bank-details"
                                >
                                    <div
                                        className={`card p-3 ${
                                            activeSection === "bank-details" &&
                                            "active"
                                        }`}
                                    >
                                        <div className="mb-3 d-flex justify-content-between">
                                            <h5>
                                                Bank Account Details{" "}
                                                {activeSection}
                                            </h5>
                                        </div>
                                        {employeeInfo && (
                                            <PopupBankAccount
                                                empDetails={employeeInfo}
                                                admin={admin}
                                                email={employeeInfo.email}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="col-md-6 mb-4"
                                    id="personal-docs"
                                >
                                    {employeeInfo && (
                                        <div className="">
                                            <div
                                                className={`card p-3 ${
                                                    activeSection ===
                                                        "personal-docs" &&
                                                    "active"
                                                }`}
                                            >
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <h5>Employee Documents</h5> 
                                                    <PopupUserDocumentUploader/>
                                                </div>
                                                <div>
                                                    {
                                                        empDocs && empDocs.length > 0 && empDocs.map(item => <DocumentCard data={item} col="6" key={item.id} />)
                                                    }
                                                    {/* {employeeInfo.empContract && (
                                                        <a
                                                            href={
                                                                employeeInfo.empContract
                                                            }
                                                            className="btn btn-primary btn-sm me-2 mb-2"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Employment Contract
                                                        </a>
                                                    )}
                                                    {employeeInfo.empNationalID && (
                                                        <a
                                                            href={
                                                                employeeInfo.empNationalID
                                                            }
                                                            className="btn btn-primary btn-sm me-2 mb-2"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            National ID
                                                        </a>
                                                    )}
                                                    {employeeInfo.empPassport && (
                                                        <a
                                                            href={
                                                                employeeInfo.empPassport
                                                            }
                                                            className="btn btn-primary btn-sm me-2 mb-2"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Passport
                                                        </a>
                                                    )}
                                                    {employeeInfo.empJobPerks && (
                                                        <a
                                                            href={
                                                                employeeInfo.empJobPerks
                                                            }
                                                            className="btn btn-primary btn-sm me-2 mb-2"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Job Perks
                                                        </a>
                                                    )}
                                                    {employeeInfo.empCV && (
                                                        <a
                                                            href={
                                                                employeeInfo.empCV
                                                            }
                                                            className="btn btn-primary btn-sm me-2 mb-2"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            CV
                                                        </a>
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {employeeInfo && (
                                        <div className="mt-3" id="payslips">
                                            <div
                                                className={`card p-3 ${
                                                    activeSection ===
                                                        "payslips" && "active"
                                                }`}
                                            >
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <h5>Payslips</h5>
                                                </div>
                                                {employeeInfo && email && (
                                                    <CompPersonalPayslips
                                                        empID={empID}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="col-md-6 mb-4"
                                    id="leave-balance"
                                >
                                    {employeeInfo && (
                                        <div className="">
                                            <div
                                                className={`card p-3 ${
                                                    activeSection ===
                                                        "leave-balance" &&
                                                    "active"
                                                }`}
                                            >
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <h5>Leave Balance</h5>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table pr-5 table-bordered border-primary table-hover table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Entitlement
                                                                </th>
                                                                <td>
                                                                    {employeeInfo.leaveBalance +
                                                                        employeeInfo.leaveTaken}{" "}
                                                                    Days
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    Leave Taken
                                                                </th>
                                                                <td>
                                                                    {
                                                                        employeeInfo.leaveTaken
                                                                    }{" "}
                                                                    Days
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-danger">
                                                                    Leave
                                                                    Balance
                                                                </th>
                                                                <th className="text-danger">
                                                                    {
                                                                        employeeInfo.leaveBalance
                                                                    }{" "}
                                                                    Days
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6 mb-4" id="declaration">
                                    <div
                                        className={`card p-3 ${
                                            activeSection === "declaration" &&
                                            "active"
                                        }`}
                                    >
                                        <div className="mb-3 d-flex justify-content-between">
                                            <h5>Personal Declaration</h5>
                                        </div>
                                        {employeeInfo && (
                                            <PopupUpdateDeclaration
                                                employeeInfo={employeeInfo}
                                                admin={admin}
                                                email={employeeInfo.email}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="personal-information" className="mt-3">
                        <div className="container">
                            <div className="d-flex  mb-3">
                                <h5 className="me-3">Personal Information</h5>
                                {employeeInfo && (
                                    <EditPersonalInfoPopup
                                        empID={empID}
                                        currentUser={employeeInfo}
                                    />
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <div className="card p-3">
                                        <p className="text-secondary mb-0">
                                            Name: {employeeInfo.name}
                                        </p>
                                        <p className="text-secondary mb-0">
                                            Phone: {employeeInfo.phone}
                                        </p>
                                        <p className="text-secondary mb-0">
                                            WhatsApp: {employeeInfo.phone}
                                        </p>
                                        <p className="text-secondary mb-0">
                                            Personal Email:{" "}
                                            {employeeInfo.personalEmail}
                                        </p>
                                        <p className="text-secondary mb-1">
                                            Date of Birth:{" "}
                                            {customDateFormat(employeeInfo.dob)}
                                        </p>
                                        <p className="text-secondary mb-1">
                                            Address: {employeeInfo.address}
                                        </p>
                                        <p className="text-secondary mb-1">
                                            Country: {employeeInfo.country}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="card p-3">
                                        <p className="text-secondary mb-1">
                                            Marital Status:{" "}
                                            {employeeInfo.maritalStatus}
                                        </p>
                                        <p className="text-secondary mb-0">
                                            Preferred Language:{" "}
                                            {employeeInfo.preferredLang}
                                        </p>
                                        <p className="text-secondary mb-3">
                                            Other Languages:{" "}
                                            {employeeInfo.allLang}
                                        </p>
                                        <p className="text-secondary mb-0">
                                            Emergency Contact:{" "}
                                            {employeeInfo.emergencyContact}
                                        </p>
                                        <p className="text-secondary mb-0">
                                            Relation:{" "}
                                            {employeeInfo.emergencyRelation}
                                        </p>
                                        <p className="text-secondary mb-0">
                                            Phone:{" "}
                                            {employeeInfo.emergencyNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </>
    );
};

export default EmployeeProfileTemplate;
