import React from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import {
    authUsersCollection,
    myelinersCollectionRef,
} from "../../firebase/fire-app";
import { Link } from "react-router-dom";

const AdminAddEmployee = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [myeliners, loading, error] = useCollectionData(
        myelinersCollectionRef.orderBy("empID", "desc"),
        { idField: "id" }
    );
    const [authUsersList, usrLoading, usrError] = useDocumentData(
        authUsersCollection.doc("authUsers")
    );
    const onSubmit = (data, e) => {
        // alert(JSON.stringify(data))
        const dob = new Date(data.dob);
        data.dob = dob;
        data.createdAt = new Date();
        data.leaveBalance = 30;
        data.leaveTaken = 0;
        data.leaveEntitlement = 30;
        myelinersCollectionRef
            .doc(data.email)
            .get()
            .then((docSnapshot) => {
                if (docSnapshot.exists && authUsersList) {
                    alert("Employee ID already exists");
                } else {
                    const currUsers = [...authUsersList.users, data.email];
                    authUsersCollection.doc("authUsers").update({
                        users: [...new Set(currUsers)],
                    });
                    myelinersCollectionRef
                        .doc(data.email)
                        .set(data)
                        .then(() => {
                            alert("Employee Added");
                            e.target.reset();
                        });
                }
            });
    };
    return (
        <>
            <SidebarComponent>
                <section className="container">
                    <h3 className="mt-3">Add Employee</h3>
                </section>
                <hr />
                <section className="">
                    <div className="container ps-5">
                        <div className="row">
                            <div className="col-md-8">
                                {/* <h4 className='mt-4 mb-4'>Claim Something</h4> */}
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="row"
                                >
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="form-label font-bold"
                                            >
                                                Employee ID
                                            </label>
                                            <input
                                                {...register("empID")}
                                                type="text"
                                                name="empID"
                                                className="form-control outline-light"
                                                id="exampleInputEmail1"
                                                placeholder="XXXXXX"
                                                aria-describedby="emailHelp"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-8"></div>
                                    <InputWrapper col="6" label="Name">
                                        <input
                                            {...register("name")}
                                            type="text"
                                            name="name"
                                            className="form-control"
                                        />
                                    </InputWrapper>
                                    <InputWrapper col="6" label="Designation">
                                        <input
                                            {...register("designation")}
                                            type="text"
                                            name="designation"
                                            className="form-control"
                                        />
                                    </InputWrapper>
                                    <div className="col-md-5"></div>
                                    <InputWrapper col="5" label="DOB">
                                        <input
                                            {...register("dob")}
                                            name="dob"
                                            type="date"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                        />
                                    </InputWrapper>
                                    <div className="col-md-6"></div>
                                    <InputWrapper col="7" label="Email">
                                        <input
                                            {...register("email")}
                                            type="email"
                                            placeholder="xxxxx@myelinh.com"
                                            name="email"
                                            className="form-control"
                                        />
                                    </InputWrapper>
                                    <InputWrapper
                                        col="8"
                                        label="Personal Email"
                                    >
                                        <input
                                            {...register("personalEmail")}
                                            type="email"
                                            name="personalEmail"
                                            className="form-control"
                                        />
                                    </InputWrapper>
                                    <InputWrapper col="8" label="Phone">
                                        <input
                                            {...register("phone")}
                                            type="text"
                                            name="phone"
                                            className="form-control"
                                        />
                                    </InputWrapper>
                                    <InputWrapper col="8" label="WhatsApp">
                                        <input
                                            {...register("whatsApp")}
                                            type="text"
                                            name="whatsApp"
                                            className="form-control"
                                        />
                                    </InputWrapper>
                                    {/* <InputWrapper col="8" label="Image -">
                                        <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </InputWrapper> */}

                                    <div className="col-12">
                                        <button className="btn btn-primary">
                                            Add Employee
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className="col-md-4">
                                {myeliners &&
                                    myeliners.length > 0 &&
                                    myeliners.map((item) => (
                                        <EmpCard key={item.id} data={item} />
                                    ))}
                            </div>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default AdminAddEmployee;

const EmpCard = ({ data }) => {
    return (
        <div className="card font-14 mb-3 p-3 d-inline-block">
            <h5 className="card-title">{data.id}</h5>
            <p className="text-secondary mb-0">{data.name}</p>
            {/* <p className="text-secondary mb-1">{data.email}</p> */}
            <button className="btn btn-sm btn-primary inline-btn me-3">
                View Profile
            </button>
            <Link className="btn btn-sm btn-danger inline-btn">
                Edit Details
            </Link>
        </div>
    );
};
