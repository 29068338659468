import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { assignedTasksCollectionRef } from "../../firebase/fire-app";
import { Spinner } from "react-bootstrap";

const PopupTaskUpdate = ({ data, cardMode }) => {
    const [show, setShow] = useState(false);
    const [taskStatus, setTaskStatus] = useState(null)
    const [empNote,setEmpNote] = useState("")
    const [systemLoading, setSystemLoading] = useState(null)

    useEffect(()=>{
        if(data){
            setTaskStatus(data.status)
            setEmpNote(data?.empNote)
        }
    },[data])

    const updateTaskDetails = ()=>{
        setSystemLoading(true)
        if(empNote){
            assignedTasksCollectionRef.doc(data.id).update({
                status: taskStatus,
                empNote: empNote
            }).then(()=>{
                setSystemLoading(false)
                setShow(false)
            })
        }else{
            assignedTasksCollectionRef.doc(data.id).update({
                status: taskStatus,
            }).then(()=>{
                setSystemLoading(false)
                setShow(false)
            })
        }
       
    }

    return (
        <>
            <button className={`btn btn-${cardMode} btn-sm`} onClick={() => setShow(true)}>
                Update Task
            </button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Assign Task
                    </h5>
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex justify-content-between">
                        <h5 className="font-bold">{data.title}</h5>
                        <div>
                            <span className="badge bg-primary badge-light">
                                {data.status}
                            </span>
                        </div>
                    </div>
                    <div className="mt-2">
                        <i
                            dangerouslySetInnerHTML={{ __html: data.details }}
                        ></i>
                    </div>
                    <div className="row">
                        <InputWrapper col="4" label="Start date">
                            <select
                            onChange={(e)=> setTaskStatus(e.target.value)}
                                id="disabledSelect"
                                name="priority"
                                className="form-select"
                            >
                                <option value="">Select status</option>
                                <option value="New task">New task</option>
                                <option value="In progress" >In progress</option>
                                <option value="Completed">Completed</option>
                            </select>
                        </InputWrapper>
                        <InputWrapper col="7" label="Title">
                            <input
                            onBlur={(e)=> setEmpNote(e.target.value)}
                                type="text"
                                name="title"
                                className="form-control"
                            />
                        </InputWrapper>
                        <div className="col-12">
                            {systemLoading ?<button disabled className="btn btn-primary btn-sm">
                                Update task <Spinner size="sm" animation="grow"/>
                            </button> :<button onClick={updateTaskDetails} className="btn btn-primary btn-sm">
                                Update task
                            </button>}
                        </div>
                    </div>
                    <p>{taskStatus} - {empNote}</p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupTaskUpdate;
