import React from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { advisoryData } from "./advisorData";
import ProfileCardSingleAdvisor from "./ProfileCardSingleAdvisor";
import { advisorsCollectionRef } from "../../firebase/fire-app";
import { useCollectionData } from "react-firebase-hooks/firestore";

const ViewKnowAdvisors = () => {
    const [myelinAdvisors, loading, error] = useCollectionData(advisorsCollectionRef.orderBy("serial", "asc"), {idField: "id"})

    const onAddAdvisorsToDB =()=>{
        advisoryData.map(item =>{
            advisorsCollectionRef.add(item).then(()=>{
                console.log("done -_0");
            })
        })
    }
    return (
        <>
            <SidebarComponent>
                <section className="sticky-top page-heading">
                    <div className="container">
                        <h3 className="-3">Advisory Board</h3>
                    </div>
                </section>
                {/* <section className="container text-center">
                    <button onClick={onAddAdvisorsToDB} className="btn btn-primary">Add Advisors to DB</button>
                </section> */}
                <section className="profile-card-container mt-3">
                    <div className="container">
                        <div className="row">
                            {
                                myelinAdvisors && myelinAdvisors.length>0 && myelinAdvisors.map((item)=>(
                                    <ProfileCardSingleAdvisor data={item} key={item.id}/>
                                ))
                            }
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewKnowAdvisors;
