import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { payslipsCollectionRef } from '../../firebase/fire-app';

const CompPersonalPayslips = ({empID}) => {
    const [allPayslips, loading, error] = useCollectionData(payslipsCollectionRef.where('empID', '==', empID), { idField: 'id' })

    return (
        <>
            
            <div className="row">
                <div className="col-12">
                    {allPayslips && allPayslips.length > 0 && allPayslips.map((data, index) => <PayslipCard key={index} data={data} />)}
                </div>
            </div>
        </>
    );
};

export default CompPersonalPayslips;



const PayslipCard = ({ data }) => {
    return (
        <>
            <a className='btn btn-sm btn-primary me-2 mb-2' target='_blank' href={data.url}>{data.payMonth}</a>
        </>
    )
}