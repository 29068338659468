import { addDays, differenceInCalendarDays, set } from 'date-fns';


export const timeStampToDate = (timeStamp) => {
    if (timeStamp) {
        const milliseconds = timeStamp.seconds * 1000
        const tt = new Date(milliseconds)
        const dateString = tt.toDateString()
        return dateString;
    }
}

export const customDateFormat = (utcDate) => {
    if (utcDate) {
        let today = new Date(utcDate.seconds * 1000);
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        // today = mm + '-' + dd + '-' + yyyy;
        // console.log(today);
        // today = mm + '/' + dd + '/' + yyyy;
        // console.log(today);
        // today = dd + '-' + mm + '-' + yyyy;
        // console.log(today);
        today = dd + '/' + mm + '/' + yyyy;
        return today
    } else {
        return "Not yet"
    }
}


export const screenSizePickerDateRange = () => {
    const width = window.innerWidth;
    if (width < 700) {
        return "vertical"
    } else {
        return "horizontal"
    }
}

export const removeFromArray = (array, value) => {
    const itemIndex = array.indexOf(value);
    if (itemIndex > -1) {
        // Remove the item from the array
        array.splice(itemIndex, 1);
        return array
    } else {
        return array
    }
}

export const convertMillisecondsToDateTime = (fireTimeStamp) => {

    if (fireTimeStamp) {
        const milliseconds = fireTimeStamp.seconds * 1000
        const date = new Date(milliseconds);

        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1 and pad with leading zero if necessary
        const day = ("0" + date.getDate()).slice(-2); // Pad with leading zero if necessary

        const hours = ("0" + date.getHours()).slice(-2); // Pad with leading zero if necessary
        const minutes = ("0" + date.getMinutes()).slice(-2); // Pad with leading zero if necessary
        const seconds = ("0" + date.getSeconds()).slice(-2); // Pad with leading zero if necessary
        const millisecond = ("00" + date.getMilliseconds()).slice(-3); // Pad with leading zeros if necessary

        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = `${hours}:${minutes}:${seconds}.${millisecond}`;

        return { date: formattedDate, time: formattedTime };
    }

}


export function getVacationDays(startDate, endDate) {
    const officialWeekendDays = [0 /* Sunday */, 6 /* Saturday */];
    let currentDay = startDate;
    let vacationDays = 0;

    while (currentDay <= endDate) {
        // Check if the day is not an official weekend day
        if (!officialWeekendDays.includes(currentDay.getDay())) {
            vacationDays++;
        }
        currentDay = addDays(currentDay, 1);
    }

    return vacationDays;
}