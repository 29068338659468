import React, { useState } from "react";
import PopupAddNewCandidate from "./PopupAddNewCandidate";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

import { recruitmentCollectionRef } from "../../firebase/fire-app";
import PopupAdminReview from "./PopupAdminReview";
import {
    timeStampToDateString,
    timeStampToJSDate,
} from "../../utilities/firebasetimestamp";
const AdminViewRecruitment = () => {
    const [candidateStatus, setCandidateStatus] = useState("live");
    const [allCandidates, loading, error] = useCollectionData(
        recruitmentCollectionRef.where(
            "candidateStatus",
            "==",
            candidateStatus
        ),
        { idField: "id" }
    );

    // recruitmentCollectionRef.orderBy("lastUpdated", "desc").get();
    return (
        <>
            <div className="text-right">
                <ButtonGroup
                    size="sm"
                    className="me-5"
                    aria-label="Basic example"
                >
                    <Button
                        onClick={() => setCandidateStatus("live")}
                        size="sm"
                        variant={`${
                            candidateStatus === "live" ? "primary" : "light"
                        }`}
                    >
                        Active
                    </Button>
                    <Button
                        onClick={() => setCandidateStatus("archived")}
                        size="sm"
                        variant={`${
                            candidateStatus === "archived" ? "warning" : "light"
                        }`}
                    >
                        Archived
                    </Button>
                </ButtonGroup>
                {/* <h4 className="mb-0">Admin Dashboard</h4> */}
                <PopupAddNewCandidate />
            </div>
            <div className="container">
                <div className="table-responsive">
                    <table className="table mt-4  pr-5 table-bordered border-primary table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Sl.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Position</th>
                                <th>Date</th>
                                <th>Score-1</th>
                                <th>Rev-1</th>
                                <th>Score-2</th>
                                <th>Rev-2</th>
                                <th>Total Score</th>
                                <th>Final Rev.</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allCandidates &&
                                allCandidates.map((item, index) => (
                                    <CandidateRow
                                        data={item}
                                        index={index + 1}
                                        key={item.id}
                                    />
                                ))}
                        </tbody>
                    </table>
                </div>
                {/*             
                <div className="row">
                    {allCandidates &&
                        allCandidates.length > 0 &&
                        allCandidates.map((item) => (
                            <CandidateCard data={item} key={item.id} />
                        ))}
                </div> */}
            </div>
        </>
    );
};

export default AdminViewRecruitment;

const CandidateRow = ({ data, index }) => {
    const onArchiveCandidate = () => {
        recruitmentCollectionRef.doc(data.id).update({
            candidateStatus: "archived",
        });
    };
    return (
        <tr>
            <td>{index}</td>
            <td>{data.name}</td>
            <td>{data.email}</td>
            <td>{data.positionTitle}</td>
            <td>{timeStampToDateString(data.createdAt)}</td>
            <td>{data.oneScore}</td>
            <td>{data.assOneReview}</td>
            <td>{data.twoScore}</td>
            <td>{data.assTwoReview}</td>
            <td>
                {data.oneScore
                    ? data.oneScore
                    : 0 + data.twoScore
                    ? data.twoScore
                    : 0}
            </td>
            <td>{data.finalReview}</td>
            <td>
                <PopupAdminReview data={data} />
                <button
                    onClick={onArchiveCandidate}
                    className="btn btn-sm btn-warning"
                >
                    Archive
                </button>
            </td>
        </tr>
    );
};
