import React, { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { customDateFormat } from "../../utilities";
import { wfhApplicationCollectionRef } from "../../firebase/fire-app";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { StatusBadge } from "../ViewClaimManagement/FormElements";
import emailjs from "@emailjs/browser";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { webServerLive } from "../../firebase/liveServerStatus";

const AdminRemoteWork = () => {
    const [status, setStatus] = useState("Pending");
    const [myLeaves, loading, error] = useCollectionData(
        wfhApplicationCollectionRef.where("status", "==", status),
        { idField: "id" }
    );

    return (
        <>
            <SidebarComponent>
                <section className="container d-flex justify-content-between">
                    <h3 className="mt-3">Remote Work Applications </h3>
                    <ButtonGroup
                        size="sm"
                        className="mt-4"
                        aria-label="Basic example"
                    >
                        <Button
                            onClick={() => setStatus("Pending")}
                            size="sm"
                            variant={`${
                                status === "Pending" ? "warning" : "light"
                            }`}
                        >
                            Pending
                        </Button>
                        <Button
                            onClick={() => setStatus("Approved")}
                            size="sm"
                            variant={`${
                                status === "Approved" ? "primary" : "light"
                            }`}
                        >
                            Approved
                        </Button>
                        <Button
                            onClick={() => setStatus("Rejected")}
                            size="sm"
                            variant={`${
                                status === "Rejected" ? "danger" : "light"
                            }`}
                        >
                            Rejected
                        </Button>
                    </ButtonGroup>
                </section>
                <hr />
                <section className="">
                    <div className="container ">
                        <div className="table-responsive">
                            {myLeaves && myLeaves.length > 0 && (
                                <table className="table mt-5 pr-5 table-bordered border-primary table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>EmpID</th>
                                            <th>Leave Type</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Days</th>
                                            <th>Remarks</th>
                                            <th>Status</th>
                                            <th>Reject</th>
                                            <th>Approve</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myLeaves &&
                                            myLeaves.map((claim, index) => (
                                                <LeaveRow
                                                    claim={claim}
                                                    index={index}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default AdminRemoteWork;

const LeaveRow = ({ claim, index }) => {
    const onApproveApplication = () => {
        wfhApplicationCollectionRef
            .doc(claim.id)
            .update({ status: "Approved" })
            .then(() => {
                const mailData = {
                    mailSubject: "Remote Work Application Approved",
                    message:
                        "Your application to work remotely has been approved",
                    empID: claim.empID,
                    name: claim.name,
                    type: claim.leaveType,
                    remarks: claim.leaveRemarks,
                    duration:
                        customDateFormat(claim.startDate) +
                        " to " +
                        customDateFormat(claim.endDate),
                    status: "Approved",
                    mailOne: claim.empEmail,
                    mailTwo: "zied.tayeb@myelinh.com",
                    mailThree: "samaher.garbaya@myelinh.com",
                    mailFour: "info@myelinh.com",
                    from_name: "Myelin-H HR",
                };
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_vhzaawx",
                        mailData,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                }
                alert("Leave Approved");
            });
    };
    const onRejectApplication = () => {
        wfhApplicationCollectionRef
            .doc(claim.id)
            .update({ status: "Rejected" })
            .then(() => {
                alert("Leave Rejected");
                const mailData = {
                    mailSubject: "Remote Work Application Rejected",
                    message:
                        "Your application to work remotely has been rejected",
                    empID: claim.empID,
                    name: claim.name,
                    type: claim.leaveType,
                    duration:
                        customDateFormat(claim.startDate) +
                        " to " +
                        customDateFormat(claim.endDate),
                    remarks: claim.leaveRemarks,
                    status: "Rejected",
                };
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_vhzaawx",
                        mailData,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                }
            });
    };
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{claim.empID}</td>
            <td>{claim.leaveType}</td>
            <td>{customDateFormat(claim.startDate)}</td>
            <td>{customDateFormat(claim.endDate)}</td>
            <td> {claim.leaveDays}</td>
            <td>{claim.leaveRemarks} </td>
            <td>
                <StatusBadge status={claim.status} />
            </td>
            <td>
                <button
                    onClick={onRejectApplication}
                    className="btn btn-danger btn-sm"
                >
                    REJECT
                </button>
            </td>
            <td>
                <button
                    onClick={onApproveApplication}
                    className="btn btn-primary btn-sm"
                >
                    APPROVE
                </button>
            </td>
        </tr>
    );
};
