import { FaLink } from "react-icons/fa6";
import { documentsManagerCollectionRef } from "../../firebase/fire-app";
import { defaultFormatDate } from "../../utilities/convertTime";
import { FaTrashAlt } from "react-icons/fa";

export const CompAdminDocumentCard = ({ data, col, admin, selectedEmp }) => {
    console.log(data);
    const onImgDelete = () => {
        documentsManagerCollectionRef
            .doc(data.id)
            .delete()
            .then(() => {
                "image deleted!";
            });
    };
    return (
        <>

                <div className={`col-md-${col ? col : "6"} mb-2`}>
                    <div className="card p-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="w-75">
                                <p className="font-16 font-bold mb-1">
                                    {data.name}
                                </p>
                                <p className="font-14 mb-0">
                                    {data.category} |{" "}
                                    {defaultFormatDate(data.createdAt)}
                                </p>
                                {admin && (
                                    <p className="mb-0 font-14">
                                        {data.employeeEmail}
                                    </p>
                                )}
                            </div>
                            <div>
                                <a
                                    className="btn btn-sm p-1 btn-primary me-2"
                                    href={data.fileURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLink />
                                </a>
                                <button
                                    onClick={onImgDelete}
                                    className="btn btn-sm p-1 btn-danger"
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            
        </>
    );
};
