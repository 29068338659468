import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ProfileCardSingle from "./ProfileCardSingleAdvisor";
import EditProfileCardInfo from "../ViewPersonalProfile/EditProfileCardInfo";
import { FaLinkedinIn, FaInstagram, FaTwitter, FaLink } from "react-icons/fa";
import { useAuth } from "../../Hooks/useAuth";

const ProfileCardDetailsAdvisor = ({ data }) => {
    const [show, setShow] = useState(false);
    const { currentUser } = useAuth();

    return (
        <>
            <button className="btn btn-smaller btn-outline-primary" onClick={() => setShow(true)}>Read more..</button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold text-align-center mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        {data.name}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3   f">
                            <div className="profile-card-single">
                                <div className="edit-btn-container">
                                    {/* <EditProfileCardInfo data={data} /> */}

                                    {currentUser && currentUser.email === data.email && (
                            <EditProfileCardInfo data={data} />
                        )}
                                </div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-md-5 mb-4 mb-md-0">
                                        <div className="dp-img">
                                            <img
                                                src={"https://myelinh.com/images/new-advisor.jpeg"}
                                                alt=""
                                                className="img-luid"
                                            />
                                        </div>
                                        <div className="text">
                                            <p className="name mb-0">{data.name}</p>
                                            <p className="job_title">
                                                {data.designation}
                                            </p>
                                        </div>
                                        <div className="icons">
                                            {data.socialLinkedIn && (
                                                <a
                                                    href={data.socialLinkedIn}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaLinkedinIn />
                                                </a>
                                            )}
                                            {data.socialInstagram && (
                                                <a
                                                    href={data.socialInstagram}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaInstagram />
                                                </a>
                                            )}
                                            {data.socialTwitter && (
                                                <a
                                                    href={data.socialTwitter}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaTwitter />
                                                </a>
                                            )}
                                            {data.socialWeb && (
                                                <a
                                                    href={data.socialWeb}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaLink />
                                                </a>
                                            )}
                                        </div>
                                        <div className="d-flex mt-3 justify-content-center">
                                            <div>
                                                <a
                                                    className="btn mx-1 btn-sm btn-primary cta"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`mailto:${data.email}`}
                                                >
                                                    Message
                                                </a>
                                            </div>
                                            <div>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={data.socialLinkedIn}
                                                    className="btn btn-sm btn-outline-primary"
                                                >
                                                    Connect
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 ">
                                        <div className="right-description ps-3">
                                            {data.shortDescription && (
                                                <div
                                                    className="text-align-left font-14"
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.shortDescription,
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProfileCardDetailsAdvisor;
