import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import slugify from "slugify";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { blogsCollectionRef } from "../../firebase/fire-app";
import { useNavigate } from 'react-router-dom';

const PopupAddNewBlog = () => {
    const [show, setShow] = useState(false);

    const [blogs, setBlogs] = useState(true);
    const [blogSlug, setBlogSlug] = useState(null);
    const [blogTitle, setBlogTitle] = useState(null);

    const blogObject = {
        title: "",
        slug: "",
        featuredImage: ``,
        content: ``,
        status: `Published`,
        tags: [],
        category: ``,
        createdAt: new Date(),
        lastUpdated: new Date(),
        totalViews: 0,
    };
    const history = useNavigate();

    const onTitleChange = (e) => {
        setBlogTitle(e.target.value);
        const slu = slugify(e.target.value, {
            replacement: "-",
            remove: undefined,
            lower: true,
            strict: true,
            locale: "vi", // language code of the locale to use
        });
        if (blogs) {
            console.log("blogs are available");
            let sameBlog = null;
            // sameBlog = blogs.find((item) => item.slug === slu);
            // setSimilarBlog(sameBlog)
            setBlogSlug(slu);
        } else {
            console.log(slu);
            // setSimilarBlog(false)
            setBlogSlug(slu);
        }
    };
    const onSlugChange =(e)=>{
        setBlogSlug(e.target.value)
    }

    const onCreateNewBlog =()=>{
        const blogContent = {...blogObject, title: blogTitle, slug: blogSlug}
        blogsCollectionRef.doc(blogSlug).set(blogContent).then((snapshot)=>{
            history("/admin/blogs/edit/" + blogSlug)

        })
    }

    return (
        <>
            <button
                className={`btn btn-primary btn-sm`}
                onClick={() => setShow(true)}
            >
                Add new blog
            </button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add new blog
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <InputWrapper col="8" label="Title">
                            <input
                                onChange={onTitleChange}
                                type="text"
                                name="title"
                                className="form-control"
                            />
                        </InputWrapper>
                        <InputWrapper col="8" label="URL">
                            <input
                                type="text"
                                onChange={onSlugChange}
                                name="slug"
                                defaultValue={blogSlug}
                                className="form-control"
                            />
                        </InputWrapper>
                        <div className="col-12 text-center">
                            {blogSlug ? (
                                <button onClick={onCreateNewBlog} className="btn btn-sm btn-primary">
                                    Add blog content
                                </button>
                            ) : (
                                <button
                                    disabled
                                    className="btn btn-sm btn-primary"
                                >
                                    Add blog content
                                </button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAddNewBlog;
