import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { archivedUsersCollectionRef, myelinersCollectionRef } from '../../firebase/fire-app';
import SidebarComponent from '../../Components/Shared/SidebarComponent';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { customDateFormat } from '../../utilities';

const AdminArchivedMyeliners = () => {
    const [allMyeliners, loading, error] = useCollectionData(archivedUsersCollectionRef.where("type", "==", "archive"), { idField: 'id' })
    return (
        <>
            <SidebarComponent>
                <section className="container">
                    <h3 className="mt-3">Archive</h3>
                </section>
                <hr />
                <section>
                    <div className="container px-5">
                       <div className="table-responsive">
                       {allMyeliners && allMyeliners.length > 0 && <table className="table pr-5 table-bordered border-primary table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Sl.</th>
                                    <th>EmpID</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Email</th>
                                    {/* <th>Personal email</th> */}
                                    {/* <th>Contact</th> */}
                                    <th>Archived On</th>

                                </tr>
                            </thead>
                            <tbody>
                                {allMyeliners && allMyeliners.map((data, index) => <TableRow data={data} index={index} />)}
                            </tbody>
                        </table>}
                       </div>
                    </div>
                </section>
            </SidebarComponent>

        </>
    );
};

export default AdminArchivedMyeliners;


const TableRow = ({ data, index }) => {
    const onDelete = () => {
        if (data && data.empID) {
            myelinersCollectionRef.doc(data.empID).delete()
                .then(() => {
                    alert('Employee Deleted')
                })
                .catch((err) => {
                    alert('Error Deleting Employee')
                })
        }
    }
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{data.empID}</td>
            <td>{data.name}</td>
            <td>{data.designation}</td>
            <td>
                <CopyToClipboard text={data.email}>
                    <button className='btn btn-sm btn-light'>{data.email}</button>
                </CopyToClipboard>
            </td>
            {/* <td>
                <CopyToClipboard text={'Myeliners@2023@'}>
                    <button className='btn btn-sm btn-dark'>Pass</button>
                </CopyToClipboard>
            </td> */}
            {/* <td>{data.personalEmail}</td> */}
            {/* <td>{data.phone}</td> */}
            {/* <td>{data.whatsApp}</td> */}
            {/* <td> <button onClick={onDelete} className="btn btn-danger btn-sm">Delete</button> </td> */}
            <td>
               {customDateFormat(data.archived)}
            </td>

        </tr>
    )
}