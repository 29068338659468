import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import {  myelinersCollectionRef } from '../../firebase/fire-app';
import firebase from 'firebase/app';



const LeaveBalanceManagement = () => {
    const [show, setShow] = useState(false);
    const [allMyeliners, loading, error] = useCollectionData(myelinersCollectionRef, { idField: 'id' })
    const [rows, setRows] = useState(null);

    useEffect(() => {
        if (allMyeliners && allMyeliners.length > 0) {
            setRows(allMyeliners)
        }
    }, [allMyeliners])

    useEffect(() => {
        setModifyLeave(false)
    }, [show])

    const [modifyLeave, setModifyLeave] = useState(null);

    return (
        <>
            <Button variant="danger" size='sm' onClick={() => setShow(true)}>
                Manage Employee Leave Balance
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Leave Balances
                    </h5>
                </Modal.Header>
                <Modal.Body className='scroll-flow'>
                    {allMyeliners && allMyeliners.length > 0 &&
                        <div className="table-responsive">

                            <table className="table mt-5 pr-md-5 table-bordered border-primary  table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>EmpID</th>
                                        <th>Leave Taken</th>
                                        {modifyLeave && <th>Modify Leave Taken</th>}

                                        <th>Leave Balance</th>
                                        {modifyLeave && <th>Modify Leave Balance</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allMyeliners && allMyeliners.map((claim, index) => <LeaveRow modifyLeave={modifyLeave} data={claim} index={index} />)}
                                </tbody>
                            </table>

                        </div>}

                    <button onClick={() => setModifyLeave(true)} className="btn btn-primary">Modify leave balances</button>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default LeaveBalanceManagement;

const LeaveRow = ({ data, index, modifyLeave }) => {
    const [balance, setBalance] = useState(data.leaveBalance)
    const [leaveTaken, setLeaveTaken] = useState(data.leaveTaken);
    const onBalanceChange = (type) => {
        if (type === 'taken') {
            const balanceVal = Number(leaveTaken);
            myelinersCollectionRef.doc(data.id).update({
                leaveTaken: balanceVal
            })
        } else if (type === "balance") {
            const balanceVal = Number(balance);
            myelinersCollectionRef.doc(data.id).update({
                leaveBalance: balanceVal
            })
        }


    }
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{data.name}</td>
            <td>{data.empID}</td>
            <td>{data.leaveTaken}</td>
            {modifyLeave && <td className='d-md-flex'>
                <input defaultValue={leaveTaken} onBlur={(e) => setLeaveTaken(e.target.value)} type="number" name="leaveBalance" className='me-md-4 form-control' id="leaveBalance" />
                <button onClick={() => onBalanceChange('taken')} className="btn btn-sm btn-primary ml-3">save</button>
            </td>}
            <td>{data.leaveBalance}</td>
            {modifyLeave && <td className='d-md-flex'>
                <input defaultValue={balance} onBlur={(e) => setBalance(e.target.value)} type="number" name="leaveBalance" className='me-md-4 form-control' id="leaveBalance" />
                <button onClick={() => onBalanceChange('balance')} className="btn btn-sm btn-primary ml-3">save</button>
            </td>}
        </tr>
    )
}